var AppComponents = angular.module('RessieApp.components', [
    'RessieApp.componentTemplates',
    'RessieApp.components.reports',
    'RessieApp.components.company',
    'RessieApp.components.reservations',
    'RessieApp.components.whitelist',
    'RessieApp.components.referrals',
    'RessieApp.components.package'

]);
