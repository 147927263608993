var MerchandiseReport = angular.module('RessieApp.components.reports.merchandise', []);

MerchandiseReport.controller('merchandiseReport', function ($scope, $stateParams, Reports) {

  var ACL = 'Bookkeeping Reports';
  $scope.allowed = $scope.user.can('mdse', ACL);

  var start = $scope.start = $scope.$parent.calendar.date.startDate;
  var end = $scope.end = $scope.$parent.calendar.date.endDate;

  $scope.$parent.isPrintable();

  (function(parent){
    parent.setPrintTitle('MDSE for ' + start.format('MM-DD-YY') + ' thru ' + end.format('MM-DD-YY'))
  })($scope.$parent);

  // NOTE: Handle the report filter.
  $scope.filter = $scope.$parent.filter;
  $scope.$watch('$parent.filter', function(n) {
    $scope.filter = n;
  });

  function GrabData(){
    Reports.sales(
      $stateParams.start,
      $stateParams.end,
      function Win(res){
        var data = angular.copy(res);
        $scope.data = data.constructor.name === 'Resource' ? data.toJSON() : data;
        $scope.days = angular.copy($scope.data);
        $scope.Reservations = $scope.data.Reservation;
        $scope.Prepayments = $scope.data.Prepayment;
        delete $scope.days.Prepayment;
        delete $scope.days.Reservation;
        delete $scope.days.batches;
        $scope.$parent.isReady();
      }
    );
  }

  $scope.prep = function(){
    if($scope.allowed) return GrabData();
    $scope.$parent.isReady();
  }

})
