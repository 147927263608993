var SourcesReportController = angular.module('RessieApp.components.reports.packages', []);

SourcesReportController.controller('packageReport', function ($scope, $stateParams, $timeout, Reports) {

  $scope.$parent.isPrintable();
  var company = $scope.user.activeCompany();

  (function(parent){
    var date = parent.calendar.date.startDate.format('MM-DD-YY');
    parent.setPrintTitle('Booking Sources between ' + date)
  })($scope.$parent);

  $scope.data = {};

  $scope.packages = function() {
    return Object.keys($scope.data);
  }

  $scope.total = function() {
    return Object.keys($scope.data).reduce(function(prev, cur) {
      return $scope.data[cur].length + prev;
    }, 0)
  }

  $scope.prep = function(){
    Reports.byPackage($stateParams.start, $stateParams.end, function(res) {
      delete res.requestUrl;
      $scope.data = res;
      $scope.$parent.isReady();
    });
  }

});