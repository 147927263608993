var PilotCommissionReport = angular.module('RessieApp.components.reports.pilot-commissions', []);

PilotCommissionReport.controller('pilotCommissionsReport', function ($scope, $stateParams, Reports, Users, Contacts, $filter) {

  var ACL = 'Bookkeeping Reports';
  $scope.allowed = $scope.user.can('commissions', ACL);

  // $scope.$parent.isPrintable();

  var start = $scope.start = $scope.$parent.calendar.date.startDate;
  var end = $scope.end = $scope.$parent.calendar.date.endDate;

  // NOTE: Handle the report filter.
  $scope.filter = $scope.$parent.filter;
  $scope.$watch('$parent.filter', function(n) {
    $scope.filter = n;
  });

  function GroupByPilot(reservations) {
    var pilots = {};
    reservations.forEach(function(reservation) {
      var blank = { name: 'Unassigned' }
      var pilot = reservation.flight.length ? ((reservation.flight[0].flight || {}).pilot || blank) : blank;
      if (!pilot) {
        pilot = { name: 'Unassigned' }
      }
      if (pilot.name === 'Unassigned') {
        console.log(`unassigned reservation`, reservation.flight);
      }

      pilots[pilot.name] = pilots[pilot.name] || [];
      pilots[pilot.name].push(reservation)
    });
    return pilots;
  }

  function GroupPaymentTypes(reservations) {
    var types = {};
    reservations.forEach(function(reservation){
      types['Tips'] = types['Tips'] || 0
      var payment = reservation.payment;
      // get retail tips
      types['Tips'] = reservation.line_items.filter(function(item) {
        return /tip/i.test(item.product.name);
      }).map(function(tip) {
        return Number.parseFloat(tip.count) * Number.parseFloat(tip.value);
      }).reduce(function(prev, current) {
        return prev + current;
      }, types['Tips']);

      var charges = [];
      types['Cash'] = types['Cash'] || 0;
      types['Credit Cards'] = types['Credit Cards'] || 0;
      types['Credit Card Refunds'] = types['Credit Card Refunds'] || 0;
      types['Cash'] += payment.cash.reduce(function(prev, current){ return prev + Number.parseInt(current, 10) }, 0)
      payment.cards.forEach(function(card) {
        if (card.charges.length) {
          card.charges.forEach(function(charge) {
            if (charge.tip) {
              types['Tips'] += Number.parseFloat(charge.tip);
            }
            charges.push(charge);
          })
        }
      });
      types['Credit Cards'] += charges.reduce(function(prev, charge) {
        var refundTotal = charge.refunds.reduce(function(prevRef, refund){
          return prevRef + refund.amount;
        }, 0)
        return prev + (charge.amount - refundTotal);
      }, 0);

      if(payment.other.length) {
        payment.other.forEach(function(payment) {
          var partner = payment.partner;
          if (partner.name === 'Square POS') {
            types[partner.name] = types[partner.name] || 0;
            types[partner.name] += payment.amount;
          }
          if (partner.name !== 'PROMO' && partner.name !== 'Square POS') {
            if(!$scope.notCommissionPartners.find(function(notEligible){ 
              return notEligible.id === partner.id 
            })) {
              types['BILL-TO'] = types['BILL-TO'] || 0;
              types['BILL-TO'] += payment.amount;
            }
          }
        })
      }
      if (payment.gifts.length) {
        payment.gifts.forEach(function(gift) {
          var name = gift.type;
          switch (gift.type) {
            case 'card':
              name = 'Prepaid: Gift Card'
              break;
            case 'promo':
              name = 'Prepaid: Promotion'
              break;
            case 'donation':
              name = 'Prepaid: Donation'
              break;
          }
          types[name] = types[name] || 0;
          types[name] += gift.amount;
        })
      }
    })
    for (var type in types) {
      if (Object.hasOwnProperty.call(types, type)) {
        var amount = types[type];
        if (amount === 0) {
          delete types[type];
        }
      }
    }
    if (types['Tips']) {
      types['Tips'] = types['Tips'] * -1;
    }
    return types;
  }

  $scope.getFullPilotByName = function(name) {
    return $scope.allPilots.filter(function(pilot) {
      return pilot.name === name;
    })[0];
  }

  function TypeTotals(types, excludeTips) {
    var cacheTypes = types;
    var tips = cacheTypes['Tips'] || 0;
    var values = Object.values(cacheTypes);
    return (values.reduce(function(prev, val){
      return prev + val;
    }, 0)) + (excludeTips ? tips : 0);
  }

  // NOTE: tip results come from sales report directive
  function GrabData() {

    Users.ofType('pilot', function(pilots){
      $scope.allPilots = pilots;
    });

    Contacts.byType('partner', function(partners){
      $scope.notCommissionPartners = Object.values(partners.contacts).filter(function(partner) {
        return partner.meta.not_pilot_commission_eligible;
      });
    });

    return Reports.sales(
      $stateParams.start,
      $stateParams.end,
      function Win(res){
        // console.log('pilots', $scope.allPilots)
        var data = angular.copy(res);
        var resourceData = (data.constructor.name === 'Resource' ? data.toJSON() : data);
        var reservations = Object.values(resourceData.Reservation).filter(function(reservation) { 
          var isReservationDateValid = moment(reservation.day).isBetween(
            moment($stateParams.start, 'MM-DD-YY').subtract(1, 'day'),
            moment($stateParams.end, 'MM-DD-YY').add(1, 'day')
          );
          var isReservationActive = reservation.status === 'active';
          return isReservationDateValid && isReservationActive;
        });
        $scope.pilots = GroupByPilot(reservations);
        $scope.paymentTypes = GroupPaymentTypes(reservations)
        $scope.getTypes = GroupPaymentTypes;
        $scope.typeTotals = TypeTotals;
        $scope.$parent.isReady();
      }
    );
  }

  $scope.prep = function(){
    if($scope.allowed) return GrabData();
    $scope.$parent.isReady();
  };

})
