var bookedonReport = angular.module('RessieApp.components.reports.bookedon', []);

bookedonReport.controller('bookedOn', function ($scope, $stateParams, Reports) {

  var start = $scope.start = $scope.$parent.calendar.date.startDate;
  var end = $scope.end = $scope.$parent.calendar.date.endDate;

  $scope.$parent.isPrintable();

  (function(parent){
    var _start = start.format('MM-DD-YY');
    var _end = end.format('MM-DD-YY');
    if(_start === _end) {
      parent.setPrintTitle('Booked On: ' + _start);
    } else {
      parent.setPrintTitle('Booked Between: ' + _start + ' and ' + _end);
    }
  })($scope.$parent);

  $scope.countGuests = function(bookings, onlineOnly) {
    onlineOnly = onlineOnly || false;
    return bookings.reduce ? bookings.filter($scope.getActive).reduce(function(prev, booking){
        const isBookedOnline = booking.trace.find(function(t){ return t.reason === 'created' && t.user === null }) !== undefined;
        const count = prev + ((booking.adults || 0) + (booking.children || 0));
        return onlineOnly ? (isBookedOnline ? count : prev) : count;
    }, 0) : 0;
  };

  $scope.countAllBooked = function(data) {
    if(data){
      return Object.keys(data).reduce(function(prev, day){
        return prev + data[day].filter($scope.getActive).length;
      }, 0);
    }
  }

  
  $scope.getActive = function(item) {
    return item.status !== 'pending' && item.status !== 'waiting'
  }

  $scope.groupedByAgent = function(days) {
    var bookings = [];
    for (var day in days) {
      if (days.hasOwnProperty(day)) {
        var res = days[day];
        bookings = bookings.concat(res);
      }
    }
    var grouped = bookings.reduce(function (booking, x) {
      var agent = (x.trace[0].user && x.trace[0].user.name) || 'Online';
      (booking[agent] = booking[agent] || []).push(x);
      return booking;
    }, {});
    // console.log(grouped);
    return grouped;
  }

  $scope.countAllGuestsBooked = function(data, onlineOnly) {
    onlineOnly = onlineOnly || false;
    if(data){
      return Object.keys(data).reduce(function(prev, day){
        return prev + $scope.countGuests(data[day], onlineOnly);
      }, 0);
    }
  }

  $scope.prep = function(){
    Reports.bookedOn(start.format('MM-DD-YY'), end.format('MM-DD-YY'), function(res){
      res = res.constructor.name === 'Resource' ? res.toJSON() : res;
      delete res.requestUrl;
      $scope.data = res.constructor.name === 'Resource' ? res.toJSON() : res;
      $scope.bookedByAgent = $scope.groupedByAgent($scope.data);
      $scope.$parent.isReady();
    });
  }

})
