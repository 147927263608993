var GlanceReport = angular.module('RessieApp.components.reports.glance', []);

GlanceReport.controller('glanceReport', function ($scope, $stateParams, Events) {

  // $scope.$parent.isSingleDay();
  $scope.$parent.isPrintable();

  (function(parent){
    var date = parent.calendar.date.startDate.format('MM-DD-YY');
    parent.setPrintTitle('At-A-Glance for ' + date)
  })($scope.$parent);

  // set default days
  if($stateParams.start === '') {
    if(moment().hours() > 9) {
      var tomorrow = moment().add(1, 'day')
      $scope.$parent.calendar.date.startDate = tomorrow;
      $scope.$parent.calendar.date.endDate = tomorrow.clone().add(7, 'day');
    } else {
      var today = moment();
      $scope.$parent.calendar.date.startDate = today;
      $scope.$parent.calendar.date.endDate = today.clone().add(7, 'days');
    }
  }

  var start = $scope.start = $scope.$parent.calendar.date.startDate;
  var end = $scope.end = $scope.$parent.calendar.date.endDate;

  $scope.prep = function(){
    var cols = {};
    Events.range(
      start.format('MM-DD-YYYY'),
      end.format('MM-DD-YYYY'),
      function Win(res){
        var count = 0;
        var skipping_brunch = 0;
        angular.forEach(res, function(day){
          count += day.capacity.booked;
          skipping_brunch += (day.capacity.skipping_brunch || 0);
          angular.forEach(day.reportable, function(count, item){
            cols[item] = cols[item] || 0;
            cols[item] += count;
          })
        });
        $scope.data = { days: res, total: count, total_skipping_brunch: skipping_brunch, extra_columns: cols };
        $scope.$parent.isReady();
      }
    );
  }

})
