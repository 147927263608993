var BookedOnline = angular.module('RessieApp.components.reservations.online', []);

BookedOnline.controller('BookedOnline', function ($scope, $stateParams, Bookings, $timeout) {

  $scope.start = moment.utc().startOf('month');
  $scope.end = moment.utc().endOf('day');
  $scope.page = 1;
  $scope.limit = 20;
  $scope.count = 0;

  $scope.bookings = [];

  $scope.pages = [];

  $scope.calendar = {
    date: {
      startDate: $scope.start,
      endDate: $scope.end
    },
    options: {
      showDropdowns: true,
      locale: {
        format: 'MM/DD/YYYY',
        separator: ' thru '
      },
      singleDatePicker: false,
      ranges: {
        'Today': [
          moment(),
          moment()
        ],
        'Yesterday': [
          moment().subtract(1, 'days'),
          moment().subtract(1, 'days')
        ],
        'Last 7 Days': [
          moment().subtract(6, 'days'),
          moment()
        ],
        'Last 30 Days': [
          moment().subtract(29, 'days'),
          moment()
        ],
        'This Month': [
          moment().startOf('month'),
          moment().endOf('month')
        ],
        'Last Month': [
          moment().subtract(1, 'month').startOf('month'),
          moment().subtract(1, 'month').endOf('month')
        ]
      }
    }
  };

  $scope.isHeavy = function(booking) {
    return booking.meta.weights.find((w) => w >= 275) !== undefined;
  }

  $scope.updatePage = function(page){
    $scope.page = page;
  }

  $scope.$watch('calendar.date.startDate', function(n, o){
    $scope.start = n;
    getBookings($scope.start.format('MM-DD-YY'), $scope.end.format('MM-DD-YY'), $scope.page);
  })

  $scope.$watch('calendar.date.endDate', function(n, o) {
    $scope.end = n;
    getBookings($scope.start.format('MM-DD-YY'), $scope.end.format('MM-DD-YY'), $scope.page);
  })

  $scope.$watch('page', function(n, o) {
    getBookings($scope.start.format('MM-DD-YY'), $scope.end.format('MM-DD-YY'), $scope.page);
  })

  getBookings($scope.start.format('MM-DD-YY'), $scope.end.format('MM-DD-YY'), $scope.page);

  function getBookings(start, end, page, limit){
    limit = limit || $scope.limit;
    Bookings.online({
      start: start,
      end: end,
      page: page,
      limit: limit
    }, function(res){
      $timeout(function(){
        $scope.bookings = res.results;
        $scope.pages = [];
        $scope.count = res.meta.count;
        var pages = Math.ceil(res.meta.count/$scope.limit);
        for (var index = 0; index < pages; index++) {
          $scope.pages.push({ page: index + 1 })
        }
      });
    }, function Error(err){
  
    })
  }


});
