var photosReport = angular.module('RessieApp.components.reports.photos', []);

photosReport.controller('photosReport', function ($scope, $stateParams, Events) {

  var start = $scope.start = $scope.$parent.calendar.date.startDate;

  $scope.$parent.calendar.date.endDate = start;

  $scope.$parent.isSingleDay();
  $scope.$parent.isPrintable();

  (function(parent){
    var date = start.format('MM-DD-YY');
    parent.setPrintTitle('Photo Email List for ' + date)
  })($scope.$parent);

  $scope.prep = function(){
    Events.get(start.clone().format('MM-DD-YYYY'), function(res){
      res.reservations = res.reservations.filter(function(res){
        var line_items = res.line_items;
        return line_items.filter(function(item){
          return /photo/i.test(item.product.name)
        }).length >= 1;
      })
      $scope.data = res;
      $scope.$parent.isReady();
    });
  }

})
