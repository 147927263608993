angular.module('RessieApp.componentTemplates', ['comp/company/departure/template', 'comp/package/package', 'comp/package/widget', 'comp/referrals/referrals', 'comp/reports/ar/report', 'comp/reports/batch/report', 'comp/reports/birdeye/report', 'comp/reports/bookedon/report', 'comp/reports/booking-source/report', 'comp/reports/commissions/filter/template', 'comp/reports/commissions/pay_modal', 'comp/reports/commissions/report', 'comp/reports/deposits/report', 'comp/reports/discrepancies/report', 'comp/reports/gifts/redeem_modal', 'comp/reports/gifts/report', 'comp/reports/glance/report', 'comp/reports/itsheet/report', 'comp/reports/manifest/report', 'comp/reports/merchandise/report', 'comp/reports/package/report', 'comp/reports/photos/report', 'comp/reports/pickups/report', 'comp/reports/pilot-commissions/report', 'comp/reports/ratings/report', 'comp/reports/reports', 'comp/reports/sales/report', 'comp/reports/salesslip/report', 'comp/reports/tips/report', 'comp/reports/waivers/report', 'comp/reservations/booked_online/template', 'comp/whitelist/whitelist']);

angular.module("comp/company/departure/template", []).run(["$templateCache", function($templateCache) {
  $templateCache.put("comp/company/departure/template",
    "<div class=\"panel panel-default\">\n" +
    "  <div class=\"panel-heading\">\n" +
    "    <h4>Manage Departure Times</h4>\n" +
    "  </div>\n" +
    "  <div class=\"panel-body\">\n" +
    "    <table class=\"table\" style=\"margin-bottom: 0\">\n" +
    "      <thead>\n" +
    "        <tr>\n" +
    "          <th>Start</th>\n" +
    "          <th>End</th>\n" +
    "          <th>Time</th>\n" +
    "        </tr>\n" +
    "      </thead>\n" +
    "      <tbody ng-repeat=\"range in dates | orderBy: sortDates track by $index\">\n" +
    "        <tr ng-show=\"isSelected($index)\" class=\"bg-light\">\n" +
    "\n" +
    "          <td>\n" +
    "            <div class=\"input-group pull-left\" style=\"width: 53px; margin-right: 5px;\">\n" +
    "              <input class=\"form-control input input-sm\" type=\"text\" \n" +
    "                ng-disabled=\"!range.start.date\"\n" +
    "                date-entry=\"{ 'format': 'md /' }\"\n" +
    "                ng-model-options=\"{ updateOn: 'default blur', debounce: { 'default': 500, 'blur': 0 } }\"\n" +
    "                ng-model=\"range.start._date\" />\n" +
    "            </div>\n" +
    "            <div class=\"pull-left\" style=\"line-height: 28px\">\n" +
    "              <span class=\"pos-rlt\" style=\"top: 1px\">DST:</span>\n" +
    "              <div class=\"btn-group btn-group-xs\">\n" +
    "                <button type=\"button\" class=\"btn btn-xs btn-default\" \n" +
    "                  ng-class=\"{ 'active': range.start.start_dst }\" \n" +
    "                  ng-click=\"range.start.start_dst = !range.start.start_dst\">Start</button>\n" +
    "                <button type=\"button\" class=\"btn btn-xs btn-default\" \n" +
    "                  ng-class=\"{ 'active': range.start.end_dst }\" \n" +
    "                  ng-click=\"range.start.end_dst = !range.start.end_dst\">End</button>\n" +
    "              </div>\n" +
    "            </div>\n" +
    "          </td>\n" +
    "\n" +
    "          <td>\n" +
    "            <div class=\"input-group pull-left\" style=\"width: 53px; margin-right: 5px;\">\n" +
    "              <input class=\"form-control input input-sm\" type=\"text\" \n" +
    "                ng-disabled=\"!range.end.date\"\n" +
    "                date-entry=\"{ 'format': 'md /' }\"\n" +
    "                ng-model-options=\"{ updateOn: 'default blur', debounce: { 'default': 500, 'blur': 0 } }\"\n" +
    "                ng-model=\"range.end._date\" />\n" +
    "            </div>\n" +
    "            <div class=\"pull-left\" style=\"line-height: 28px\">\n" +
    "              <span class=\"pos-rlt\" style=\"top: 1px\">DST:</span>\n" +
    "              <div class=\"btn-group btn-group-xs\">\n" +
    "                <button type=\"button\" class=\"btn btn-xs btn-default\" \n" +
    "                  ng-disabled=\"range.start.start_dst || range.start.end_dst\"\n" +
    "                  ng-class=\"{ 'active': range.end.start_dst }\"\n" +
    "                  ng-click=\"range.end.start_dst = !range.end.start_dst\">Start</button>\n" +
    "                <button type=\"button\" class=\"btn btn-xs btn-default\" \n" +
    "                  ng-disabled=\"range.start.end_dst\"\n" +
    "                  ng-class=\"{ 'active': range.end.end_dst }\"\n" +
    "                  ng-click=\"range.end.end_dst = !range.end.end_dst\">End</button>\n" +
    "              </div>\n" +
    "            </div>\n" +
    "          </td>\n" +
    "\n" +
    "          <td>\n" +
    "            <div class=\"pos-rlt\">\n" +
    "              <div class=\"input-group\" style=\"width: 82px\">\n" +
    "                <input class=\"form-control input input-sm\" type=\"text\" \n" +
    "                  time=\"{ maxTime: '11:55 AM', 'minutes': 15 }\"\n" +
    "                  ng-model-options=\"{ updateOn: 'default blur', debounce: { 'default': 500, 'blur': 0 } }\"\n" +
    "                  ng-model=\"range._time\" />\n" +
    "              </div>\n" +
    "\n" +
    "              <div class=\"actions pos-abt btn-group\" style=\"top: 3px; right: -33px;\">\n" +
    "                <button type=\"button\" class=\"btn btn-xs btn-info\" ng-click=\"update($index)\"><i class=\"fa fa-check\"></i></button>\n" +
    "                <button type=\"button\" class=\"btn btn-xs btn-danger\" ng-click=\"removeDate($index)\"><i class=\"fa fa-trash\"></i></button>\n" +
    "              </div>\n" +
    "\n" +
    "            </div>\n" +
    "          </td>\n" +
    "        </tr>\n" +
    "        <tr ng-click=\"setEditIndex($index)\" \n" +
    "          ng-hide=\"isSelected($index)\"\n" +
    "          ng-class=\"{ 'text-muted' : editIndex > -1 && !isSelected($index), 'point' : $index !== editIndex }\">\n" +
    "          <td>\n" +
    "            <span ng-if=\"range.start.date\">{{ range.start.date | readTime:'MM/DD':true }}</span>\n" +
    "            <span ng-if=\"range.start.start_dst\">{{ startOfDST | readTime:'MM/DD' }} - Start of DST</span>\n" +
    "            <span ng-if=\"range.start.end_dst\">{{ endOfDST | addTime:'day':1 | readTime:'MM/DD' }} - End of DST</span>\n" +
    "          </td>\n" +
    "          <td>\n" +
    "            <span ng-if=\"range.end.date\">{{ range.end.date | readTime:'MM/DD':true }}</span>\n" +
    "            <span ng-if=\"range.end.start_dst\">{{ startOfDST | subTime:'day':1 | readTime:'MM/DD' }} - Start of DST</span>\n" +
    "            <span ng-if=\"range.end.end_dst\">{{ endOfDST | readTime:'MM/DD' }} - End of DST</span>\n" +
    "          </td>\n" +
    "          <td>{{ range.time | readTime:'hh:mm A':true }}</td>\n" +
    "        </tr>\n" +
    "      </tbody>\n" +
    "      <tfoot>\n" +
    "        <tr class=\"active\" ng-show=\"!adding\"><td colspan=\"3\" class=\"text-center\">\n" +
    "          <button type=\"button\" class=\"btn btn-link btn-block\" ng-click=\"adding = true\">\n" +
    "            Add a Time\n" +
    "          </button>\n" +
    "        </td></tr>\n" +
    "        <tr ng-show=\"adding\" ng-init=\"nextTime()\" class=\"active\">\n" +
    "          <td>\n" +
    "            <div class=\"input-group pull-left\" style=\"width: 53px; margin-right: 5px;\">\n" +
    "              <input class=\"form-control input input-sm\" type=\"text\" \n" +
    "                ng-disabled=\"!newTime.start.date\"\n" +
    "                date-entry=\"{ 'format': 'md /' }\"\n" +
    "                ng-model-options=\"{ updateOn: 'default blur', debounce: { 'default': 500, 'blur': 0 } }\"\n" +
    "                ng-model=\"newTime.start._date\" />\n" +
    "            </div>\n" +
    "            <div class=\"pull-left\" style=\"line-height: 28px\">\n" +
    "              <span class=\"pos-rlt\" style=\"top: 1px\">DST:</span>\n" +
    "              <div class=\"btn-group btn-group-xs\">\n" +
    "                <button type=\"button\" class=\"btn btn-xs btn-default\" \n" +
    "                  ng-class=\"{ 'active': newTime.start.start_dst }\" \n" +
    "                  ng-click=\"newTime.start.start_dst = !newTime.start.start_dst\">Start</button>\n" +
    "                <button type=\"button\" class=\"btn btn-xs btn-default\" \n" +
    "                  ng-class=\"{ 'active': newTime.start.end_dst }\" \n" +
    "                  ng-click=\"newTime.start.end_dst = !newTime.start.end_dst\">End</button>\n" +
    "              </div>\n" +
    "            </div>\n" +
    "          </td>\n" +
    "          <td>\n" +
    "            <div class=\"input-group pull-left\" style=\"width: 53px; margin-right: 5px;\">\n" +
    "              {{ end = (start | addTime:'day':1 | readTime:'MM/DD');'' }}\n" +
    "              <input class=\"form-control input input-sm\" type=\"text\" \n" +
    "                ng-disabled=\"!newTime.end.date\"\n" +
    "                date-entry=\"{ 'format': 'md /' }\"\n" +
    "                ng-model-options=\"{ updateOn: 'default blur', debounce: { 'default': 500, 'blur': 0 } }\"\n" +
    "                ng-model=\"newTime.end._date\" />\n" +
    "            </div>\n" +
    "            <div class=\"pull-left\" style=\"line-height: 28px\">\n" +
    "              <span class=\"pos-rlt\" style=\"top: 1px\">DST:</span>\n" +
    "              <div class=\"btn-group btn-group-xs\">\n" +
    "                <button type=\"button\" class=\"btn btn-xs btn-default\" \n" +
    "                  ng-class=\"{ 'active': newTime.end.start_dst }\" \n" +
    "                  ng-click=\"newTime.end.start_dst = !newTime.end.start_dst\">Start</button>\n" +
    "                <button type=\"button\" class=\"btn btn-xs btn-default\" \n" +
    "                  ng-class=\"{ 'active': newTime.end.end_dst }\" \n" +
    "                  ng-click=\"newTime.end.end_dst = !newTime.end.end_dst\">End</button>\n" +
    "              </div>\n" +
    "            </div>\n" +
    "          </td>\n" +
    "          <td>\n" +
    "            <div class=\"pos-rlt\">\n" +
    "              <div class=\"input-group\" style=\"width: 82px\">\n" +
    "                <input class=\"form-control input input-sm\" type=\"text\" \n" +
    "                  time=\"{ maxTime: '11:55 AM', 'minutes': 15 }\"\n" +
    "                  ng-model-options=\"{ updateOn: 'default blur', debounce: { 'default': 500, 'blur': 0 } }\"\n" +
    "                  ng-model=\"newTime.time\" />\n" +
    "              </div>\n" +
    "              <div class=\"actions pos-abt\" style=\"top: 3px; right: -33px;\">\n" +
    "                <button type=\"button\" class=\"btn btn-xs btn-success\" ng-click=\"addTime(newTime)\"><i class=\"fa fa-plus\"></i></button>\n" +
    "              </div>\n" +
    "            </div>\n" +
    "          </td>\n" +
    "        </tr>\n" +
    "      </tfoot>\n" +
    "    </table>\n" +
    "  </div>\n" +
    "\n" +
    "  \n" +
    "</div>\n" +
    "");
}]);

angular.module("comp/package/package", []).run(["$templateCache", function($templateCache) {
  $templateCache.put("comp/package/package",
    "<div class=\"panel panel-default\">\n" +
    "  <div class=\"panel-heading\">Build {{ $ctrl.package.name }} Public Form</div>\n" +
    "  <div class=\"panel-body\">\n" +
    "    <div class=\"row\">\n" +
    "      <div class=\"col-md-12\">\n" +
    "        <div class=\"form-group\">\n" +
    "          <input class=\"form-control input-lg\" ng-model=\"$ctrl.builder.header\" placeholder=\"Form Title\" type=\"text\" />\n" +
    "        </div>\n" +
    "      </div>\n" +
    "    </div>\n" +
    "    <div class=\"row\">\n" +
    "      <div class=\"col-md-4\">\n" +
    "        <h4>Modifiers</h4>\n" +
    "        <div class=\"checkbox\">\n" +
    "          <label><input type=\"checkbox\" ng-model=\"$ctrl.builder.data.vip\" /> VIP</label>\n" +
    "        </div>\n" +
    "        <div class=\"checkbox\">\n" +
    "          <label><input type=\"checkbox\" ng-model=\"$ctrl.builder.data.hotel\" /> Hotel</label>\n" +
    "        </div>\n" +
    "        <div class=\"checkbox\">\n" +
    "          <label><input type=\"checkbox\" ng-model=\"$ctrl.builder.data.group\" /> Group</label>\n" +
    "        </div>  \n" +
    "        <div class=\"checkbox\">\n" +
    "          <label><input type=\"checkbox\" ng-model=\"$ctrl.builder.data.no_brunch\" /> No Brunch</label>\n" +
    "        </div>  \n" +
    "        <div class=\"checkbox\">\n" +
    "          <label><input type=\"checkbox\" ng-model=\"$ctrl.builder.data.bundle\" /> Bundle</label>\n" +
    "        </div>\n" +
    "      </div>\n" +
    "      <div class=\"col-md-8\">\n" +
    "\n" +
    "        <div class=\"row\">\n" +
    "          <div class=\"col-md-12\">\n" +
    "            <h4>Defaults</h4>\n" +
    "            <div class=\"row\">\n" +
    "              <div class=\"col-md-12\">\n" +
    "                <div class=\"checkbox\">\n" +
    "                  <label><input type=\"checkbox\" ng-model=\"$ctrl.builder.data.autoDay\" /> Automatically assign first available day.</label>\n" +
    "                </div>\n" +
    "              </div>\n" +
    "            </div>\n" +
    "            <div class=\"row\">\n" +
    "              <div class=\"form-group col-md-6\">\n" +
    "                <input class=\"form-control input-sm\" ng-model=\"$ctrl.builder.data.adults\" placeholder=\"Adults\" type=\"number\" />\n" +
    "              </div>\n" +
    "              <div class=\"form-group col-md-6\">\n" +
    "                <input class=\"form-control input-sm\" ng-model=\"$ctrl.builder.data.children\" ng-disabled=\"$ctrl.package.adults\" placeholder=\"Children\" type=\"number\" />\n" +
    "              </div>\n" +
    "            </div>\n" +
    "          </div>\n" +
    "        </div>\n" +
    "\n" +
    "        <div class=\"row\" ng-if=\"$ctrl.builder.data.hotel\">\n" +
    "          <div class=\"col-md-12\">\n" +
    "            <h4>Hotel</h4>\n" +
    "            <ui-select name=\"hotel\" ng-model=\"$ctrl.selected_hotel\" style=\"width: 100%\">\n" +
    "              <ui-select-match placeholder=\"Select a Hotel\">\n" +
    "                <span>{{ $select.selected.name }}</span>\n" +
    "              </ui-select-match>\n" +
    "              <ui-select-choices repeat=\"hotel in $ctrl.hotels_list | toArray | orderBy:'name' | filter: $select.search\">\n" +
    "                {{ hotel.name }}\n" +
    "              </ui-select-choices>\n" +
    "            </ui-select>\n" +
    "          </div>\n" +
    "        </div>\n" +
    "\n" +
    "        <div class=\"row\" ng-if=\"$ctrl.builder.data.bundle\">\n" +
    "          <div class=\"col-md-12\">\n" +
    "            <h4>Bundle</h4>\n" +
    "            <ui-select name=\"hotel\" ng-model=\"$ctrl.selected_bundle\" style=\"width: 100%\">\n" +
    "              <ui-select-match placeholder=\"Select a Bundle\">\n" +
    "                <span>{{ $select.selected.name }}</span>\n" +
    "              </ui-select-match>\n" +
    "              <ui-select-choices repeat=\"bundle in $ctrl.bundles_list | orderBy:'code' | filter: $select.search\">\n" +
    "                {{ bundle.code }}\n" +
    "              </ui-select-choices>\n" +
    "            </ui-select>\n" +
    "          </div>\n" +
    "        </div>\n" +
    "\n" +
    "        <div class=\"row\">\n" +
    "          <div class=\"col-md-12\">\n" +
    "            <h4>Locks</h4>\n" +
    "            <div class=\"checkbox\">\n" +
    "              <label><input type=\"checkbox\" ng-model=\"$ctrl.builder.locks.adults\" /> Adults</label>\n" +
    "            </div>\n" +
    "            <div class=\"checkbox\">\n" +
    "              <label><input type=\"checkbox\" ng-model=\"$ctrl.builder.locks.children\" /> Children</label>\n" +
    "            </div>  \n" +
    "          </div>\n" +
    "        </div>\n" +
    "\n" +
    "      </div>\n" +
    "    </div>\n" +
    "  </div>\n" +
    "</div>\n" +
    "\n" +
    "<h4 class=\"clearfix\">Direct Link\n" +
    "  <!-- <button type=\"button\" class=\"btn btn-xs bg-light pull-right\" copy=\"#externalURL\">\n" +
    "    <i class=\"fa fa-clipboard ngFadeIn\" ng-show=\"!success\"></i>\n" +
    "    <i class=\"fa fa-check ngFadeIn\" ng-show=\"success\"></i>\n" +
    "  </button> -->\n" +
    "</h4>\n" +
    "<pre><code decode=\"true\" id=\"externalURL\" class=\"html\">\n" +
    "   {{ $ctrl.link }}\n" +
    "</code></pre>\n" +
    "");
}]);

angular.module("comp/package/widget", []).run(["$templateCache", function($templateCache) {
  $templateCache.put("comp/package/widget",
    "<h4>Popup Form</h4>\n" +
    "<pre ng-if=\"$ctrl.data.public\"><code>\n" +
    "{{ $ctrl.code.popup }}\n" +
    "</code></pre>\n" +
    "\n" +
    "<h4>Inline Form</h4>\n" +
    "<pre ng-if=\"$ctrl.data.public\"><code>\n" +
    "  <rezflow-inline package=\"{{$ctrl.data._id}}\"{{adults}}></rezflow-inline>\n" +
    "</code></pre>");
}]);

angular.module("comp/referrals/referrals", []).run(["$templateCache", function($templateCache) {
  $templateCache.put("comp/referrals/referrals",
    "<div class=\"panel panel-default\">\n" +
    "  <div class=\"panel-heading\"><h4>Referral Sources</h4></div>\n" +
    "  <div class=\"panel-body\"><form></form> <!-- adding this empty form is the only thing that makes the one below register ¯\\_(ツ)_/¯ -->\n" +
    "    <form name=\"form\">\n" +
    "      <ul class=\"list-unstyled\">\n" +
    "        <li ng-repeat=\"item in $ctrl.company.meta.sources\" class=\"clearfix\" style=\"padding: 5px 5px 5px 12px; line-height: 30px;\">\n" +
    "          {{ item }}\n" +
    "          <button type=\"button\" class=\"btn btn-link pull-right\" ng-click=\"$ctrl.remove($index)\"><i class=\"fa fa-minus\"></i></button>\n" +
    "        </li>\n" +
    "        <li class=\"input-group\">\n" +
    "          <input type=\"text\" name=\"referral\" ng-model=\"$ctrl.referral\" class=\"form-control\" placeholder=\"referral\" />\n" +
    "          <span class=\"input-group-btn\">\n" +
    "            <button type=\"button\" class=\"btn btn-success\" ng-disabled=\"!$ctrl.referral || form.$error.referral\" ng-click=\"$ctrl.add()\">\n" +
    "              <i class=\"fa fa-plus\"></i>\n" +
    "            </button>\n" +
    "          </span>\n" +
    "        </li>\n" +
    "      </ul>\n" +
    "    </form>\n" +
    "  </div>\n" +
    "</div>");
}]);

angular.module("comp/reports/ar/report", []).run(["$templateCache", function($templateCache) {
  $templateCache.put("comp/reports/ar/report",
    "<div class=\"col-md-7\" print-section ng-if=\"allowed\">\n" +
    "\n" +
    "  <div ng-init=\"showPartner = false;\" class=\"row\">\n" +
    "\n" +
    "    <div sales-report=\"data\" start=\"start\" end=\"end\">\n" +
    "\n" +
    "      <div class=\"pull-right\">\n" +
    "\n" +
    "        <div class=\"btn-group\">\n" +
    "\n" +
    "          <button type=\"button\"\n" +
    "            class=\"btn bg-light\"\n" +
    "            ng-click=\"setFilter('all', 'paid')\"\n" +
    "            ng-class=\"{ 'active': filter.ar.paid == 'all' }\">All</button>\n" +
    "          <button type=\"button\"\n" +
    "            class=\"btn bg-light\"\n" +
    "            ng-click=\"setFilter('paid', 'paid')\"\n" +
    "            ng-class=\"{ 'active': filter.ar.paid == 'paid' }\">Paid</button>\n" +
    "          <button type=\"button\"\n" +
    "            class=\"btn bg-light\"\n" +
    "            ng-click=\"setFilter('unpaid', 'paid')\"\n" +
    "            ng-class=\"{ 'active': filter.ar.paid == 'unpaid' }\">Unpaid</button>\n" +
    "          <button type=\"button\"\n" +
    "            class=\"btn bg-light\"\n" +
    "            ng-click=\"setFilter('cancelled', 'paid')\"\n" +
    "            ng-class=\"{ 'active': filter.ar.paid == 'cancelled' }\">Cancelled</button>\n" +
    "          <button ng-if=\"showPartner\" type=\"button\"\n" +
    "            class=\"btn bg-light\"\n" +
    "            ng-click=\"$parent.showPartner = false;\">Clear Partner Filter</button>\n" +
    "\n" +
    "        </div>\n" +
    "\n" +
    "        <button type=\"button\" class=\"btn bg-danger hidden-print\" ng-click=\"clearSales()\">Clear Cache</button>\n" +
    "\n" +
    "      </div>\n" +
    "\n" +
    "      <table style=\"width: 100%;\">\n" +
    "        <tbody>\n" +
    "          <tr ng-repeat=\"(key, group) in bill_to_grouped\"\n" +
    "            ng-init=\"show[key] = true\"\n" +
    "            ng-show=\"!showPartner || showPartner == key\">\n" +
    "            <td>\n" +
    "              <h3>\n" +
    "                <button class=\"btn btn-link hidden-print\" ng-click=\"show[key] = !show[key]\">\n" +
    "                  <i class=\"fa\" ng-class=\"{ 'fa-caret-down': show[key], 'fa-caret-right': !show[key] }\"></i>\n" +
    "                </button>\n" +
    "                {{ partner = group[0].partner ? group[0].partner.name : 'Unlinked';'' }}\n" +
    "                <span class=\"point\" ng-click=\"$parent.showPartner = key\">{{ partner }}</span>\n" +
    "              </h3>\n" +
    "\n" +
    "              <table class=\"table\">\n" +
    "                <thead>\n" +
    "                  <tr>\n" +
    "                    <th style=\"width: 10%\" ng-click=\"sort = 'conf'; rev = !rev\">#</th>\n" +
    "                    <th style=\"width: 10%\" ng-click=\"sort = 'date'; rev = !rev\">Date</th>\n" +
    "                    <th style=\"width: 5%\">Count</th>\n" +
    "                    <th style=\"width: 30%\" ng-click=\"sort = 'name'; rev = !rev\">Guest</th>\n" +
    "                    <th style=\"width: 25%\">Account</th>\n" +
    "                    <th style=\"width: 10%\" ng-click=\"sort = 'amount'; rev = !rev\">Amount Due</th>\n" +
    "                    <th style=\"width: 10%\" class=\"text-center\">Paid</th>\n" +
    "                  </tr>\n" +
    "                </thead>\n" +
    "                <tbody ng-show=\"!show[key]\">\n" +
    "                  <tr class=\"active\">\n" +
    "                    <td colspan=\"6\" class=\"text-center\"><em>{{ group.length }} items hidden</em></td>\n" +
    "                  </tr>\n" +
    "                </tbody>\n" +
    "                <tbody ng-show=\"show[key]\">\n" +
    "                  <tr ng-repeat=\"charge in group | orderBy: arSort : rev | filter: showBooking\"\n" +
    "                      ng-init=\"booking = data[charge.reference.model][charge.reference._id]\">\n" +
    "                    <td>\n" +
    "                      <span ng-class=\"{ 'strike': charge.booking_status === 'cancelled'}\">\n" +
    "                        {{ user.activeCompany().nickname }}{{ booking.confirmation}}\n" +
    "                      </span>\n" +
    "                    </td>\n" +
    "                    <td>{{ charge.date | readTime: 'MM-DD-YY':true }}</td>\n" +
    "                    <td>{{ (booking.adults || 0) + (booking.children || 0) }}</td>\n" +
    "                    <td>\n" +
    "                      <span ng-if=\"charge.reference.model === 'Prepayment'\">\n" +
    "                        {{ charge.reference.name.first + ', ' + charge.reference.name.last }}\n" +
    "                      </span>\n" +
    "                      <a ng-if=\"charge.reference.model === 'Reservation'\" ng-href=\"/booking/{{booking.confirmation}}\">\n" +
    "                        {{ booking.group_name || booking.last_name + ', ' + booking.first_name }}\n" +
    "                      </a>\n" +
    "                    </td>\n" +
    "                    <td>\n" +
    "                      <span ng-show=\"charge.partner\">{{ charge.partner.name }}</span> {{ charge.type }}\n" +
    "                    </td>\n" +
    "                    <td>{{ charge.amount | currency }}</td>\n" +
    "                    <td>\n" +
    "                      <label class=\"block text-center\">\n" +
    "                        <input type=\"checkbox\" ng-model=\"charge.paid\" ng-change=\"updateBillTo(charge)\"/>\n" +
    "                      </label>\n" +
    "                    </td>\n" +
    "                  </tr>\n" +
    "                </tbody>\n" +
    "                <tfoot>\n" +
    "                  <tr>\n" +
    "                    <td></td>\n" +
    "                    <td></td>\n" +
    "                    <td><strong>{{ countGuests(group) }}</strong></td>\n" +
    "                    <td></td>\n" +
    "                    <td></td>\n" +
    "                    <td><strong>{{ totalAR(group, filter.ar.paid) | currency }}</strong></td>\n" +
    "                    <td></td>\n" +
    "                  </tr>\n" +
    "                </tfoot>\n" +
    "              </table>\n" +
    "            </td>\n" +
    "          </tr>\n" +
    "        </tbody>\n" +
    "      </table>\n" +
    "\n" +
    "    </div>\n" +
    "\n" +
    "  </div>\n" +
    "\n" +
    "</div>\n" +
    "\n" +
    "<div ng-if=\"!allowed\" class=\"row\">\n" +
    "  <div class=\"col-md-7\"><span class=\"text-muted\">Report not Authorized</span></div>\n" +
    "</div>\n" +
    "");
}]);

angular.module("comp/reports/batch/report", []).run(["$templateCache", function($templateCache) {
  $templateCache.put("comp/reports/batch/report",
    "<div class=\"row\" style=\"margin-top: 20px;\" ng-if=\"allowed\" print-section>\n" +
    "\n" +
    "  <div class=\"col-md-12 col-lg-10 col-xl-6\">\n" +
    "\n" +
    "    <div sales-report=\"data\" start=\"start\" end=\"end\">\n" +
    "\n" +
    "      {{ totalFees = (data.fees.charged - data.fees.refunded); '' }}\n" +
    "\n" +
    "      {{ deposit = (data.credit_cards.total || (compiled.debits.credit_card - compiled.credits.refunds) - totalFees);'' }}\n" +
    "\n" +
    "      <h3 style=\"padding-left: 8px; margin-bottom: 20px;\">\n" +
    "        {{ balance = (data.balance || compiled.balance); ''}}\n" +
    "        Balance: <span ng-class=\"{ 'text-danger': balance < 0 }\">\n" +
    "          {{ balance | currency }}\n" +
    "        </span>\n" +
    "        <div class=\"pull-right hidden-print\" ng-if=\"!locked\" style=\"font-size: 13px; line-height: 1.5\">\n" +
    "          <div ng-if=\"data.stripe_balance < deposit\" class=\"checkbox-inline\" style=\"padding-left: 0; padding-right: 20px; margin-right: 10px;\">\n" +
    "            <label>\n" +
    "              skip funds transfer\n" +
    "              <input type=\"checkbox\" ng-model=\"data.ignore_transfer\" aria-label=\"do not transfer funds\" style=\"right: 0; top: 0;\">\n" +
    "            </label>\n" +
    "          </div>\n" +
    "          <button\n" +
    "            type=\"button\"\n" +
    "            ng-click=\"report.batch(data.ignore_transfer, updateBatchTable)\"\n" +
    "            class=\"btn btn-md bg-info\"\n" +
    "            style=\"min-width: 70px;\"\n" +
    "            ng-disabled=\"report.working || (!data.ignore_transfer && data.stripe_balance < deposit)\">\n" +
    "              <span ng-if=\"!report.working\">Batch</span>\n" +
    "              <span ng-if=\"report.working\" style=\"display: inline-block; top: -8px; position: relative;\" spin=\"{\n" +
    "                lines: 17,\n" +
    "                length: 0,\n" +
    "                width: 4,\n" +
    "                radius: 4,\n" +
    "                corners: 1,\n" +
    "                rotate: 0,\n" +
    "                direction: 1,\n" +
    "                color: '#fff',\n" +
    "                speed: 1.2,\n" +
    "                trail: 36,\n" +
    "                shadow: false,\n" +
    "                hwaccel: false,\n" +
    "                className: 'spinner',\n" +
    "                zIndex: 2e9,\n" +
    "                top: '-5px',\n" +
    "                left: 'auto'\n" +
    "              }\"></span>\n" +
    "          </button>\n" +
    "          <button type=\"button\" class=\"btn bg-danger\" ng-click=\"clearSales()\">Clear Cache</button>\n" +
    "        </div>\n" +
    "\n" +
    "      </h3>\n" +
    "\n" +
    "      <div class=\"col-md-6 no-pad\" style=\"margin-bottom: 20px;\">\n" +
    "        <table class=\"table table-borderless table-condensed\">\n" +
    "          <tbody>\n" +
    "            <tr>\n" +
    "              <td style=\"width: 50%\"><strong class=\"upper\">Stripe Balance</strong></td>\n" +
    "              <td style=\"width: 50%\">\n" +
    "                <span>{{ data.stripe_balance || 0 | currency }}</span>\n" +
    "              </td>\n" +
    "            </tr>\n" +
    "            <tr>\n" +
    "              <td style=\"width: 50%\"><strong class=\"upper\">Total CC Deposit</strong></td>\n" +
    "              <td style=\"width: 50%\">{{ deposit | currency }}</td>\n" +
    "            </tr>\n" +
    "            <tr>\n" +
    "              <td style=\"width: 50%\"><strong class=\"upper\">Total Cash Deposit</strong></td>\n" +
    "              <td style=\"width: 50%\">\n" +
    "                <span ng-if=\"data.is_batch\">{{ data.cash.total || 0 | currency }}</span>\n" +
    "                <span ng-if=\"!data.is_batch\">{{ compiled.debits.cash || 0 | currency }}</span>\n" +
    "              </td>\n" +
    "            </tr>\n" +
    "          </tbody>\n" +
    "        </table>\n" +
    "      </div>\n" +
    "\n" +
    "      <div class=\"row\" style=\"margin-bottom: 20px;\">\n" +
    "        <div class=\"col-md-12\">\n" +
    "\n" +
    "          <table class=\"table\" print-table=\"compiled\">\n" +
    "            <thead>\n" +
    "              <tr>\n" +
    "                <th style=\"width: 33%\">Item</th>\n" +
    "                <th style=\"width: 25%\">Sales</th>\n" +
    "                <th style=\"width: 25%\">Refunds</th>\n" +
    "                <th style=\"width: 25%\">Total</th>\n" +
    "              </tr>\n" +
    "            </thead>\n" +
    "            <tbody>\n" +
    "              <tr>\n" +
    "                <td><strong>Credit Cards</strong></td>\n" +
    "                <td>{{ data.credit_cards.sales || (compiled.debits.credit_card) || 0 | currency }}</td>\n" +
    "                <td>{{ (data.credit_cards.refunds || (compiled.credits.refunds)) || 0 | currency }}</td>\n" +
    "                <td>{{ (compiled.debits.credit_card - compiled.credits.refunds) || 0 | currency }}</td>\n" +
    "              </tr>\n" +
    "              <tr>\n" +
    "                <td><strong>Square POS</strong></td>\n" +
    "                <td>{{ (compiled.debits.square) || 0 | currency }}</td>\n" +
    "                <td>{{ (data.square.refunds || (compiled.credits.square)) || 0 | currency }}</td>\n" +
    "                <td>{{ (compiled.debits.square - compiled.credits.square) || 0 | currency }}</td>\n" +
    "              </tr>\n" +
    "              <tr ng-hide=\"true\">\n" +
    "                <td><strong>FareHarbor</strong></td>\n" +
    "                <td>{{ (compiled.debits.fareharbor) || 0 | currency }}</td>\n" +
    "                <td>{{ (data.fareharbor.refunds || (compiled.credits.fareharbor)) || 0 | currency }}</td>\n" +
    "                <td>{{ (compiled.debits.fareharbor - compiled.credits.fareharbor) || 0 | currency }}</td>\n" +
    "              </tr>\n" +
    "              <tr>\n" +
    "                <td><strong>Clover</strong></td>\n" +
    "                <td>{{ (compiled.debits.clover) || 0 | currency }}</td>\n" +
    "                <td>{{ (data.clover.refunds || (compiled.credits.clover)) || 0 | currency }}</td>\n" +
    "                <td>{{ (compiled.debits.clover - compiled.credits.clover) || 0 | currency }}</td>\n" +
    "              </tr>\n" +
    "\n" +
    "              <tr>\n" +
    "                <td><strong>Processing Fees</strong></td>\n" +
    "                <td>{{ data.fees.charged || 0 | currency }}</td>\n" +
    "                <td>{{ data.fees.refunded || 0 | currency }}</td>\n" +
    "                <td>{{ data.fees.charged - data.fees.refunded || 0 | currency }}</td>\n" +
    "              </tr>\n" +
    "              <tr>\n" +
    "                <td><strong>Cash</strong></td>\n" +
    "                <td>{{ data.cash.sales || compiled.debits.cash || 0 | currency }}</td>\n" +
    "                <td>{{ data.cash.refunds || 0 | currency }}</td>\n" +
    "                <td>{{ data.cash.total || compiled.debits.cash || 0 | currency }}</td>\n" +
    "              </tr>\n" +
    "            </tbody>\n" +
    "          </table>\n" +
    "\n" +
    "        </div>\n" +
    "      </div>\n" +
    "\n" +
    "      <div class=\"row\" style=\"margin-bottom: 20px;\">\n" +
    "\n" +
    "        <div class=\"col-md-8 no-pad-rt\">\n" +
    "\n" +
    "          <table class=\"table table-borderless-top\" print-table=\"compiled\">\n" +
    "            <thead>\n" +
    "              <tr>\n" +
    "                <th>Item</th>\n" +
    "                <th>Sales</th>\n" +
    "              </tr>\n" +
    "            </thead>\n" +
    "            <tbody>\n" +
    "              <tr>\n" +
    "                <td style=\"width: 50%; font-weight: bold;\">A/R</td>\n" +
    "                <td>{{ data.sales.ar || compiled.debits.ar || 0 | currency }}</td>\n" +
    "              </tr>\n" +
    "              <tr>\n" +
    "                <td style=\"width: 50%; font-weight: bold;\">Deposits Redeemed</td>\n" +
    "                <td>{{ compiled.debits.deposits_redeemed || 0 | currency }}</td>\n" +
    "              </tr>\n" +
    "              <tr>\n" +
    "                <td style=\"width: 50%; font-weight: bold;\">GC Redeemed</td>\n" +
    "                <td>{{ data.sales.gifts_redeemed || compiled.debits.gifts_redeemed || 0 | currency }}</td>\n" +
    "              </tr>\n" +
    "              <tr>\n" +
    "                <td style=\"width: 50%; font-weight: bold;\">Discounts</td>\n" +
    "                <td>{{ data.sales.discounts || compiled.debits.discounts || 0 | currency }}</td>\n" +
    "              </tr>\n" +
    "            </tbody>\n" +
    "          </table>\n" +
    "\n" +
    "        </div>\n" +
    "\n" +
    "      </div>\n" +
    "\n" +
    "      <div class=\"row\">\n" +
    "\n" +
    "        <div class=\"col-md-8 no-pad-rt\">\n" +
    "\n" +
    "          <table class=\"table table-borderless-top\" print-table=\"compiled\">\n" +
    "            <thead>\n" +
    "              <tr>\n" +
    "                <th style=\"width: 50%;\">Item</th>\n" +
    "                <th style=\"width: 16.6%\">Sales</th>\n" +
    "                <th style=\"width: 16.6%\">Refunds</th>\n" +
    "                <!-- <th style=\"width: 16.6%\">Total</th> --> </tr>\n" +
    "            </thead>\n" +
    "            <tbody>\n" +
    "              <tr>\n" +
    "                <td style=\"font-weight: bold;\">Flights</td>\n" +
    "                <td>{{ compiled.credits.flights || 0 | currency }}</td>\n" +
    "                <td>N/A</td>\n" +
    "                <!-- <td>{{ (compiled.credits.flights || 0) - (compiled.debits.flights_refunded || 0) | currency }}</td> -->\n" +
    "              </tr>\n" +
    "              <tr>\n" +
    "                <td style=\"font-weight: bold;\">Tips</td>\n" +
    "                <td>{{ data.sales.tips || compiled.credits.tips || 0 | currency }}</td>\n" +
    "                <td>N/A</td>\n" +
    "                <!-- <td>{{ 0 | currency }}</td> -->\n" +
    "              </tr>\n" +
    "              <tr>\n" +
    "                <td style=\"font-weight: bold;\">MDSE</td>\n" +
    "                <td>{{ data.sales.mdse || compiled.credits.mdse || 0 | currency }}</td>\n" +
    "                <td>N/A</td>\n" +
    "                <!-- <td>{{ 0 | currency }}</td> --> </tr>\n" +
    "              <tr>\n" +
    "                <td style=\"font-weight: bold;\">Pick-Ups</td>\n" +
    "                <td>{{ data.sales.pickups || compiled.credits.pickup || 0 | currency }}</td>\n" +
    "                <td>N/A</td>\n" +
    "                <!-- <td>{{ 0 | currency }}</td> --> </tr>\n" +
    "              <tr>\n" +
    "                <td style=\"font-weight: bold;\">GC Sold</td>\n" +
    "                <td>{{ data.sales.gifts_sold || compiled.credits.gifts || 0 | currency }}</td>\n" +
    "                <td>N/A</td>\n" +
    "                <!-- <td>{{ 0 | currency }}</td> --> </tr>\n" +
    "              <tr>\n" +
    "                <td style=\"font-weight: bold;\">Refunds</td>\n" +
    "                <td>N/A</td>\n" +
    "                <td>{{ (compiled.credits.refunds || 0) | currency }}</td>\n" +
    "                <!-- <td>{{ 0 | currency }}</td> --> </tr>\n" +
    "              <tr>\n" +
    "                <td style=\"font-weight: bold;\">Deposits</td>\n" +
    "                <td>{{ (compiled.credits.deposits_received || 0) | currency }}</td>\n" +
    "                <td>{{ compiled.debits.deposits_refunded || 0 | currency }}</td>\n" +
    "                <!-- <td>{{ (compiled.credits.deposits_received || 0) - (compiled.debits.deposits_refunded || 0) | currency }}</td> --> </tr>\n" +
    "            </tbody>\n" +
    "          </table>\n" +
    "\n" +
    "        </div>\n" +
    "\n" +
    "      </div>\n" +
    "\n" +
    "      <div class=\"row hidden-print\">\n" +
    "\n" +
    "        <div class=\"col-lg-8 col-md-12\">\n" +
    "          <h3>Previous Batches</h3>\n" +
    "          <table class=\"table\" print-table=\"batches\">\n" +
    "            <thead>\n" +
    "              <tr>\n" +
    "                <th style=\"width: 25%\">Range</th>\n" +
    "                <th style=\"width: 25%\">Batched</th>\n" +
    "                <th style=\"width: 25%\">Amount</th>\n" +
    "                <th style=\"width: 25%\" class=\"text-center\">Transfer</th>\n" +
    "              </tr>\n" +
    "            </thead>\n" +
    "            <tbody>\n" +
    "              <tr class=\"hidden-print\"><td colspan=\"4\">\n" +
    "                <a href=\"\" ng-click=\"goToDate(today)\" class=\"btn block btn-link bg-light\">Next Batch</a>\n" +
    "              </td></tr>\n" +
    "              <tr ng-repeat=\"batch in batches | orderBy: 'end': true\">\n" +
    "                <td>\n" +
    "                  <a\n" +
    "                    ng-click=\"goToDate(batch.start, batch.end)\"\n" +
    "                    href=\"#\" class=\"btn btn-link btn-sm\">\n" +
    "                    <strong>{{ batch.start | readTime: 'MM-DD-YY':true }}</strong> thru <strong>{{ batch.end | readTime: 'MM-DD-YY':true }}</strong>\n" +
    "                  </a>\n" +
    "                </td>\n" +
    "                <td><span class=\"nowrap\">{{ batch.created | readTime: 'MM-DD-YY' }}</span></td>\n" +
    "                <td>\n" +
    "                  <span ng-class=\"{ 'text-muted': batch.transfer === 'ignored'}\">{{ batch.credit_cards.total - batch.fees.total | currency }}</span>\n" +
    "                </td>\n" +
    "                <td class=\"text-center\">\n" +
    "                  <span ng-if=\"batch.transfer === 'ignored'\">--</span>\n" +
    "                  <a ng-if=\"batch.transfer !== 'ignored'\"\n" +
    "                    class=\"btn btn-link\"\n" +
    "                    target=\"_blank\"\n" +
    "                    ng-href=\"https://dashboard.stripe.com/transfers/{{ batch.transfer }}\">View Transfer</a>\n" +
    "                </td>\n" +
    "              </tr>\n" +
    "            </tbody>\n" +
    "          </table>\n" +
    "        </div>\n" +
    "\n" +
    "      </div>\n" +
    "\n" +
    "    </div>\n" +
    "\n" +
    "  </div>\n" +
    "\n" +
    "</div>\n" +
    "\n" +
    "<div ng-if=\"!allowed\" class=\"row\">\n" +
    "  <div class=\"col-md-7\"><span class=\"text-muted\">Report not Authorized</span></div>\n" +
    "</div>\n" +
    "");
}]);

angular.module("comp/reports/birdeye/report", []).run(["$templateCache", function($templateCache) {
  $templateCache.put("comp/reports/birdeye/report",
    "<div class=\"row\" print-section>\n" +
    "  <div class=\"col-md-5\">\n" +
    "    <table class=\"table table-striped\">\n" +
    "      <thead>\n" +
    "        <tr>\n" +
    "          <th>First Name</th>\n" +
    "          <th>Last Name</th>\n" +
    "          <th>Email</th>\n" +
    "          <th>Phone</th>\n" +
    "        </tr>\n" +
    "      </thead>\n" +
    "      <tbody>\n" +
    "        <tr ng-repeat=\"booking in bookings\">\n" +
    "          <td colspan=\"2\" ng-if=\"booking.group_name\">{{ booking.group_name}}</td>\n" +
    "          <td ng-if=\"!booking.group_name\">{{ booking.first_name }}</td>\n" +
    "          <td ng-if=\"!booking.group_name\">{{ booking.last_name }}</td>\n" +
    "          <td>{{ booking.email || 'n/a' }}</td>\n" +
    "          <td>{{ booking.phone }}</td>\n" +
    "        </tr>\n" +
    "      </tbody>\n" +
    "    </table>\n" +
    "  </div>\n" +
    "</div>");
}]);

angular.module("comp/reports/bookedon/report", []).run(["$templateCache", function($templateCache) {
  $templateCache.put("comp/reports/bookedon/report",
    "<div class=\"row\" style=\"margin-top: 20px;\" print-section>\n" +
    "\n" +
    "  <div class=\"row\">\n" +
    "    <div class=\"col-md-5\">\n" +
    "      <h3>Overview</h3>\n" +
    "      <table class=\"table table-striped\" print-table=\"data\">\n" +
    "        <thead>\n" +
    "          <tr>\n" +
    "            <th>Booked By</th>\n" +
    "            <th>Bookings Created</th>\n" +
    "            <th>Total Booked</th>\n" +
    "          </tr>\n" +
    "        </thead>\n" +
    "        <tbody>\n" +
    "          <tr ng-repeat=\"(agent, bookings) in bookedByAgent\">\n" +
    "            <td>\n" +
    "              {{ agent }}\n" +
    "              <!-- <a ng-href=\"/day/bookings/{{ day | readTime: 'MM-DD-YY' }}\"\n" +
    "                class=\"btn btn-link\">{{ day | readTime: 'MM/DD/YY' }}</a> -->\n" +
    "            </td>\n" +
    "            <td>{{ (bookings | filter:getActive).length }}</td>\n" +
    "            <td>{{ countGuests(bookings) }}</td>\n" +
    "          </tr>\n" +
    "        </tbody>\n" +
    "        <tfoot>\n" +
    "          <tr>\n" +
    "            <td><strong>Totals</strong></td>\n" +
    "            <td><strong>{{ countAllBooked(data) }}</strong></td>\n" +
    "            <td><strong>{{ countAllGuestsBooked(data) }}</strong></td>\n" +
    "          </tr>\n" +
    "        </tfoot>\n" +
    "      </table>\n" +
    "    \n" +
    "    </div>\n" +
    "  </div>\n" +
    "  <div class=\"row\">\n" +
    "    <div class=\"col-md-5\">\n" +
    "      \n" +
    "      <div class=\"row\" ng-repeat=\"(day, bookings) in data\">\n" +
    "        <div class=\"col-md-12\">\n" +
    "          <h3>{{ day }}</h3>\n" +
    "          <table class=\"table table-striped\">\n" +
    "            <thead>\n" +
    "              <tr>\n" +
    "                <th>Confirmation</th>\n" +
    "                <th>Flight Date</th>\n" +
    "                <th>Name</th>\n" +
    "                <th>Guest Count</th>\n" +
    "                <th>Booked By</th>\n" +
    "              </tr>\n" +
    "            </thead>\n" +
    "            <tbody>\n" +
    "              <tr ng-repeat=\"booking in bookings | filter:getActive\">\n" +
    "                <td> {{ user.activeCompany().nickname }}{{ booking.confirmation }}</td>\n" +
    "                <td>{{ booking.day | readTime:'MM/DD/YY' }}</td>\n" +
    "                <td>\n" +
    "                  <span ng-if=\"booking.group\">{{ booking.group_name }}</span>\n" +
    "                  <span ng-if=\"!booking.group\">{{ booking.last_name }}, {{ booking.first_name }}</span>\n" +
    "                </td>\n" +
    "                <td>{{ booking.adults + (booking.children || 0) }}</td>\n" +
    "                <td>\n" +
    "                  {{ booking.trace[0].user.name || 'Online' }}\n" +
    "                </td>\n" +
    "              </tr>\n" +
    "            </tbody>\n" +
    "          </table>\n" +
    "        </div>\n" +
    "      </div>\n" +
    "\n" +
    "    </div>\n" +
    "  </div>\n" +
    "\n" +
    "\n" +
    "</div>\n" +
    "");
}]);

angular.module("comp/reports/booking-source/report", []).run(["$templateCache", function($templateCache) {
  $templateCache.put("comp/reports/booking-source/report",
    "<div class=\"col-md-4\">\n" +
    "\n" +
    "  <table class=\"table\">\n" +
    "    <thead>\n" +
    "      <tr>\n" +
    "        <th class=\"text-left\">Source</th>\n" +
    "        <th class=\"text-center\">Bookings</th>\n" +
    "      </tr>\n" +
    "    </thead>\n" +
    "    <tbody>\n" +
    "      <tr ng-repeat=\"source in sources()\">\n" +
    "        <td class=\"text-left\">\n" +
    "          <strong>{{ source }}</strong>\n" +
    "        </td>\n" +
    "        <td class=\"text-center\">{{ data[source].length }}</td>\n" +
    "      </tr>\n" +
    "    </tbody>\n" +
    "    <tfoot>\n" +
    "      <tr>\n" +
    "      </tr>\n" +
    "    </tfoot>\n" +
    "  </table>\n" +
    "\n" +
    "</div>");
}]);

angular.module("comp/reports/commissions/filter/template", []).run(["$templateCache", function($templateCache) {
  $templateCache.put("comp/reports/commissions/filter/template",
    "<div class=\"filter-panel\">\n" +
    "  <div class=\"col-md-2\">\n" +
    "\n" +
    "    <div class=\"row pos-rlt form-inline\">\n" +
    "      <div class=\"form-group\">\n" +
    "        <label for=\"\">Start Date:</label>\n" +
    "\n" +
    "        <input type=\"text\" ng-click=\"showStart = !showStart\" class=\"input form-control pull-right\"\n" +
    "          date-entry=\"\"\n" +
    "          ng-model=\"calendar.start\"\n" +
    "          ng-model-options=\"{ updateOn: 'default blur', debounce: { 'default': 500, 'blur': 0 } }\"\n" +
    "          ng-value=\"(calendar.start | readTime:'MM/DD/YYYY')\"\n" +
    "          style=\"background-color: #fff; max-width: 100px;\" />\n" +
    "      </div>\n" +
    "\n" +
    "      <div class=\"dropdown-menu\" ng-if=\"showStart\"\n" +
    "        style=\"display: block; left: -50px; top: 25px;\">\n" +
    "        <datepicker show-weeks=\"false\"\n" +
    "          ng-model=\"calendar.start\"\n" +
    "          ng-change=\"$parent.showStart = false;\"\n" +
    "          format-day=\"dd\" format-month=\"MM\" format-year=\"yyyy\"></datepicker>\n" +
    "      </div>\n" +
    "\n" +
    "    </div>\n" +
    "\n" +
    "    <div class=\"row pos-rlt form-inline\">\n" +
    "\n" +
    "      <div class=\"form-group\">\n" +
    "        <label for=\"\">End Date:</label>\n" +
    "        <input type=\"text\" ng-click=\"showEnd = !showEnd\" class=\"input form-control pull-right\"\n" +
    "          date-entry=\"\"\n" +
    "          ng-value=\"(calendar.end | readTime:'MM/DD/YYYY')\"\n" +
    "          style=\"background-color: #fff; max-width: 100px;\" />\n" +
    "      </div>\n" +
    "\n" +
    "      <div class=\"dropdown-menu\" ng-if=\"showEnd\" style=\"display: block; left: -50px; top: 25px;\">\n" +
    "        <datepicker show-weeks=\"false\"\n" +
    "          ng-model=\"calendar.end\"\n" +
    "          ng-change=\"$parent.showEnd = false;\"\n" +
    "          format-day=\"dd\" format-month=\"MM\" format-year=\"yyyy\"></datepicker>\n" +
    "      </div>\n" +
    "    </div>\n" +
    "\n" +
    "    <div><a href=\"\" class=\"btn btn-link extra\" ng-disabled=\"!commissionFilters.ready\" ng-click=\"$parent.prep((calendar.start | readTime: 'MM-DD-YY'), (calendar.end  | readTime: 'MM-DD-YY'))\">Update</a></div>\n" +
    "\n" +
    "  </div>\n" +
    "  <div class=\"col-md-2\">\n" +
    "\n" +
    "    <div class=\"row\">\n" +
    "\n" +
    "      <div class=\"input-group\" style=\"width: 100%; margin-bottom: 5px;\">\n" +
    "        <ui-select\n" +
    "          ng-model=\"commissionFilters.hotel\"\n" +
    "          name=\"hotelFilter\"\n" +
    "          on-select=\"pushSelect($item, $model)\"\n" +
    "          class=\"form-control input\"\n" +
    "          style=\"border: none;\">\n" +
    "\n" +
    "          <ui-select-match placeholder=\"Select a Hotel\">\n" +
    "            <span ng-bind-html=\"$select.selected.name\"></span>\n" +
    "          </ui-select-match>\n" +
    "\n" +
    "          <ui-select-choices\n" +
    "            ui-disable-choice=\"disableOption(hotel._id)\"\n" +
    "            repeat=\"hotel in ($parent.data['hotels'] | toArray | filter: hasConcierges) | orderBy:'name' | filter: $select.search\">\n" +
    "            {{ hotel.name }}\n" +
    "          </ui-select-choices>\n" +
    "\n" +
    "        </ui-select>\n" +
    "      </div>\n" +
    "    </div>\n" +
    "\n" +
    "    <div class=\"row\" ng-repeat=\"id in commissionFilters.selected.hotels\"\n" +
    "      ng-init=\"hotel = $parent.data['hotels'][id]\"\n" +
    "      style=\"margin-bottom: 5px;\">\n" +
    "      <div class=\"input-group\">\n" +
    "        <input type=\"text\" class=\"input form-control\" ng-value=\"hotel.name\" disabled=\"disabled\" style=\"background-color: #fff\">\n" +
    "        <span class=\"input-group-btn\">\n" +
    "          <button\n" +
    "            type=\"button\"\n" +
    "            class=\"btn btn-icon bg-light lter\" style=\"padding: 6px 7px;\"\n" +
    "            ng-click=\"unfilterHotel($index)\">\n" +
    "            <i class=\"fa fa-times\"></i>\n" +
    "          </button>\n" +
    "        </span>\n" +
    "      </div>\n" +
    "\n" +
    "    </div>\n" +
    "\n" +
    "  </div>\n" +
    "\n" +
    "  <div class=\"col-md-2\">\n" +
    "    <div class=\"row\">\n" +
    "\n" +
    "      <div class=\"input-group\" style=\"width: 100%; margin-bottom: 5px;\">\n" +
    "        <ui-select\n" +
    "          ng-model=\"commissionFilters.concierge\"\n" +
    "          name=\"conciergeFilter\"\n" +
    "          on-select=\"pushSelect($item, $model)\"\n" +
    "          class=\"form-control input\"\n" +
    "          style=\"border: none;\">\n" +
    "          <ui-select-match placeholder=\"Select a Concierge\">\n" +
    "            <span ng-bind-html=\"$select.selected.name\"></span>\n" +
    "          </ui-select-match>\n" +
    "          <ui-select-choices\n" +
    "            ui-disable-choice=\"disableOption(concierge._id)\"\n" +
    "            repeat=\"concierge in (getConcierges() | toArray) | orderBy:'name' | filter: $select.search\" ng-hide=\"\">\n" +
    "            {{ concierge.name }}\n" +
    "          </ui-select-choices>\n" +
    "        </ui-select>\n" +
    "      </div>\n" +
    "    </div>\n" +
    "\n" +
    "    <div class=\"row\" ng-repeat=\"id in commissionFilters.selected.concierges\"\n" +
    "      style=\"margin-bottom: 5px;\"\n" +
    "      ng-init=\"concierge = getConcierges()[id]\">\n" +
    "\n" +
    "      <div class=\"input-group\">\n" +
    "        <input type=\"text\" class=\"input form-control\" ng-value=\"concierge.name\" disabled=\"disabled\" style=\"background-color: #fff\">\n" +
    "        <span class=\"input-group-btn\">\n" +
    "          <button\n" +
    "            type=\"button\"\n" +
    "            class=\"btn btn-icon bg-light lter\" style=\"padding: 6px 7px;\"\n" +
    "            ng-click=\"unfilterConcierge($index)\">\n" +
    "            <i class=\"fa fa-times\"></i>\n" +
    "          </button>\n" +
    "        </span>\n" +
    "      </div>\n" +
    "\n" +
    "    </div>\n" +
    "\n" +
    "  </div>\n" +
    "  <div class=\"col-md-2\">\n" +
    "    <div class=\"row\">\n" +
    "\n" +
    "      <div class=\"input-group\" style=\"width: 100%; margin-bottom: 5px;\">\n" +
    "        <ui-select\n" +
    "          ng-model=\"commissionFilters.payment\"\n" +
    "          name=\"paymentFilter\"\n" +
    "          on-select=\"filterPayment($item)\"\n" +
    "          class=\"form-control input\"\n" +
    "          style=\"border: none;\">\n" +
    "          <ui-select-match placeholder=\"Select a Payment Type\">\n" +
    "            <span ng-bind-html=\"$select.selected.name\"></span>\n" +
    "          </ui-select-match>\n" +
    "          <ui-select-choices\n" +
    "            repeat=\"payment in (paymentTypes | toArray) | orderBy:'name' | filter: $select.search\" ng-hide=\"\">\n" +
    "            {{ payment.name }}\n" +
    "          </ui-select-choices>\n" +
    "        </ui-select>\n" +
    "      </div>\n" +
    "\n" +
    "    </div>\n" +
    "\n" +
    "    <div class=\"row\" ng-repeat=\"id in commissionFilters.selected.payments\"\n" +
    "      style=\"margin-bottom: 5px;\"\n" +
    "      ng-init=\"payment = paymentTypes[id]\">\n" +
    "\n" +
    "      <div class=\"input-group\">\n" +
    "\n" +
    "        <input type=\"text\" class=\"input form-control\" ng-value=\"payment.name\" disabled=\"disabled\" style=\"background-color: #fff\">\n" +
    "\n" +
    "        <span class=\"input-group-btn\">\n" +
    "          <button\n" +
    "            type=\"button\"\n" +
    "            class=\"btn btn-icon bg-light lter\" style=\"padding: 6px 7px;\"\n" +
    "            ng-click=\"unfilterPayment($index)\">\n" +
    "            <i class=\"fa fa-times\"></i>\n" +
    "          </button>\n" +
    "        </span>\n" +
    "      </div>\n" +
    "\n" +
    "    </div>\n" +
    "\n" +
    "  </div>\n" +
    "  <div class=\"col-md-2\">\n" +
    "    <div class=\"row\">\n" +
    "      <div class=\"input-group\" style=\"width: 100%; margin-bottom: 5px;\">\n" +
    "        <ui-select\n" +
    "          ng-model=\"commissionFilters.status\"\n" +
    "          name=\"statusFilter\"\n" +
    "          on-select=\"filterStatus($item)\"\n" +
    "          class=\"form-control input\"\n" +
    "          style=\"border: none;\">\n" +
    "          <ui-select-match placeholder=\"Select Status\">\n" +
    "            <span ng-bind-html=\"$select.selected.name\"></span>\n" +
    "          </ui-select-match>\n" +
    "          <ui-select-choices\n" +
    "            repeat=\"status in (statusTypes | toArray) | orderBy:'name' | filter: $select.search\">\n" +
    "            {{ status.name }}\n" +
    "          </ui-select-choices>\n" +
    "        </ui-select>\n" +
    "      </div>\n" +
    "    </div>\n" +
    "\n" +
    "    <div class=\"row\" ng-repeat=\"id in commissionFilters.selected.status\"\n" +
    "      style=\"margin-bottom: 5px;\"\n" +
    "      ng-init=\"status = statusTypes[id]\" ng-if=\"status !== 'paid' && status !== 'unpaid'\">\n" +
    "\n" +
    "      <div class=\"input-group\">\n" +
    "\n" +
    "        <input type=\"text\" class=\"input form-control\" ng-value=\"status.name\" disabled=\"disabled\" style=\"background-color: #fff\">\n" +
    "\n" +
    "        <span class=\"input-group-btn\">\n" +
    "          <button\n" +
    "            type=\"button\"\n" +
    "            class=\"btn btn-icon bg-light lter\" style=\"padding: 6px 7px;\"\n" +
    "            ng-click=\"unfilterStatus($index)\">\n" +
    "            <i class=\"fa fa-times\"></i>\n" +
    "          </button>\n" +
    "        </span>\n" +
    "      </div>\n" +
    "\n" +
    "    </div>\n" +
    "  </div>\n" +
    "\n" +
    "  <div class=\"col-md-2\">\n" +
    "    <div class=\"row\">\n" +
    "      <label class=\"checkbox-inline\">\n" +
    "        <input type=\"checkbox\" ng-model=\"commissionFilters.selected.paid\"> Commission Paid\n" +
    "      </label>\n" +
    "    </div>\n" +
    "    <div class=\"row\">\n" +
    "      <label class=\"checkbox-inline\">\n" +
    "        <input type=\"checkbox\" ng-model=\"commissionFilters.selected.unpaid\"> Commission Unpaid\n" +
    "      </label>\n" +
    "    </div>\n" +
    "  </div>\n" +
    "\n" +
    "</div>\n" +
    "");
}]);

angular.module("comp/reports/commissions/pay_modal", []).run(["$templateCache", function($templateCache) {
  $templateCache.put("comp/reports/commissions/pay_modal",
    "<form name=\"CommissionPayment\" id=\"CommissionPayment\">\n" +
    "\n" +
    "  <div class=\"modal-header\">\n" +
    "    <h3>Commission Payment for {{ recipient.name }}</h3>\n" +
    "  </div>\n" +
    "\n" +
    "  <div class=\"modal-body form-horizontal\" style=\"overflow: auto;\">\n" +
    "    \n" +
    "    <div class=\"checkbox col-md-10 col-sm-offset-2\" style=\"margin-bottom: 10px;\">\n" +
    "      <label for=\"not_commissionable\">\n" +
    "        <input type=\"checkbox\" id=\"not_commissionable\" ng-model=\"payment.not_commissionable\" ng-click=\"unsetOther('not_commissionable')\"> Non-Commissionable\n" +
    "      </label>\n" +
    "    </div>\n" +
    "\n" +
    "    <div class=\"form-group col-md-12\">\n" +
    "      <label for=\"date\" class=\"control-label col-sm-2\">Date:</label>\n" +
    "      <div class=\"col-sm-10\">\n" +
    "        <input type=\"text\" id=\"date\" class=\"input form-control\"\n" +
    "          date-entry=\"\"\n" +
    "          ng-model=\"payment.date\" />\n" +
    "      </div>\n" +
    "    </div>\n" +
    "\n" +
    "    <div class=\"form-group col-md-12\">\n" +
    "      <label for=\"method\" class=\"control-label col-sm-2\">Method:</label>\n" +
    "      <div class=\"col-sm-10\">\n" +
    "        <input type=\"text\" id=\"method\" class=\"input form-control\"\n" +
    "          ng-model=\"payment.method\" />\n" +
    "      </div>\n" +
    "    </div>\n" +
    "\n" +
    "    <div class=\"form-group col-md-12\"\n" +
    "      ng-repeat=\"commission in commissions track by $index\" ng-init=\"commission.commission.amount = (commission.commission.amount | currency:'')\">\n" +
    "      <label for=\"amount\" class=\"control-label col-sm-2\">\n" +
    "        {{ user.activeCompany().nickname }}{{ commission.booking.confirmation }}\n" +
    "      </label>\n" +
    "      <div class=\"col-sm-10\">\n" +
    "        <input type=\"text\" id=\"amount\"\n" +
    "          ng-disabled=\"payment.not_commissionable || payment.on_hold\"\n" +
    "          class=\"input form-control\"\n" +
    "          ng-model=\"commission.commission.amount\" />\n" +
    "      </div>\n" +
    "    </div>\n" +
    "\n" +
    "    <div class=\"form-group col-md-12\">\n" +
    "\n" +
    "      <label for=\"on_hold\" class=\"control-label col-sm-2\">\n" +
    "        <input type=\"checkbox\" id=\"on_hold\" ng-model=\"payment.on_hold\" ng-click=\"unsetOther('on_hold')\"/> Hold\n" +
    "      </label>\n" +
    "\n" +
    "      <div class=\"col-sm-10\" ng-if=\"payment.on_hold\">\n" +
    "        <input type=\"text\" id=\"hold_reason\" class=\"input form-control\"\n" +
    "          placeholder=\"Reason for Hold\"\n" +
    "          ng-model=\"payment.hold_reason\" />\n" +
    "      </div>\n" +
    "\n" +
    "    </div>\n" +
    "\n" +
    "  </div>\n" +
    "\n" +
    "  <div class=\"modal-footer\">\n" +
    "    <button class=\"btn btn-link\" ng-click=\"close()\">Close</button>\n" +
    "    <button class=\"btn btn-primary\" ng-click=\"submit()\">Submit</button>\n" +
    "    <button class=\"btn btn-link text-danger\" ng-if=\"payment.edit\" ng-click=\"remove()\">Remove</button>\n" +
    "  </div>\n" +
    "\n" +
    "</form>\n" +
    "");
}]);

angular.module("comp/reports/commissions/report", []).run(["$templateCache", function($templateCache) {
  $templateCache.put("comp/reports/commissions/report",
    "<div style=\"margin-top: 20px; margin-bottom: 20px;\" class=\"tabbable\" ng-if=\"allowed\" print-section ng-init=\"showAll = false;\">\n" +
    "\n" +
    "  <ul class=\"nav nav-tabs hidden-print\">\n" +
    "    <li ng-click=\"filter.type = 'hotels'; prep();\"\n" +
    "      ng-class=\"{ 'active': filter.type === 'hotels' }\">\n" +
    "      <a href=\"#\">Hotels</a>\n" +
    "    </li>\n" +
    "    <li ng-click=\"filter.type = 'employees'; prep();\"\n" +
    "      ng-class=\"{ 'active': filter.type === 'employees' }\">\n" +
    "      <a href=\"#\">Employees</a>\n" +
    "    </li>\n" +
    "    <li ng-click=\"filter.type = 'partners'; prep();\"\n" +
    "      ng-class=\"{ 'active': filter.type === 'partners' }\">\n" +
    "      <a href=\"#\">Partners</a>\n" +
    "    </li>\n" +
    "  </ul>\n" +
    "\n" +
    "  <div id=\"commissionFilters\" class=\"row\" ng-controller=\"commissionsFilter\">\n" +
    "\n" +
    "    <div class=\"col-md-12 col-lg-10 col-xl-6\">\n" +
    "      <h3>\n" +
    "\n" +
    "        {{ filter.type }}:\n" +
    "\n" +
    "        <span class=\"dropdown\" ng-class=\"{ 'open': filter.parent_open }\">\n" +
    "          <small class=\"dropdown-toggle point upper\" ng-click=\"filter.parent_open = !filter.parent_open\">\n" +
    "            {{ data[filter.type][hotelFilter].name || filter.filter }}\n" +
    "          </small>\n" +
    "          <ul class=\"dropdown-menu animated fadeIn text-normal\">\n" +
    "            <li><input type=\"text\" class=\"plain\" ng-model=\"filtered\" style=\"width: 100%; padding: 5px 17px;\" placeholder=\"Filter ...\"></li>\n" +
    "            <li ng-repeat=\"p in (data[filter.type] | toArray) | filter:filtered | orderBy: 'name'\"\n" +
    "              ng-click=\"filter.parent_open = false\"\n" +
    "              ng-show=\"(p.concierges | toArray).length\">\n" +
    "              <a href=\"\" ng-click=\"commissionFilters.selected.hotels = []; pushSelect(p)\">{{ p.name }}</a>\n" +
    "            </li>\n" +
    "          </ul>\n" +
    "        </span>\n" +
    "\n" +
    "        <span ng-show=\"filter.type === 'hotels'\"> > Concierge:\n" +
    "          <span class=\"dropdown\" ng-class=\"{ 'open': hotelFilter && filter.child_open }\">\n" +
    "            <small class=\"dropdown-toggle point upper\" ng-click=\"filter.child_open = !filter.child_open\">{{ data[filter.type][hotelFilter].concierges[conciergeFilter].name || 'All' }}</small>\n" +
    "            <ul class=\"dropdown-menu animated fadeIn text-normal\">\n" +
    "              <li><input type=\"text\" class=\"plain\" ng-model=\"filteredChild\" style=\"width: 100%; padding: 5px 17px;\" placeholder=\"Filter ...\"></li>\n" +
    "\n" +
    "              <li><a href=\"\" ng-click=\"unfilterConcierge(); filter.child_open = false;\">All</a></li>\n" +
    "\n" +
    "              <li ng-repeat=\"c in (data[filter.type][hotelFilter].concierges | toArray) | filter:filteredChild | orderBy: 'name'\"\n" +
    "                ng-click=\"filter.child_open = false\">\n" +
    "                <a href=\"\" ng-click=\"filterTo(c)\">{{ c.name }}</a>\n" +
    "              </li>\n" +
    "            </ul>\n" +
    "          </span>\n" +
    "        </span>\n" +
    "\n" +
    "        <div class=\"pull-right pos-rlt hidden-print\" style=\"top: 2px;\">\n" +
    "          <a href=\"\" class=\"btn btn-link extra\" ng-class=\"{ 'bg-lighter': commissionFilters.show }\" ng-click=\"commissionFilters.show = !commissionFilters.show\">\n" +
    "            <span ng-show=\"commissionFilters.show\">Hide</span>\n" +
    "            <span ng-show=\"!commissionFilters.show\">Show</span>\n" +
    "             Filters\n" +
    "          </a>\n" +
    "          <span class=\"text-muted\" ng-show=\"!commissionFilters.show\">|</span>\n" +
    "          <a href=\"\" class=\"btn btn-link extra\" ng-click=\"showAll = !showAll\">\n" +
    "            <span ng-if=\"showAll\">Collapse</span>\n" +
    "            <span ng-if=\"!showAll\">Expand</span>\n" +
    "          </a>\n" +
    "        </div>\n" +
    "\n" +
    "      </h3>\n" +
    "\n" +
    "      <div class=\"col-md-12 bg-lighter hidden-print\"\n" +
    "        ng-show=\"commissionFilters.show\"\n" +
    "        ng-include src=\"'comp/reports/commissions/filter/template'\"></div>\n" +
    "\n" +
    "    </div>\n" +
    "  </div>\n" +
    "\n" +
    "  <div ng-if=\"working\" class=\"col-md-12 col-lg-10 col-xl-6 text-center\">\n" +
    "    <span style=\"display: inline-block;\" spin=\"{\n" +
    "      lines: 17,\n" +
    "      length: 0,\n" +
    "      width: 4,\n" +
    "      radius: 20,\n" +
    "      corners: 1,\n" +
    "      rotate: 0,\n" +
    "      direction: 1,\n" +
    "      color: '#333',\n" +
    "      speed: 1.2,\n" +
    "      trail: 36,\n" +
    "      shadow: false,\n" +
    "      hwaccel: false,\n" +
    "      className: 'spinner',\n" +
    "      zIndex: 2e9,\n" +
    "      top: '-5px',\n" +
    "      left: 'auto'\n" +
    "    }\"></span>\n" +
    "  </div>\n" +
    "\n" +
    "  <div ng-if=\"!working\">\n" +
    "    <div class=\"row\"\n" +
    "      ng-if=\"filter.type !== 'hotels'\"\n" +
    "      style=\"margin-top: 20px;\">\n" +
    "\n" +
    "      <div class=\"col-md-12 col-lg-10 col-xl-6\">\n" +
    "\n" +
    "        <table class=\"table table-body-striped\">\n" +
    "          <tbody\n" +
    "            ng-repeat=\"recipient in (data[filter.type] | toArray) | filter:$parent.filterReport\"\n" +
    "            ng-if=\"filter.type !== 'employees' || (user._id === recipient._id && user.meta.commissions) || user.hasGroup('Manager')\"\n" +
    "            ng-init=\"recipient.type = filter.type;\">\n" +
    "\n" +
    "            <tr\n" +
    "              ng-show=\"!recipient.show(showAll)\"\n" +
    "              ng-init=\"bookings = data.grouped[recipient._id]\"\n" +
    "              ng-include=\"'commission_totals'\"></tr>\n" +
    "\n" +
    "            <tr ng-show=\"recipient.show(showAll)\">\n" +
    "              <td colspan=\"8\">\n" +
    "                <a href=\"\" class=\"btn btn-link\"\n" +
    "                  ng-click=\"recipient.show_details = !recipient.show_details\">\n" +
    "                  <i class=\"fa hidden-print\" ng-class=\"{ 'fa-minus': recipient.show(showAll), 'fa-plus': !recipient.show(showAll) }\"></i>\n" +
    "                </a>\n" +
    "                {{ recipient.name}}\n" +
    "                <button type=\"button\"\n" +
    "                  ng-if=\"user.hasGroup('Bookkeeping')\"\n" +
    "                  ng-disabled=\"!selectedPayments[recipient._id] || (selectedPayments[recipient._id] | toArray).length === 0\"\n" +
    "                  class=\"btn btn-success pull-right hidden-print\"\n" +
    "                  ng-click=\"openPayment(recipient)\">Pay</a>\n" +
    "              </td>\n" +
    "            </tr>\n" +
    "\n" +
    "            <tr ng-show=\"recipient.show(showAll)\">\n" +
    "              <td ng-include=\"'bookings'\"\n" +
    "                ng-init=\"data = { bookings: data.grouped[recipient._id], filter: $parent.filterReport }\"\n" +
    "                style=\"padding: 0;\"\n" +
    "                colspan=\"8\"></td>\n" +
    "            </tr>\n" +
    "\n" +
    "          </tbody>\n" +
    "        </table>\n" +
    "\n" +
    "      </div>\n" +
    "\n" +
    "    </div>\n" +
    "\n" +
    "    <div class=\"row\"\n" +
    "      ng-repeat=\"parent in (data.hotels | toArray) | orderBy: 'name' | filter:filterReport\"\n" +
    "      ng-init=\"parent.type = (filter.type)\"\n" +
    "      ng-if=\"filter.type === 'hotels' && (parent.concierges | toArray).length\">\n" +
    "\n" +
    "      <div class=\"col-md-12 col-lg-10 col-xl-6\">\n" +
    "\n" +
    "        <h2>\n" +
    "          {{ parent.name }}\n" +
    "          <span class=\"pull-right\" ng-if=\"filter.type === 'hotels'\">Total Due: {{ totalDue(parent.concierges) | currency }}</span>\n" +
    "        </h2>\n" +
    "\n" +
    "        <div ng-show=\"filter.type === 'hotels'\">\n" +
    "          <table class=\"table table-body-striped\">\n" +
    "            <tbody\n" +
    "              ng-repeat=\"recipient in (parent.concierges | toArray) | filter:$parent.filterReport\"\n" +
    "              ng-init=\"recipient.type = 'concierges'; recipient.parent = parent._id;\">\n" +
    "\n" +
    "              <tr\n" +
    "                ng-show=\"!recipient.show(showAll)\"\n" +
    "                ng-init=\"bookings = data.grouped[recipient._id]\"\n" +
    "                ng-include=\"'commission_totals'\"></tr>\n" +
    "\n" +
    "              <tr ng-show=\"recipient.show(showAll)\">\n" +
    "                <td colspan=\"8\">\n" +
    "                  <a href=\"\" class=\"btn btn-link\"\n" +
    "                    ng-click=\"recipient.show_details = !recipient.show_details\">\n" +
    "                    <i class=\"fa hidden-print\" ng-class=\"{ 'fa-minus': recipient.show(showAll), 'fa-plus': !recipient.show(showAll) }\"></i>\n" +
    "                  </a>\n" +
    "                  {{ recipient.name}}\n" +
    "                  <button type=\"button\"\n" +
    "                    ng-if=\"user.hasGroup('Bookkeeping')\"\n" +
    "                    ng-disabled=\"!selectedPayments[recipient._id] || (selectedPayments[recipient._id] | toArray).length === 0\"\n" +
    "                    class=\"btn btn-success pull-right hidden-print\"\n" +
    "                    ng-click=\"openPayment(recipient)\">Pay</a>\n" +
    "                </td>\n" +
    "              </tr>\n" +
    "\n" +
    "              <tr ng-show=\"recipient.show(showAll)\">\n" +
    "                <td ng-include=\"'bookings'\"\n" +
    "                  ng-init=\"data = { bookings: data.grouped[recipient._id], filter: $parent.filterReport }\"\n" +
    "                  style=\"padding: 0;\"\n" +
    "                  colspan=\"8\"></td>\n" +
    "              </tr>\n" +
    "            </tbody>\n" +
    "          </table>\n" +
    "\n" +
    "        </div>\n" +
    "\n" +
    "      </div>\n" +
    "    </div>\n" +
    "  </div>\n" +
    "\n" +
    "</div>\n" +
    "\n" +
    "<div ng-if=\"!allowed\" class=\"row\">\n" +
    "  <div class=\"col-md-7\"><span class=\"text-muted\">Report not Authorized</span></div>\n" +
    "</div>\n" +
    "\n" +
    "<script type=\"text/ng-template\" id=\"bookings\">\n" +
    "  \n" +
    "  <table class=\"table table-striped\">\n" +
    "    <thead>\n" +
    "      <tr>\n" +
    "        <th style=\"width: 10%\">Confirmation</th>\n" +
    "        <th style=\"width: 10%\">Date</th>\n" +
    "        <th style=\"width: 20%\">Guest Name</th>\n" +
    "        <th style=\"width: 5%\">#</th>\n" +
    "        <th style=\"width: 10%\">Payment</th>\n" +
    "        <th style=\"width: 10%\">Amount Paid</th>\n" +
    "        <th style=\"width: 10%\">Amount</th>\n" +
    "        <th style=\"width: 15%\">Status</th>\n" +
    "        <th style=\"width: 10%\"></th>\n" +
    "      </tr>\n" +
    "    </thead>\n" +
    "    <tbody>\n" +
    "      <tr\n" +
    "        ng-repeat=\"booking in data.bookings | filter: data.filter | orderBy: booking.day\">\n" +
    "        <td>\n" +
    "          <span ng-if=\"!booking.legacy\">\n" +
    "            {{ user.activeCompany().nickname }}{{ booking.confirmation }}\n" +
    "          </span>\n" +
    "          <span ng-if=\"booking.legacy\">{{ booking.legacy }}</span>\n" +
    "        </td>\n" +
    "        <td>{{ booking.day | readTime:'MM/DD/YY':true }}</td>\n" +
    "        <td>\n" +
    "          <a ng-href=\"/booking/{{ user.activeCompany().nickname }}{{booking.confirmation}}\">\n" +
    "            <span ng-if=\"booking.group_name\">{{ booking.group_name }}</span>\n" +
    "            <span ng-if=\"!booking.group_name\">{{ booking.last_name }}, {{ booking.first_name }}</span>\n" +
    "          </a>\n" +
    "        </td>\n" +
    "        <td>{{ booking.adults + (booking.children || 0) }}</td>\n" +
    "        <td ng-include=\"'payment_source'\">See Template Below</td>\n" +
    "        <td>\n" +
    "          <div ng-if=\"balanceDue(booking) <= 0\">\n" +
    "            <span ng-if=\"booking.cart.package.vip\">{{ vipPrice(booking).price | currency }}</span>\n" +
    "            <span ng-if=\"!booking.cart.package.vip\">\n" +
    "              {{ (booking.cart.package.adults.total | parseFloat) + ((booking.cart.package.children.total || 0) | parseFloat) | currency }}\n" +
    "            </span>\n" +
    "          </div>\n" +
    "        </td>\n" +
    "        <td>{{ commission(recipient, booking) | currency }}</td>\n" +
    "        <td ng-click=\"openPayment(recipient, booking);\" ng-include=\"'status'\">See Template Below</td>\n" +
    "        <td>\n" +
    "          {{ payment = getCommissionPayment(booking); '' }}\n" +
    "          {{ commissionAmount = commission(recipient, booking); '' }}\n" +
    "          <input ng-disabled=\"!commissionAmount || balanceDue(booking) > 0 || payment || booking.status !== 'active'\"\n" +
    "            ng-checked=\"payment\"\n" +
    "            ng-click=\"changePaymentCheck(booking)\"\n" +
    "            type=\"checkbox\" ng-model=\"booking.commission_paid\">\n" +
    "        </td>\n" +
    "      </tr>\n" +
    "    </tbody>\n" +
    "    <tfoot>\n" +
    "      <tr ng-include=\"'commission_totals'\"></tr>\n" +
    "    </tfoot>\n" +
    "  </table>\n" +
    "\n" +
    "</script>\n" +
    "\n" +
    "<!-- NOTE: Shows the table totals, when colapsed its used to expand the bookings view -->\n" +
    "<script type=\"text/ng-template\" id=\"commission_totals\">\n" +
    "  <td style=\"width: 20%\">\n" +
    "    <span ng-if=\"(recipient && !recipient.show(showAll))\" ng-click=\"recipient.show_details = !recipient.show_details\">\n" +
    "      <a href=\"\" class=\"btn btn-link\"\n" +
    "        ng-click=\"recipient.show_details = !recipient.show_details\">\n" +
    "        <i class=\"fa hidden-print\" ng-class=\"{ 'fa-minus': recipient.show(showAll), 'fa-plus': !recipient.show(showAll) }\"></i>\n" +
    "      </a>\n" +
    "      <button type=\"button\" class=\"btn btn-link\" ng-click=\"filterTo(recipient)\">{{ recipient.name }}</button>\n" +
    "    </span>\n" +
    "    <strong ng-if=\"recipient.show(showAll)\">Totals</strong>\n" +
    "  </td>\n" +
    "  <td style=\"width: 30%\" colspan=\"2\">\n" +
    "    <strong>{{ bookingRange(bookings).first | readTime: 'MM/DD/YY': true }} - {{ bookingRange(bookings).last | readTime: 'MM/DD/YY': true }}</strong>\n" +
    "  </td>\n" +
    "  <!-- <td>merged with above</td> -->\n" +
    "  <td style=\"width: 5%\"><strong>{{ totalGuests(bookings) }}</strong></td>\n" +
    "  <td style=\"width: 20%\"></td>\n" +
    "  <td><!-- Total Paid Footer: Leaving empty for now --></td>\n" +
    "  <td style=\"width: 10%\"><strong>{{ totalCommission(recipient, bookings) | currency }}</strong></td>\n" +
    "  <td style=\"width: 15%\"><strong class=\"nowrap\">\n" +
    "    Commission Due: {{ totalCommission(recipient, bookings, false) | currency }}\n" +
    "  </strong></td>\n" +
    "  <td style=\"width: 10%\"></td>\n" +
    "</script>\n" +
    "\n" +
    "<script type=\"text/ng-template\" id=\"payment_source\">\n" +
    "  <span ng-init=\"payment = getPaymentSource(booking)\">\n" +
    "    <span ng-if=\"payment.count === 0\">None</span>\n" +
    "    <span ng-if=\"payment.count > 1\">Multiple</span>\n" +
    "    <span ng-if=\"payment.count === 1\">\n" +
    "      <span ng-if=\"payment.cards.length\">{{ payment.cards[0].name | cardName }}: {{ payment.cards[0].masked }}</span>\n" +
    "      <span ng-if=\"payment.ar.length\">Bill To: {{ payment.ar[0].name }}</span>\n" +
    "      <span ng-if=\"payment.cash.length\">Cash</span>\n" +
    "      <span ng-if=\"payment.gifts.length\">Prepayment</span>\n" +
    "    </span>\n" +
    "  </span>\n" +
    "</script>\n" +
    "<script type=\"text/ng-template\" id=\"status\">\n" +
    "  \n" +
    "  {{ payment = getCommissionPayment(booking); '' }}\n" +
    "  {{ commissionable = (!payment || payment.commissionable); '' }}\n" +
    "  \n" +
    "  <span ng-if=\"booking.status === 'cancelled'\" class=\"text-muted\">\n" +
    "    Cancelled\n" +
    "  </span>\n" +
    "\n" +
    "  <span ng-if=\"booking.status !== 'cancelled'\">\n" +
    "\n" +
    "    <span ng-if=\"balanceDue(booking) > 0 && !recipient.meta.hold_commissions\" class=\"text-danger\">\n" +
    "      Balance Due: {{ balanceDue(booking) | currency }}\n" +
    "    </span>\n" +
    "\n" +
    "    <span ng-if=\"!payment && commissionable\">\n" +
    "\n" +
    "      <span ng-if=\"!recipient.meta.hold_commissions && balanceDue(booking) <= 0\" class=\"\">\n" +
    "        Commission Due: {{ commission(recipient, booking) | currency }}\n" +
    "      </span>\n" +
    "\n" +
    "      <span ng-if=\"recipient.meta.hold_commissions\" class=\"text-muted\">\n" +
    "        On Hold: {{ payment.hold_reason || (commission(recipient, booking) | currency) }}\n" +
    "      </span>\n" +
    "\n" +
    "    </span>\n" +
    "\n" +
    "    <span ng-if=\"(payment && !payment.hold_reason) && commissionable\">\n" +
    "      Paid: {{ payment.amount | currency }}\n" +
    "    </span>\n" +
    "\n" +
    "    <span ng-if=\"(payment && payment.hold_reason)\" class=\"text-muted\">\n" +
    "      On Hold: {{ payment.hold_reason }}\n" +
    "    </span>\n" +
    "\n" +
    "    <span ng-if=\"(!commissionable) && balanceDue(booking) <= 0\">\n" +
    "      Not Commissionable\n" +
    "    </span>\n" +
    "\n" +
    "  </span>\n" +
    "\n" +
    "</script>\n" +
    "");
}]);

angular.module("comp/reports/deposits/report", []).run(["$templateCache", function($templateCache) {
  $templateCache.put("comp/reports/deposits/report",
    "<div class=\"row\" ng-if=\"allowed\" style=\"margin-top: 20px;\" print-section id=\"DepositsReport\">\n" +
    "\n" +
    "  <div sales-report=\"data\" start=\"start\" end=\"end\" class=\"col-md-12\" print-landscape>\n" +
    "\n" +
    "    <div class=\"row\">\n" +
    "      <div class=\"col-md-4\">\n" +
    "        <table class=\"table upper\">\n" +
    "          <thead>\n" +
    "            <tr>\n" +
    "              <th colspan=\"2\">Deposits</th>\n" +
    "            </tr>\n" +
    "          </thead>\n" +
    "          <tbody>\n" +
    "            <tr>\n" +
    "              <td>Total Received</td>\n" +
    "              <td>{{ totalReceived(all) | currency }}</td>\n" +
    "            </tr>\n" +
    "            <tr>\n" +
    "              <td>Total Redeemed</td>\n" +
    "              <td>{{ totalRedeemed(all) | currency }}</td>\n" +
    "            </tr>\n" +
    "            <tr class=\"text-danger\">\n" +
    "              <td>Total Refunded</td>\n" +
    "              <td>{{ totalRefunded(all) | currency }}</td>\n" +
    "            </tr>\n" +
    "          </tbody>\n" +
    "        </table>\n" +
    "      </div>\n" +
    "    </div>\n" +
    "\n" +
    "    <div class=\"row\">\n" +
    "      <div class=\"col-md-8\">\n" +
    "        <table class=\"table\">\n" +
    "          <thead>\n" +
    "            <tr>\n" +
    "              <th>#</th>\n" +
    "              <th>Customer</th>\n" +
    "              <th>Date</th>\n" +
    "              <th>Balance</th>\n" +
    "            </tr>\n" +
    "          </thead>\n" +
    "          <tbody ng-init=\"reportOrder = 'sort.day'\" ng-repeat=\"booking in all | orderBy:'day' track by $index\">\n" +
    "            <tr>\n" +
    "              <td>{{ user.activeCompany().nickname + booking.confirmation }}</td>\n" +
    "              <td>{{ booking.group ? booking.group_name : booking.last_name + ', ' + booking.first_name }}</td>\n" +
    "              <td>{{ booking.day | readTime:'MM/DD/YY':true}}</td>\n" +
    "              <td class=\"upper\">\n" +
    "                <span ng-show=\"depositsReceivedNotRedeemed(booking) > 0\">Deposit Received -\n" +
    "                  {{depositsReceivedNotRedeemed(booking) | currency }}</span>\n" +
    "                <span ng-show=\"depositsRedeemed(booking) > 0\">Deposit Redeemed -\n" +
    "                  {{depositsRedeemed(booking) | currency }}</span>\n" +
    "                <span ng-show=\"depositsRefunded(booking) > 0\" class=\"text-danger\">Deposit Refunded -\n" +
    "                  {{depositsRefunded(booking) | currency }}</span>\n" +
    "                <span\n" +
    "                  ng-show=\"booking.status === 'cancelled' && depositsRefunded(booking) < depositsReceivedNotRedeemed(booking)\">Cancelled\n" +
    "                  Flight - {{ depositsReceivedNotRedeemed(booking) | currency }}</span>\n" +
    "              </td>\n" +
    "            </tr>\n" +
    "            <tr ng-hide=\"true\">\n" +
    "              <td colspan=\"4\">\n" +
    "                <div class=\"row\">\n" +
    "                  <div class=\"col-md-4\">\n" +
    "                    <strong>Deposits Received</strong>\n" +
    "                    <pre><code>{{ depositsReceivedNotRedeemed(booking) | json }}</code></pre>\n" +
    "                  </div>\n" +
    "                  <div class=\"col-md-4\">\n" +
    "                    <strong>Deposits Redeemed</strong>\n" +
    "                    <pre><code>{{ depositsRedeemed(booking) | json }}</code></pre>\n" +
    "                  </div>\n" +
    "                  <div class=\"col-md-4\">\n" +
    "                    <strong>Deposits Refunded</strong>\n" +
    "                    <pre><code>{{ depositsRefunded(booking) | json }}</code></pre>\n" +
    "                  </div>\n" +
    "                </div>\n" +
    "              </td>\n" +
    "            </tr>\n" +
    "          </tbody>\n" +
    "        </table>\n" +
    "      </div>\n" +
    "    </div>\n" +
    "\n" +
    "  </div>\n" +
    "\n" +
    "</div>\n" +
    "\n" +
    "<div ng-if=\"!allowed\" class=\"row\">\n" +
    "  <div class=\"col-md-7\"><span class=\"text-muted\">Report not Authorized</span></div>\n" +
    "</div>\n" +
    "");
}]);

angular.module("comp/reports/discrepancies/report", []).run(["$templateCache", function($templateCache) {
  $templateCache.put("comp/reports/discrepancies/report",
    "<div class=\"row\" ng-if=\"allowed\" style=\"margin-top: 20px;\" print-section id=\"DiscrepancyReport\">\n" +
    "\n" +
    "  <div start=\"start\" end=\"end\" class=\"col-md-12\" print-landscape>\n" +
    "\n" +
    "    <div class=\"row\">\n" +
    "      <div class=\"col-md-8\">\n" +
    "        <table class=\"table\">\n" +
    "          <thead>\n" +
    "            <tr>\n" +
    "              <th>#</th>\n" +
    "              <th>Customer</th>\n" +
    "              <th>Date</th>\n" +
    "              <th>Balance</th>\n" +
    "            </tr>\n" +
    "          </thead>\n" +
    "          <tbody>\n" +
    "            <tr ng-repeat=\"booking in data\" ng-init=\"cardOnFile = onFile(booking)\" class=\"point\">\n" +
    "              <td ui-sref=\"editBooking({ confirmation: user.activeCompany().nickname + booking.confirmation })\">{{\n" +
    "                user.activeCompany().nickname + booking.confirmation }}</td>\n" +
    "              <td ui-sref=\"editBooking({ confirmation: user.activeCompany().nickname + booking.confirmation })\">{{\n" +
    "                booking.group ? booking.group_name : booking.last_name + ', ' + booking.first_name }}</td>\n" +
    "              <td ui-sref=\"editBooking({ confirmation: user.activeCompany().nickname + booking.confirmation })\">{{\n" +
    "                booking.day | readTime:'MM/DD/YY':true}}</td>\n" +
    "              <td ui-sref=\"editBooking({ confirmation: user.activeCompany().nickname + booking.confirmation })\"\n" +
    "                class=\"upper\" ng-class=\"{'text-danger': booking.cart.balance < 0 || cardOnFile }\">\n" +
    "                <span ng-if=\"cardOnFile\">Card on File {{ toFloat(cardOnFile.total) | currency }}</span>\n" +
    "                <span ng-if=\"!cardOnFile\">Balance {{ booking.cart.balance | currency }}</span>\n" +
    "              </td>\n" +
    "            </tr>\n" +
    "          </tbody>\n" +
    "        </table>\n" +
    "      </div>\n" +
    "\n" +
    "    </div>\n" +
    "  </div>\n" +
    "\n" +
    "</div>\n" +
    "\n" +
    "<div ng-if=\"!allowed\" class=\"row\">\n" +
    "  <div class=\"col-md-7\"><span class=\"text-muted\">Report not Authorized</span></div>\n" +
    "</div>\n" +
    "");
}]);

angular.module("comp/reports/gifts/redeem_modal", []).run(["$templateCache", function($templateCache) {
  $templateCache.put("comp/reports/gifts/redeem_modal",
    "<form name=\"RedeemModal\" id=\"RedeemGift\">\n" +
    "\n" +
    "  <div class=\"modal-header\">\n" +
    "    <h3>Redeem {{ gift.code }}</h3>\n" +
    "  </div>\n" +
    "\n" +
    "  <div class=\"modal-body form-horizontal\" style=\"overflow: visible; min-height: 100px\">\n" +
    "\n" +
    "    <div class=\"row\">\n" +
    "      <div class=\"col-md-12\">\n" +
    "        <div class=\"col-md-6 input-group\">\n" +
    "\n" +
    "          <label for=\"date\" class=\"control-label col-sm-6\">Redeemed On:</label>\n" +
    "\n" +
    "          <div class=\"input-group col-sm-5\">\n" +
    "            <input type=\"text\" class=\"form-control input\" ng-model=\"gift._redeemed\" \n" +
    "              date-entry=\"{ 'format': 'mdY/' }\"\n" +
    "              ng-model-options=\"{ updateOn: 'default blur', debounce: { 'default': 250, 'blur': 0 } }\"/>\n" +
    "            <span class=\"input-group-btn\">\n" +
    "              <button\n" +
    "                type=\"button\"\n" +
    "                class=\"btn bg-light btn-icon\"\n" +
    "                ng-click=\"showCal = !showCal\">\n" +
    "                <i class=\"fa fa-calendar\">&nbsp;</i>\n" +
    "              </button>\n" +
    "            </span>\n" +
    "          </div>\n" +
    "\n" +
    "          <div ng-show=\"showCal\" class=\"dropdown-menu\" \n" +
    "            style=\"display: block; left: 20px;\">\n" +
    "            <datepicker show-weeks=\"false\"\n" +
    "              ng-model=\"gift.redeemed\"\n" +
    "              ng-change=\"setDate(gift.redeemed)\"\n" +
    "              format-day=\"dd\" format-month=\"MM\" format-year=\"yyyy\"></datepicker>\n" +
    "          </div>\n" +
    "\n" +
    "        </div>\n" +
    "\n" +
    "        <div class=\"col-md-6 input-group\">\n" +
    "          \n" +
    "          <label for=\"date\" class=\"control-label col-sm-5\">Booking:</label>\n" +
    "\n" +
    "          <div class=\"input-group col-sm-6\">\n" +
    "            <span class=\"input-group-addon\" id=\"basic-addon1\">{{ user.activeCompany().nickname }}</span>\n" +
    "            <input type=\"text\" class=\"form-control input\" ng-model=\"gift.booking.confirmation\" />\n" +
    "          </div>\n" +
    "\n" +
    "        </div>\n" +
    "      </div>\n" +
    "\n" +
    "\n" +
    "    </div>\n" +
    "\n" +
    "    <div class=\"row\">\n" +
    "      <hr style=\"margin-bottom: 15px\" />\n" +
    "      <div class=\"col-md-12\">\n" +
    "        <h4 style=\"margin-top: 0; margin-left: 10px;\">Bookings</h4>\n" +
    "\n" +
    "        <div style=\"padding-right: 5px; max-height: 300px;\" class=\"vert-scroll\">\n" +
    "          <div class=\"list-group\" ng-class=\"{ 'text-muted lt': working }\" class=\"fadeInOut\">\n" +
    "            <div ng-repeat=\"booking in bookings | filter: bookingFilter\" \n" +
    "              ng-click=\"linkBooking(booking, gift)\"\n" +
    "              class=\"list-group-item\" \n" +
    "              ng-class=\"{ 'bg-light lt': gift.booking._id === booking._id }\">\n" +
    "              <div class=\"row\">\n" +
    "                <div class=\"col-md-2\">{{user.activeCompany().nickname}}{{booking.confirmation}}</div>\n" +
    "                <div class=\"col-md-3\">{{ booking.day | readTime:'MM/DD/YY':true }}</div>\n" +
    "                <div class=\"col-md-4\">\n" +
    "                  {{ booking.adults }} Adults\n" +
    "                  <span ng-if=\"booking.children\">, {{ booking.children }} Children</span>\n" +
    "                </div>\n" +
    "                <div class=\"col-md-3\">\n" +
    "                  <span class=\"pull-right\">\n" +
    "                    {{ booking.cart.total | currency }}\n" +
    "                  </span>\n" +
    "                </div>\n" +
    "              </div>\n" +
    "            </div>\n" +
    "          </div>\n" +
    "        </div>\n" +
    "      </div>\n" +
    "    </div>\n" +
    "\n" +
    "  </div>\n" +
    "\n" +
    "  <div class=\"modal-footer\">\n" +
    "    <button class=\"btn btn-link\" ng-click=\"cancel()\">Close</button>\n" +
    "    <button class=\"btn btn-primary\" ng-click=\"redeem(gift)\">Submit</button>\n" +
    "  </div>\n" +
    "\n" +
    "</form>\n" +
    "");
}]);

angular.module("comp/reports/gifts/report", []).run(["$templateCache", function($templateCache) {
  $templateCache.put("comp/reports/gifts/report",
    "<div class=\"row\" print-section>\n" +
    "\n" +
    "  <div class=\"col-md-12 col-lg-10 col-xl-6\">\n" +
    "\n" +
    "    <div class=\"col-md-12\">\n" +
    "\n" +
    "      <div class=\"pull-left\">\n" +
    "        <div class=\"btn-group btn-group-lg\">\n" +
    "          <button type=\"button\" ng-class=\"{ 'lt': getFilter() !== 'all' }\" class=\"btn btn-lg bg-light\" ng-click=\"setFilter('all')\">All</button>\n" +
    "          <button type=\"button\" ng-class=\"{ 'lt': getFilter() !== 'unredeemed' }\" class=\"btn btn-lg bg-light\" ng-click=\"setFilter('unredeemed')\">Not Redeemed</button>\n" +
    "          <button type=\"button\" ng-class=\"{ 'lt': getFilter() !== 'redeemed' }\" class=\"btn btn-lg bg-light\" ng-click=\"setFilter('redeemed')\">Redeemed</button>\n" +
    "        </div>\n" +
    "      </div>\n" +
    "\n" +
    "      <div class=\"pull-right marg-btm-20\">\n" +
    "        <h3 class=\"heads-up\">\n" +
    "          <span class=\"amount\">{{ total(data.created) | currency }}</span>\n" +
    "          <span class=\"small clear\">Purchased</span>\n" +
    "        </h3>\n" +
    "        <h3 class=\"heads-up\">\n" +
    "          <span class=\"amount\">{{ total(data.redeemed) | currency }}</span>\n" +
    "          <span class=\"small clear\">Redeemed</span>\n" +
    "        </h3>\n" +
    "      </div>\n" +
    "\n" +
    "    </div>\n" +
    "    <table class=\"table\">\n" +
    "      <thead>\n" +
    "        <tr class=\"active\">\n" +
    "          <th>Created</th>\n" +
    "          <th>Redeemed</th>\n" +
    "          <th>Code</th>\n" +
    "          <th>Guest</th>\n" +
    "          <th>Booking</th>\n" +
    "          <th>Amount</th>\n" +
    "          <th></th>\n" +
    "        </tr>\n" +
    "      </thead>\n" +
    "      <tbody>\n" +
    "        <tr ng-repeat=\"g in data.joined | filter:giftFilter\" \n" +
    "          ng-init=\"gift = prepGift(g)\"\n" +
    "          ng-class=\"{ 'strike text-muted lt': gift.booking && gift.booking.status === 'cancelled' }\">\n" +
    "          <td>\n" +
    "            <span ng-class=\"{ 'text-muted lt': !gift.inRange(gift.created) }\">\n" +
    "              {{ gift.created | readTime:'MM/DD':true }}\n" +
    "            </span>          \n" +
    "          </td>\n" +
    "          <td>\n" +
    "            <a class=\"btn btn-link btn-xs\" ng-if=\"gift.redeemed\" \n" +
    "              ng-href=\"/day/bookings/{{ gift.redeemed.format('MM-DD-YY') }}\">\n" +
    "              {{ gift.redeemed | readTime:'MM/DD':true }}\n" +
    "            </a>\n" +
    "            <span ng-if=\"!gift.redeemed\" class=\"text-muted lt\">--</span>\n" +
    "          </td>\n" +
    "          <td>\n" +
    "            <a class=\"btn btn-link btn-xs\"\n" +
    "              ng-href=\"/gift/{{ gift._id }}\">\n" +
    "              {{ gift.code }}\n" +
    "            </a>\n" +
    "          </td>\n" +
    "          <td>\n" +
    "            <a class=\"btn btn-link btn-xs\" ng-if=\"gift.booking\" \n" +
    "              ng-href=\"/booking/{{ user.activeCompany().nickname }}{{ gift.booking.confirmation }}\">\n" +
    "              {{ gift.guestName() }}\n" +
    "            </a>\n" +
    "            <span ng-if=\"!gift.booking\" class=\"text-muted lt\">--</span>\n" +
    "          </td>\n" +
    "          <td>\n" +
    "            <a class=\"btn btn-link btn-xs\" ng-if=\"gift.booking\" \n" +
    "              ng-href=\"/booking/{{user.activeCompany().nickname}}{{ gift.booking.confirmation }}\">\n" +
    "              {{ user.activeCompany().nickname }}{{ gift.booking.confirmation }}\n" +
    "            </a>\n" +
    "            <span ng-if=\"!gift.booking\" class=\"text-muted lt\">--</span>\n" +
    "          </td>\n" +
    "          <td>{{ gift.amount | currency }}</td>\n" +
    "          <td>\n" +
    "            <div class=\"btn-group btn-group-xs pull-right\">\n" +
    "              <button class=\"btn btn-xs \" type=\"button\" ng-click=\"gift.redeem()\" ng-disabled=\"!gift.booking\" ng-class=\"{ 'bg-success': gift.booking, 'btn-default': !gift.booking }\">\n" +
    "                <i class=\"fa fa-check\"></i>\n" +
    "              </button>\n" +
    "              <button class=\"btn btn-xs\" type=\"button\" title=\"Unlink from Reservation\" ng-disabled=\"!gift.booking\" ng-click=\"gift.unlink()\" ng-class=\"{ 'bg-danger': gift.booking, 'btn-default': !gift.booking }\">\n" +
    "                <i class=\"fa fa-times\"></i>\n" +
    "              </button>\n" +
    "            </div>\n" +
    "          </td>\n" +
    "        </tr>\n" +
    "      </tbody>\n" +
    "      <tfoot>\n" +
    "        <tr class=\"active\">\n" +
    "          <td>\n" +
    "            <span ng-show=\"showFilter === 'all' || showFilter === 'unredeemed'\">\n" +
    "              {{ total(data.created) | currency }}\n" +
    "            </span>\n" +
    "          </td>\n" +
    "          <td>\n" +
    "            <span ng-show=\"showFilter === 'all' || showFilter === 'redeemed'\">\n" +
    "              {{ total(data.redeemed) | currency }}\n" +
    "            </span>\n" +
    "          </td>\n" +
    "          <td></td>\n" +
    "          <td></td>\n" +
    "          <td></td>\n" +
    "          <td>\n" +
    "            <span ng-if=\"showFilter === 'redeemed'\">{{ total(data.redeemed) | currency }}</span>\n" +
    "            <span ng-if=\"showFilter === 'unredeemed'\">{{ total(data.created) | currency }}</span>\n" +
    "            <span ng-if=\"showFilter === 'all'\">{{ total(data.joined) | currency }}</span>\n" +
    "          </td>\n" +
    "          <td></td>\n" +
    "        </tr>\n" +
    "      </tfoot>\n" +
    "    </table>\n" +
    "  </div>\n" +
    "\n" +
    "</div>\n" +
    "");
}]);

angular.module("comp/reports/glance/report", []).run(["$templateCache", function($templateCache) {
  $templateCache.put("comp/reports/glance/report",
    "<div class=\"row\" print-section>\n" +
    "\n" +
    "  <div class=\"col-md-5\">\n" +
    "    <table class=\"table table-condensed table-striped\" style=\"width: 200px\">\n" +
    "      <thead>\n" +
    "        <tr>\n" +
    "          <th style=\"width: 90px;\">Date</th>\n" +
    "          <th>Guests</th>\n" +
    "          <th ng-repeat=\"(name, count) in data.extra_columns\"><span class=\"nowrap\">{{ name }}</span></th>\n" +
    "        </tr>\n" +
    "      </thead>\n" +
    "      <tbody>\n" +
    "        <tr ng-repeat=\"(key, day) in data.days | orderBy: 'date'\">\n" +
    "          <td>{{ day.date | readTime:'MM/DD/YYYY':false }}</td>\n" +
    "          <td class=\"text-center\">{{ day.capacity.booked }}</td>\n" +
    "          <td class=\"text-center\" ng-repeat=\"(name, count) in data.extra_columns\">{{ day.reportable[name] || 0 }}</td>\n" +
    "        </tr>\n" +
    "      </tbody>\n" +
    "      <tfoot>\n" +
    "        <tr style=\"font-weight: bold;\">\n" +
    "          <td>Total</td>\n" +
    "          <td class=\"text-center\">{{ data.total }}</td>\n" +
    "          <td class=\"text-center\" ng-repeat=\"(name, count) in data.extra_columns\">{{ count || 0 }}</td>\n" +
    "        </tr>\n" +
    "      </tfoot>\n" +
    "    </table>\n" +
    "\n" +
    "  </div>\n" +
    "\n" +
    "</div>\n" +
    "");
}]);

angular.module("comp/reports/itsheet/report", []).run(["$templateCache", function($templateCache) {
  $templateCache.put("comp/reports/itsheet/report",
    "<div class=\"col-md-7\" print-section>\n" +
    "\n" +
    "  <div class=\"row\" style=\"margin-top: 20px; margin-bottom: 20px;\">\n" +
    "\n" +
    "    <div class=\"col-md-3 col-xs-3 text-center no-pad-lft\">\n" +
    "      <h4>Total Scheduled:</h4>\n" +
    "      <span style=\"margin-top: 7px; display: inline-block\">{{ data.event.capacity.booked }}\n" +
    "        <span ng-if=\"data.event.capacity.total_weight > 0\"> / {{ data.event.capacity.total_weight }} lbs</span></span>\n" +
    "    </div>\n" +
    "\n" +
    "    <div class=\"text-center\" \n" +
    "      ng-class=\"{ 'col-md-3 col-xs-3': getTotalWeight() === 0, 'col-md-2 col-xs-2': getTotalWeight() > 0 }\">\n" +
    "      <h4>Total Flown</h4>\n" +
    "      <input type=\"text\" class=\"form-control\">\n" +
    "    </div>\n" +
    "\n" +
    "    <div class=\"col-md-2 col-xs-2 text-center\" ng-if=\"getTotalWeight() > 0\">\n" +
    "      <h4>Total Weight</h4>\n" +
    "      <span style=\"margin-top: 7px; display: inline-block\">{{ getTotalWeight() }}</span>\n" +
    "    </div>\n" +
    "\n" +
    "    <div class=\"text-center\" \n" +
    "      ng-class=\"{ 'col-md-3 col-xs-3': getTotalWeight() === 0, 'col-md-2 col-xs-2': getTotalWeight() > 0 }\">      <h4>Picnic:</h4>\n" +
    "      <span style=\"margin-top: 7px; display: inline-block\"><span ng-repeat=\"person in data.event.hospitality\">{{ person.name }}<span ng-if=\"!$last\">, </span></span></span>\n" +
    "    </div>\n" +
    "\n" +
    "    <div class=\"text-center col-md-3 col-xs-3 no-pad-rt\">\n" +
    "      <h4>Launch Site:</h4>\n" +
    "      <input type=\"text\" class=\"form-control\">\n" +
    "    </div>\n" +
    "\n" +
    "  </div>\n" +
    "\n" +
    "  <div class=\"row\">\n" +
    "    <table class=\"table table-condensed\" style=\"border-bottom: 1px solid #ddd\" print-table=\"data\">\n" +
    "      <thead>\n" +
    "        <tr>\n" +
    "          <th>Balloon</th>\n" +
    "          <th>Pilot</th>\n" +
    "          <th>Crew</th>\n" +
    "          <th>Driver</th>\n" +
    "          <th class=\"text-center\" style=\"width: 100px;\">Total</th>\n" +
    "          <th class=\"text-center\" style=\"width: 100px;\">Landing</th>\n" +
    "        </tr>\n" +
    "      </thead>\n" +
    "      <tbody>\n" +
    "        <tr ng-repeat=\"(key, flight) in data.flights\" style=\"\">\n" +
    "          <td>{{ flight.balloon.initials || flight.balloon.name }}</td>\n" +
    "          <td>{{ flight.pilot.name }}</td>\n" +
    "          <td>\n" +
    "            <span ng-repeat=\"(key, crew) in flight.crew.chasers\">{{ crew.nickname || crew.name }}<span ng-if=\"!$last\">, </span></span>\n" +
    "          </td>\n" +
    "          <td>\n" +
    "            <span ng-repeat=\"(key, crew) in flight.crew.drivers\">{{ crew.nickname || crew.name }}<span ng-if=\"!$last\">, </span></span>\n" +
    "          </td>\n" +
    "          <td style=\"border: 1px solid #ddd;\"></td>\n" +
    "          <td style=\"border: 1px solid #ddd;\"></td>\n" +
    "        </tr>\n" +
    "      </tbody>\n" +
    "    </table>\n" +
    "  </div>\n" +
    "\n" +
    "  <div class=\"row\">\n" +
    "    <table class=\"table table-condensed table-striped\" style=\"border-bottom: 1px solid #ddd\" print-table=\"data\">\n" +
    "      <thead>\n" +
    "        <tr>\n" +
    "          <th class=\"text-center\">#</th>\n" +
    "          <th>Balloon</th>\n" +
    "          <th class=\"text-center\">Pax</th>\n" +
    "          <th>Name</th>\n" +
    "          <th ng-if=\"getTotalWeight()\">Weights</th>\n" +
    "          <th>Pickup</th>\n" +
    "          <th>Package</th>\n" +
    "          <th>Phone</th>\n" +
    "          <th>Memo</th>\n" +
    "        </tr>\n" +
    "      </thead>\n" +
    "      <tbody>\n" +
    "        <tr ng-repeat=\"(key, booking) in data.reservations | orderBy: 'last_name'\" ng-if=\"booking.status === 'active'\">\n" +
    "          <td class=\"text-center\">{{ $index + 1 }} - </td>\n" +
    "          <td style=\"border: 1px solid #dddddd\">\n" +
    "            <span ng-repeat=\"(key, flight) in booking.flight\">{{ getFlight(flight).balloon.initials || getFlight(flight).balloon.name }}<span ng-if=\"!$last\">, </span>\n" +
    "            </span>\n" +
    "          </td>\n" +
    "          <td class=\"text-center\">{{ booking.adults + (booking.children || 0) }}</td>\n" +
    "          <td>\n" +
    "            <span ng-if=\"!booking.group\">{{ booking.last_name }}, {{ booking.first_name }}</span>\n" +
    "            <span ng-if=\"booking.group\">{{ booking.group_name }}</span>\n" +
    "          </td>\n" +
    "          <td ng-if=\"getTotalWeight()\">{{ booking.meta.weights.join(', ') }} <em>({{ getBookingWeight(booking) }})</em></td>\n" +
    "          <td>\n" +
    "            <span ng-if=\"booking.needs_pickup\">Yes</span>\n" +
    "          </td>\n" +
    "          <td>\n" +
    "            {{ booking.package.name }}\n" +
    "            <span ng-if=\"booking.vip || booking.group || booking.hotel || booking.meta.no_brunch\">\n" +
    "              <span ng-if=\"booking.vip\"> - VIP</span>\n" +
    "              <span ng-if=\"booking.group\"> - GROUP</span>\n" +
    "              <span ng-if=\"booking.hotel\"> - HOTEL</span>\n" +
    "              <span ng-if=\"booking.meta.no_brunch\"> - NO BRUNCH</span>\n" +
    "            </span>\n" +
    "          </td>\n" +
    "          <td>{{ booking.phone }}</td>\n" +
    "          <td>{{ booking.notes.memo }}</td>\n" +
    "        </tr>\n" +
    "      </tbody>\n" +
    "    </table>\n" +
    "  </div>\n" +
    "\n" +
    "</div>\n" +
    "");
}]);

angular.module("comp/reports/manifest/report", []).run(["$templateCache", function($templateCache) {
  $templateCache.put("comp/reports/manifest/report",
    "<div class=\"col-md-7\" print-section>\n" +
    "  <div style=\"float:none;\">\n" +
    "    <div ng-repeat=\"(key, flight) in data.flights | orderBy: 'balloon.name'\" class=\"row page-break\"\n" +
    "      style=\"page-break-after: always\">\n" +
    "      <div class=\"row\">\n" +
    "        <div class=\"col-md-4 col-sm-4 col-xs-4\">\n" +
    "          <h4 style=\"padding-left: 8px;\">Balloon: {{ flight.balloon.name }}</h4>\n" +
    "          <table class=\"table table-borderless\" print-data=\"data\">\n" +
    "            <tbody>\n" +
    "              <tr>\n" +
    "                <td><strong>Pilot</strong></td>\n" +
    "                <td>{{ flight.pilot.name }}</td>\n" +
    "              </tr>\n" +
    "              <tr>\n" +
    "                <td><strong>Driver</strong></td>\n" +
    "                <td>\n" +
    "                  <span ng-repeat=\"(key, crew) in flight.crew.drivers\">\n" +
    "                    {{ crew.nickname || crew.name }}<span ng-if=\"!$last\">, </span>\n" +
    "                  </span>\n" +
    "                </td>\n" +
    "              </tr>\n" +
    "              <tr>\n" +
    "                <td><strong>Crew</strong></td>\n" +
    "                <td>\n" +
    "                  <span ng-repeat=\"(key, crew) in flight.crew.chasers\">\n" +
    "                    {{ crew.nickname || crew.name }}<span ng-if=\"!$last\">, </span>\n" +
    "                  </span>\n" +
    "                </td>\n" +
    "              </tr>\n" +
    "            </tbody>\n" +
    "          </table>\n" +
    "        </div>\n" +
    "\n" +
    "        <div class=\"col-md-4 col-sm-4 col-xs-4\">\n" +
    "          <table class=\"table table-borderless\">\n" +
    "            <tbody>\n" +
    "              <tr>\n" +
    "                <td></td>\n" +
    "                <td class=\"text-center\"><strong>Scheduled</strong></td>\n" +
    "                <td class=\"text-center\"><strong>Flown</strong></td>\n" +
    "              </tr>\n" +
    "              <tr>\n" +
    "                <td class=\"text-right\"><strong>Weight</strong></td>\n" +
    "                <!-- Scheduled Weight -->\n" +
    "                <td class=\"text-center\">\n" +
    "                  {{ getFlightWeight(flight) }}\n" +
    "                </td>\n" +
    "                <!-- Weight Flown -->\n" +
    "                <td>\n" +
    "                  <div style=\"border-bottom: 1px solid black;\">&nbsp;</div>\n" +
    "                </td>\n" +
    "              </tr>\n" +
    "              <tr>\n" +
    "                <td class=\"text-right\"><strong>Pax</strong></td>\n" +
    "                <!-- Number of guests assigned -->\n" +
    "                <td class=\"text-center\">\n" +
    "                  {{ getFlightCapacity(flight) }}\n" +
    "                </td>\n" +
    "                <!-- number of guests flown -->\n" +
    "                <td>\n" +
    "                  <div style=\"border-bottom: 1px solid black;\">&nbsp;</div>\n" +
    "                </td>\n" +
    "              </tr>\n" +
    "            </tbody>\n" +
    "          </table>\n" +
    "        </div>\n" +
    "\n" +
    "        <div class=\"col-md-4 col-sm-4 col-xs-4\">\n" +
    "          <table class=\"table table-borderless\">\n" +
    "            <tbody>\n" +
    "              <tr>\n" +
    "                <td></td>\n" +
    "                <td><strong>Time</strong></td>\n" +
    "                <td><strong>Location</strong></td>\n" +
    "              </tr>\n" +
    "              <tr>\n" +
    "                <td class=\"text-right\"><strong>Launch</strong></td>\n" +
    "                <td>\n" +
    "                  <div style=\"border-bottom: 1px solid black;\">&nbsp;</div>\n" +
    "                </td>\n" +
    "                <td>\n" +
    "                  <div style=\"border-bottom: 1px solid black;\">&nbsp;</div>\n" +
    "                </td>\n" +
    "              </tr>\n" +
    "              <tr>\n" +
    "                <td class=\"text-right\"><strong>Landing</strong></td>\n" +
    "                <td>\n" +
    "                  <div style=\"border-bottom: 1px solid black;\">&nbsp;</div>\n" +
    "                </td>\n" +
    "                <td>\n" +
    "                  <div style=\"border-bottom: 1px solid black;\">&nbsp;</div>\n" +
    "                </td>\n" +
    "              </tr>\n" +
    "            </tbody>\n" +
    "          </table>\n" +
    "        </div>\n" +
    "      </div>\n" +
    "\n" +
    "      <div class=\"row\">\n" +
    "        <div class=\"col-md-12\">\n" +
    "          <h4>Guest Info:</h4>\n" +
    "          <table class=\"table table-condensed table-striped\" style=\"border-bottom: 1px solid #ddd\" print-table=\"data\">\n" +
    "            <thead>\n" +
    "              <tr>\n" +
    "                <th>Name</th>\n" +
    "                <th class=\"text-center\">Pax</th>\n" +
    "                <th ng-if=\"getTotalWeight()\">Weights</th>\n" +
    "                <th>Time</th>\n" +
    "                <th>Location</th>\n" +
    "                <th>Package</th>\n" +
    "                <th>Phone</th>\n" +
    "              </tr>\n" +
    "            </thead>\n" +
    "            <tbody>\n" +
    "              <tr ng-repeat=\"(key, booking) in flight.reservations | orderBy: 'last_name'\"\n" +
    "                ng-if=\"booking.status === 'active'\">\n" +
    "                <td>\n" +
    "                  <span ng-if=\"!booking.group\">{{ booking.last_name }}, {{ booking.first_name }}</span>\n" +
    "                  <span ng-if=\"booking.group\">{{ booking.group_name }}</span>\n" +
    "                </td>\n" +
    "                <td class=\"text-center\">{{ booking.adults + (booking.children || 0) }}</td>\n" +
    "                <td ng-if=\"getTotalWeight()\">{{ booking.meta.weights.join(', ') }} <em>({{ getBookingWeight(booking)\n" +
    "                    }})</em>\n" +
    "                </td>\n" +
    "                <td>\n" +
    "                  <span ng-if=\"booking.needs_pickup\">{{ booking.pickup.time | readTime:'h:mm A':true }}</span>\n" +
    "                  <span ng-if=\"!booking.needs_pickup\">--</span>\n" +
    "                </td>\n" +
    "                <td>\n" +
    "                  <span ng-if=\"booking.needs_pickup\">\n" +
    "                    {{ booking.accommodations.hotel.name }} <em>({{getZone(booking).name}})</em>\n" +
    "                  </span>\n" +
    "                </td>\n" +
    "                <td>\n" +
    "                  {{ booking.package.name }}\n" +
    "                  <span ng-if=\"booking.vip || booking.group || booking.hotel || booking.meta.no_brunch\">\n" +
    "                    <span ng-if=\"booking.vip\"> - VIP</span>\n" +
    "                    <span ng-if=\"booking.group\"> - GROUP</span>\n" +
    "                    <span ng-if=\"booking.hotel\"> - HOTEL</span>\n" +
    "                    <span ng-if=\"booking.meta.no_brunch\"> - NO BRUNCH</span>\n" +
    "                  </span>\n" +
    "                </td>\n" +
    "                <td>{{ booking.phone | tel }}</td>\n" +
    "              </tr>\n" +
    "            </tbody>\n" +
    "          </table>\n" +
    "        </div>\n" +
    "      </div>\n" +
    "\n" +
    "      <div class=\"row\">\n" +
    "        <div class=\"col-md-12\">\n" +
    "          <h4>Billing:</h4>\n" +
    "          <table class=\"table table-condensed table-striped\" style=\"border-bottom: 1px solid #ddd\" print-table=\"data\">\n" +
    "            <thead>\n" +
    "              <tr>\n" +
    "                <th width=\"25%\">Name</th>\n" +
    "                <th width=\"25%\" class=\"text-center\">Payments</th>\n" +
    "                <th width=\"25%\" class=\"text-center\">Balance</th>\n" +
    "                <th width=\"25%\">Confirmation</th>\n" +
    "              </tr>\n" +
    "            </thead>\n" +
    "            <tbody>\n" +
    "              <tr ng-repeat=\"(key, booking) in flight.reservations | orderBy: 'last_name'\"\n" +
    "                ng-if=\"booking.status === 'active'\" ng-init=\"booking.cart = getCart(booking)\">\n" +
    "                <td>\n" +
    "                  <span ng-if=\"!booking.group\">{{ booking.last_name }}, {{ booking.first_name }}</span>\n" +
    "                  <span ng-if=\"booking.group\">{{ booking.group_name }}</span>\n" +
    "                </td>\n" +
    "                <td class=\"text-center\">{{ totalPayments(booking) | currency }}</td>\n" +
    "                <td class=\"text-center\">{{ booking.cart.balance | currency }}</td>\n" +
    "                <td>{{ user.activeCompany().nickname }}{{ booking.confirmation }}</td>\n" +
    "              </tr>\n" +
    "            </tbody>\n" +
    "          </table>\n" +
    "\n" +
    "        </div>\n" +
    "      </div>\n" +
    "\n" +
    "      <div class=\"row\">\n" +
    "        <div class=\"col-md-12\">\n" +
    "          <h4>Notes:</h4>\n" +
    "          <table class=\"table table-condensed table-striped\" style=\"border-bottom: 1px solid #ddd\" print-table=\"data\">\n" +
    "            <thead>\n" +
    "              <tr>\n" +
    "                <th>Name</th>\n" +
    "              </tr>\n" +
    "            </thead>\n" +
    "            <tbody>\n" +
    "              <tr ng-repeat=\"(key, booking) in flight.reservations | orderBy: 'last_name'\"\n" +
    "                ng-if=\"booking.status === 'active' && booking.notes.memo\">\n" +
    "                <td>\n" +
    "                  <strong ng-if=\"!booking.group\">{{ booking.last_name }}, {{ booking.first_name }}</strong>\n" +
    "                  <strong ng-if=\"booking.group\">{{ booking.group_name }}</strong> - {{ booking.notes.memo }}\n" +
    "                </td>\n" +
    "              </tr>\n" +
    "            </tbody>\n" +
    "          </table>\n" +
    "\n" +
    "        </div>\n" +
    "      </div>\n" +
    "\n" +
    "      <hr class=\"hide-print\">\n" +
    "      &nbsp;\n" +
    "    </div>\n" +
    "  </div>\n" +
    "\n" +
    "\n" +
    "\n" +
    "</div>\n" +
    "");
}]);

angular.module("comp/reports/merchandise/report", []).run(["$templateCache", function($templateCache) {
  $templateCache.put("comp/reports/merchandise/report",
    "<div class=\"row\" ng-if=\"allowed\" print-section>\n" +
    "  <div sales-report=\"data\" start=\"start\" end=\"end\" class=\"col-md-7\">\n" +
    "\n" +
    "    <button type=\"button\" class=\"pull-right btn bg-danger hidden-print\" ng-click=\"clearSales()\">\n" +
    "      Clear Cache\n" +
    "    </button>\n" +
    "\n" +
    "    <table class=\"table table-striped\" print-table=\"compiled\">\n" +
    "      <thead>\n" +
    "        <tr>\n" +
    "          <th>Item</th>\n" +
    "          <th ng-show=\"objectKeys(compiled.mdse.days).length <= 7\"\n" +
    "            ng-repeat=\"(day, refs) in compiled.mdse.days\">{{ day }}</th>\n" +
    "          <th>TTL QTY</th>\n" +
    "          <th>TTL VALUE</th>\n" +
    "        </tr>\n" +
    "      </thead>\n" +
    "      <tbody>\n" +
    "        <tr ng-repeat=\"itemName in objectKeys(compiled.mdse.items)\">\n" +
    "          <td>{{ itemName }}</td>\n" +
    "          <td ng-show=\"objectKeys(compiled.mdse.days).length <= 7\"\n" +
    "            ng-repeat=\"(day, refs) in compiled.mdse.days\">\n" +
    "              {{ compiled.mdse.items[itemName].days[day].count || 0 }}\n" +
    "            </td>\n" +
    "          <td>{{ compiled.mdse.items[itemName].count }}</td>\n" +
    "          <td>{{ compiled.mdse.items[itemName].total | currency }}</td>\n" +
    "        </tr>\n" +
    "      </tbody>\n" +
    "      <tfoot>\n" +
    "        <tr class=\"active\">\n" +
    "          <td><strong>Total</strong></td>\n" +
    "          <td ng-show=\"objectKeys(compiled.mdse.days).length <= 7\" ng-repeat=\"(day, refs) in compiled.mdse.days\"></td>\n" +
    "          <td></td>\n" +
    "          <td><strong>{{ compiled.mdse.total | currency }}</strong></td>\n" +
    "        </tr>\n" +
    "      </tfoot>\n" +
    "    </table>\n" +
    "\n" +
    "  </div>\n" +
    "</div>\n" +
    "\n" +
    "<div ng-if=\"!allowed\" class=\"row\">\n" +
    "  <div class=\"col-md-7\"><span class=\"text-muted\">Report not Authorized</span></div>\n" +
    "</div>\n" +
    "");
}]);

angular.module("comp/reports/package/report", []).run(["$templateCache", function($templateCache) {
  $templateCache.put("comp/reports/package/report",
    "<div class=\"col-md-4\">\n" +
    "\n" +
    "  <table class=\"table table-striped\">\n" +
    "    <thead>\n" +
    "      <tr>\n" +
    "        <th class=\"text-left\">Package</th>\n" +
    "        <th class=\"text-center\">Bookings</th>\n" +
    "      </tr>\n" +
    "    </thead>\n" +
    "    <tbody>\n" +
    "      <tr ng-repeat=\"bookingPackage in packages()\">\n" +
    "        <td class=\"text-left\">\n" +
    "         {{ bookingPackage }}\n" +
    "        </td>\n" +
    "        <td class=\"text-center\">{{ data[bookingPackage].length }}</td>\n" +
    "      </tr>\n" +
    "    </tbody>\n" +
    "    <tfoot>\n" +
    "      <tr>\n" +
    "        <td>\n" +
    "          <strong>Total</strong>\n" +
    "        </td>\n" +
    "        <td class=\"text-center\">{{ total() }}</td>\n" +
    "      </tr>\n" +
    "    </tfoot>\n" +
    "  </table>\n" +
    "\n" +
    "</div>");
}]);

angular.module("comp/reports/photos/report", []).run(["$templateCache", function($templateCache) {
  $templateCache.put("comp/reports/photos/report",
    "<div class=\"row\" style=\"margin-top: 20px;\" print-section>\n" +
    "  <div class=\"col-md-6\">\n" +
    "    <table class=\"table table-bordered table-striped\" ng-init=\"booking.running = {};\">\n" +
    "      <thead>\n" +
    "        <tr>\n" +
    "          <th>#</th>\n" +
    "          <th>Name</th>\n" +
    "          <th>Email</th>\n" +
    "        </tr>\n" +
    "      </thead>\n" +
    "      <tbody>\n" +
    "        <tr ng-repeat=\"(key, booking) in data.reservations | orderBy: 'last_name'\" ng-show=\"booking.status == 'active'\">\n" +
    "          <td>{{ $index + 1 }}</td>\n" +
    "          <td><a\n" +
    "              ui-sref=\"editBooking({ confirmation: booking.confirmation })\">{{ booking.group_name || booking.first_name + ' ' + booking.last_name }}</a>\n" +
    "          </td>\n" +
    "          <td>{{ booking.photo_email }}</td>\n" +
    "        </tr>\n" +
    "      </tbody>\n" +
    "    </table>\n" +
    "  </div>\n" +
    "</div>\n" +
    "");
}]);

angular.module("comp/reports/pickups/report", []).run(["$templateCache", function($templateCache) {
  $templateCache.put("comp/reports/pickups/report",
    "<div class=\"row\">\n" +
    "  <div class=\"col-md-7\" print-section>\n" +
    "\n" +
    "    <div style=\"margin-top: 20px; margin-bottom: 20px;\"\n" +
    "      ng-repeat=\"(key, driver) in data.meta.drivers\" ng-if=\"driver.reservations.length > 0\">\n" +
    "\n" +
    "      <h5 class=\"upper\">Driver: {{ driver.name }} ({{ driver.reservations.length }}) - {{ driver.flight.balloon.name }}</h5>\n" +
    "\n" +
    "      <table class=\"table table-condensed\" style=\"border-bottom: 1px solid #ddd\" print-table=\"driver\">\n" +
    "        <thead>\n" +
    "          <tr>\n" +
    "            <th width=\"25%\">Name</th>\n" +
    "            <th width=\"5%\" class=\"text-center\">PAX</th>\n" +
    "            <th width=\"8%\">Time</th>\n" +
    "            <th width=\"28%\">Location</th>\n" +
    "            <th width=\"16.5%\">Phone</th>\n" +
    "            <th width=\"16.5%\">Returned By</th>\n" +
    "          </tr>\n" +
    "        </thead>\n" +
    "        <tbody ng-repeat=\"(key, booking) in driver.reservations | orderBy: 'pickup.time'\" ng-if=\"booking.status == 'active'\">\n" +
    "          <tr>\n" +
    "            <td>\n" +
    "              {{ ::addToAssigned(booking._id) }}\n" +
    "              <span ng-if=\"!booking.group\">{{ booking.last_name + ', ' + booking.first_name }}</span>\n" +
    "              <span ng-if=\"booking.group\">{{ booking.group_name }}</span>\n" +
    "            </td>\n" +
    "            <td class=\"text-center\">{{ booking.adults + (booking.children || 0)}}</td>\n" +
    "            <td>{{ booking.pickup.time | readTime: 'hh:mm a':true }}</td>\n" +
    "            <td>{{ booking.accommodations.hotel.name }}</td>\n" +
    "            <td><a ng-href=\"tel:{{booking.phone}}\">{{ booking.phone | phone }}</a></td>\n" +
    "            <td></td>\n" +
    "          </tr>\n" +
    "          <tr ng-show=\"booking.pickup.notes\">\n" +
    "            <td colspan=\"6\" class=\"active\">\n" +
    "              <div style=\"padding: 0px 15px;\">\n" +
    "                <strong>Notes for Driver:</strong> {{ booking.pickup.notes }}\n" +
    "              </div>\n" +
    "            </td>\n" +
    "          </tr>\n" +
    "        </tbody>\n" +
    "      </table>\n" +
    "\n" +
    "    </div>\n" +
    "\n" +
    "    <div style=\"margin-top: 20px; margin-bottom: 20px;\">\n" +
    "\n" +
    "      <h5 class=\"upper\">Unassigned</h5>\n" +
    "\n" +
    "      <table class=\"table table-condensed\" style=\"border-bottom: 1px solid #ddd\" print-table=\"data\">\n" +
    "        <thead>\n" +
    "          <tr>\n" +
    "            <th width=\"16.5%\">Name</th>\n" +
    "            <th width=\"16.5%\">PAX</th>\n" +
    "            <th width=\"16.5%\">Time</th>\n" +
    "            <th width=\"16.5%\">Location</th>\n" +
    "            <th width=\"16.5%\">Phone</th>\n" +
    "            <th width=\"16.5%\">Returned By</th>\n" +
    "          </tr>\n" +
    "        </thead>\n" +
    "        <tbody>\n" +
    "          <tr ng-repeat=\"(key, booking) in data.bookings | orderBy: 'pickup.time'\" ng-if=\"booking.status == 'active' && !isAssigned(booking._id)\">\n" +
    "            <td>\n" +
    "              <span ng-if=\"!booking.group\">{{ booking.last_name + ', ' + booking.first_name }}</span>\n" +
    "              <span ng-if=\"booking.group\">{{ booking.group_name }}</span>\n" +
    "            </td>\n" +
    "            <td>{{ booking.adults + (booking.children || 0)}}</td>\n" +
    "            <td>{{ booking.pickup.time | readTime: 'hh:mm a':true }}</td>\n" +
    "            <td>{{ booking.accommodations.hotel.name }}</td>\n" +
    "            <td>{{ booking.phone }}</td>\n" +
    "            <td></td>\n" +
    "          </tr>\n" +
    "        </tbody>\n" +
    "      </table>\n" +
    "\n" +
    "    </div>\n" +
    "\n" +
    "  </div>\n" +
    "\n" +
    "</div>\n" +
    "");
}]);

angular.module("comp/reports/pilot-commissions/report", []).run(["$templateCache", function($templateCache) {
  $templateCache.put("comp/reports/pilot-commissions/report",
    "<div class=\"row\" ng-if=\"allowed\" print-section>\n" +
    "  <div class=\"col-md-9\" id=\"tipsReport\" sales-report=\"data\" start=\"start\" end=\"end\">\n" +
    "\n" +
    "    <div ng-repeat=\"(name, reservations) in pilots\">\n" +
    "\n" +
    "      <h4>{{ name }}</h4>\n" +
    "\n" +
    "      <table class=\"table table-striped\" ng-init=\"fullPilot = getFullPilotByName(name)\">\n" +
    "\n" +
    "        <thead>\n" +
    "          <tr>\n" +
    "            <th>Payment Source</th>\n" +
    "            <th class=\"text-right\">Source Total</th>\n" +
    "          </tr>\n" +
    "        </thead>\n" +
    "        <tbody>\n" +
    "          <tr ng-repeat=\"(type, amount) in paymentTypes\" ng-if=\"type !== 'Tips'\">\n" +
    "            <td>{{ type }}</td>\n" +
    "            <td class=\"text-right\" ng-init=\"types = getTypes(reservations)\">\n" +
    "              {{ (types[type] || 0) | currency }}\n" +
    "            </td>\n" +
    "          </tr>\n" +
    "        </tbody>\n" +
    "        <tfoot>\n" +
    "          <tr>\n" +
    "            <td class=\"text-strong\">Total Sales</td>\n" +
    "            <td class=\"text-right\" ng-init=\"types = getTypes(reservations)\">\n" +
    "              <strong>{{ typeTotals(types, false) | currency }}</strong>\n" +
    "            </td>\n" +
    "          </tr>\n" +
    "          <tr>\n" +
    "            <td class=\"text-strong\">Calculated Commission</td>\n" +
    "            <td class=\"text-right text-strong\">\n" +
    "              <span ng-if=\"fullPilot.meta.pilot_commission_amount\">\n" +
    "                {{ fullPilot.meta.pilot_commission_amount * 100 + '%' }} -\n" +
    "                {{ (typeTotals(types, false)) * fullPilot.meta.pilot_commission_amount | currency }}\n" +
    "              </span>\n" +
    "              <span ng-if=\"!fullPilot.meta.pilot_commission_amount\">\n" +
    "                n/a\n" +
    "              </span>\n" +
    "            </td>\n" +
    "          </tr>\n" +
    "        </tfoot>\n" +
    "\n" +
    "      </table>\n" +
    "\n" +
    "    </div>\n" +
    "\n" +
    "    <hr>\n" +
    "\n" +
    "    <h4>All Pilots Consolidated</h4>\n" +
    "\n" +
    "    <table class=\"table table-striped\">\n" +
    "\n" +
    "      <thead>\n" +
    "        <tr>\n" +
    "          <th>Payment Source</th>\n" +
    "          <th ng-repeat=\"(name, reservations) in pilots\">\n" +
    "            {{ name }}\n" +
    "          </th>\n" +
    "          <th class=\"text-right\">Source Total</th>\n" +
    "        </tr>\n" +
    "      </thead>\n" +
    "      <tbody>\n" +
    "        <tr ng-repeat=\"(type, amount) in paymentTypes\" ng-hide=\"type === 'Tips'\">\n" +
    "          <td>{{ type }}</td>\n" +
    "          <td ng-repeat=\"(name, reservations) in pilots\" ng-init=\"types = getTypes(reservations)\">\n" +
    "            {{ (types[type] || 0) | currency }}\n" +
    "          </td>\n" +
    "          <td class=\"text-right\">\n" +
    "            {{ amount | currency }}\n" +
    "          </td>\n" +
    "        </tr>\n" +
    "      </tbody>\n" +
    "      <tfoot>\n" +
    "        <tr>\n" +
    "          <td></td>\n" +
    "          <td ng-repeat=\"(name, reservations) in pilots\" ng-init=\"types = getTypes(reservations)\">\n" +
    "            <strong>{{ typeTotals(types, false) | currency }}</strong>\n" +
    "          </td>\n" +
    "          <td class=\"text-right\"><strong>{{ typeTotals(paymentTypes, false) | currency }}</strong></td>\n" +
    "        </tr>\n" +
    "      </tfoot>\n" +
    "\n" +
    "    </table>\n" +
    "\n" +
    "  </div>\n" +
    "\n" +
    "</div>\n" +
    "\n" +
    "<div ng-if=\"!allowed\" class=\"row\">\n" +
    "  <div class=\"col-md-7\"><span class=\"text-muted\">Report not Authorized</span></div>\n" +
    "</div>\n" +
    "");
}]);

angular.module("comp/reports/ratings/report", []).run(["$templateCache", function($templateCache) {
  $templateCache.put("comp/reports/ratings/report",
    "<div class=\"col-md-4\">\n" +
    "\n" +
    "  <table class=\"table\">\n" +
    "    <thead>\n" +
    "      <tr>\n" +
    "        <th class=\"text-center\" style=\"width: 100px;\">Rating</th>\n" +
    "        <th class=\"text-center\">Quantity</th>\n" +
    "        <th class=\"text-center\">Emails</th>\n" +
    "      </tr>\n" +
    "    </thead>\n" +
    "    <tbody>\n" +
    "      <tr ng-repeat=\"rating in ratings | toArray:true | orderBy:sorter\" ng-init=\"$key = rating.$key\">\n" +
    "        <td class=\"text-center\">\n" +
    "          <rating ng-model=\"$key\" \n" +
    "            readonly=\"true\" max=\"5\" \n" +
    "            state-on=\"'fa-dot-circle-o'\" \n" +
    "            state-off=\"'fa-circle-o  text-muted lt'\"></rating>\n" +
    "        </td>\n" +
    "        <td class=\"text-center\">{{ rating.length }}</td>\n" +
    "        <td class=\"text-center\">\n" +
    "          <button class=\"btn bg-info dker btn-xs\" ng-disabled=\"!rating.length\" type=\"button\" \n" +
    "            copy=\"{{getEmails(rating) | uniqueArray | joinArray:', '}}\">Copy Emails \n" +
    "            <span ng-show=\"success\" class=\"ngFadeInOut\"><i class=\"fa fa-check\"></i></span>\n" +
    "          </button>\n" +
    "        </td>\n" +
    "      </tr>\n" +
    "    </tbody>\n" +
    "    <tfoot>\n" +
    "      <tr>\n" +
    "        <td class=\"text-center\">{{ averageRatings() }}</td>\n" +
    "        <td class=\"text-center\">{{ totalVotes() }}</td>\n" +
    "        <td class=\"text-center\">\n" +
    "          <button class=\"btn bg-info dker btn-xs\" type=\"button\" \n" +
    "            copy=\"{{ getAllEmails() | uniqueArray | joinArray:', '}}\" ng-disabled=\"!getAllEmails().length\">Copy All \n" +
    "            <span ng-show=\"success\" class=\"animated fadeIn\"><i class=\"fa fa-check\"></i></span>\n" +
    "          </button>\n" +
    "\n" +
    "        </td>\n" +
    "      </tr>\n" +
    "    </tfoot>\n" +
    "  </table>\n" +
    "\n" +
    "</div>");
}]);

angular.module("comp/reports/reports", []).run(["$templateCache", function($templateCache) {
  $templateCache.put("comp/reports/reports",
    "<div id=\"reportView\" class=\"noPrintMargin\">\n" +
    "\n" +
    "  <header class=\"row\" print-section>\n" +
    "\n" +
    "    <h2 class=\"noselect pull-left col-md-12 report-head\" style=\"\">\n" +
    "      <span>Reports:</span>\n" +
    "      <span class=\"dropdown\" ng-click=\"open = !open\" ng-class=\"{ 'open': open }\">\n" +
    "        <small class=\"dropdown-toggle point\">{{ activeReport.label || 'All Reports' }}</small>\n" +
    "        <ul class=\"dropdown-menu animated fadeIn text-normal\">\n" +
    "          <li ng-class=\"{ 'disabled': !activeReport }\">\n" +
    "            <a href=\"/reports\">All Reports</a>\n" +
    "          </li>\n" +
    "          <li class=\"dropdown-header\" ng-if=\"user.hasRolesFor('Daily Reports')\">Daily Reports</li>\n" +
    "          <li ng-repeat=\"path in reports[0].paths\" ng-class=\"{ 'disabled': path.route === active }\"\n" +
    "            ng-if=\"user.can(path.rule, reports[0].group)\">\n" +
    "            <a ng-href=\"reports/{{ path.route }}\">{{ path.label }}</a>\n" +
    "          </li>\n" +
    "          <li class=\"dropdown-header\" ng-if=\"user.hasRolesFor('Bookkeeping Reports')\">Financial &amp; Bookkeeping</li>\n" +
    "          <li ng-repeat=\"path in reports[1].paths\" ng-class=\"{ 'disabled': path.route === active }\"\n" +
    "            ng-if=\"user.can(path.rule, reports[1].group)\">\n" +
    "            <a\n" +
    "              ng-href=\"reports/{{ path.route }}/{{ calendar.date.startDate | readTime: 'MM-DD-YY'}}/{{ calendar.date.endDate | readTime: 'MM-DD-YY'}}\">{{\n" +
    "              path.label }}</a>\n" +
    "          </li>\n" +
    "          <li class=\"dropdown-header\" ng-if=\"user.hasRolesFor('Marketing/Sales Reports')\">Sales &amp; Marketing</li>\n" +
    "          <li ng-repeat=\"path in reports[2].paths\" ng-class=\"{ 'disabled': path.route === active }\"\n" +
    "            ng-if=\"user.can(path.rule, reports[2].group)\">\n" +
    "            <a\n" +
    "              ng-href=\"reports/{{ path.route }}/{{ calendar.date.startDate | readTime: 'MM-DD-YY'}}/{{ calendar.date.endDate | readTime: 'MM-DD-YY'}}\">{{\n" +
    "              path.label }}</a>\n" +
    "          </li>\n" +
    "          <li class=\"dropdown-header\" ng-if=\"user.hasRolesFor('Quality Assurance')\">Quality Assurance</li>\n" +
    "          <li ng-repeat=\"path in reports[3].paths\" ng-class=\"{ 'disabled': path.route === active }\"\n" +
    "            ng-if=\"user.can(path.rule, reports[3].group)\">\n" +
    "            <a\n" +
    "              ng-href=\"reports/{{ path.route }}/{{ calendar.date.startDate | readTime: 'MM-DD-YY'}}/{{ calendar.date.endDate | readTime: 'MM-DD-YY'}}\">{{\n" +
    "              path.label }}</a>\n" +
    "          </li>\n" +
    "        </ul>\n" +
    "      </span> -\n" +
    "\n" +
    "      <span class=\"dateSelect\" id=\"DatePicker\">\n" +
    "\n" +
    "        <input date-range-picker class=\"form-control date-picker hidden-print\" type=\"text\" ng-model=\"calendar.date\"\n" +
    "          options=\"calendar.options\" />\n" +
    "\n" +
    "        <span print-only class=\"text-normal\">\n" +
    "          {{ calendar.date.startDate | readTime:'MM/DD/YYYY' }}\n" +
    "          <span ng-if=\"!singleDay\"> thru {{ calendar.date.endDate | readTime:'MM/DD/YYYY' }}</span>\n" +
    "        </span>\n" +
    "\n" +
    "      </span>\n" +
    "\n" +
    "\n" +
    "      <button type=\"button\" ng-if=\"printable\" class=\"btn btn-default hidden-print pull-left\" style=\"margin-right: 20px;\"\n" +
    "        print=\"#reportView\" print-title=\"{{ print_title }}\">Print</button>\n" +
    "\n" +
    "    </h2>\n" +
    "\n" +
    "    <span class=\"text-muted pull-right\" print-only>{{ user.activeCompany().nickname }}</span>\n" +
    "\n" +
    "  </header>\n" +
    "\n" +
    "\n" +
    "  <div class=\"row\">\n" +
    "    <div class=\"col-md-12 text-center\" ng-repeat=\"group in reports\"\n" +
    "      ng-if=\"active === 'home' && user.hasRolesFor(group.group)\" ng-init=\"name = group.alias\">\n" +
    "      <h2 style=\"margin-bottom: 20px;\">\n" +
    "        <span ng-if=\"name === 'bookkeeping'\">Financial &amp; Bookkeeping </span>\n" +
    "        <span ng-if=\"name === 'daily'\">Daily Reports &amp; Manifests</span>\n" +
    "        <span ng-if=\"name === 'sales'\">Sales &amp; Marketing</span>\n" +
    "        <span ng-if=\"name === 'qa'\">Quality Assurance</span>\n" +
    "      </h2>\n" +
    "\n" +
    "      <ul class=\"list-inline\" style=\"margin-bottom: 100px;\">\n" +
    "        <li ng-repeat=\"path in group.paths\">\n" +
    "\n" +
    "          <!-- Use url days -->\n" +
    "          <a ng-if=\"name !== 'daily' && user.can(path.rule, group.group)\"\n" +
    "            ng-href=\"reports/{{path.route}}/{{ calendar.date.startDate | readTime: 'MM-DD-YY'}}/{{ calendar.date.endDate | readTime: 'MM-DD-YY'}}\"\n" +
    "            class=\"btn btn-link text-accent\">{{ path.label }}</a>\n" +
    "\n" +
    "          <!-- Use default days (today, or tomorrow) -->\n" +
    "          <a ng-if=\"name === 'daily' && user.can(path.rule, group.group)\" ng-href=\"reports/{{path.route}}\"\n" +
    "            class=\"btn btn-link text-accent\">{{ path.label }}</a>\n" +
    "\n" +
    "        </li>\n" +
    "      </ul>\n" +
    "\n" +
    "    </div>\n" +
    "\n" +
    "    <!-- # LOADING # -->\n" +
    "    <div class=\"text-center\" ng-if=\"!ready && active !== 'home'\" style=\"margin-top: 100px;\">\n" +
    "      <span style=\"display: inline-block;\" spin=\"{\n" +
    "        lines: 17,\n" +
    "        length: 0,\n" +
    "        width: 4,\n" +
    "        radius: 20,\n" +
    "        corners: 1,\n" +
    "        rotate: 0,\n" +
    "        direction: 1,\n" +
    "        color: '#333',\n" +
    "        speed: 1.2,\n" +
    "        trail: 36,\n" +
    "        shadow: false,\n" +
    "        hwaccel: false,\n" +
    "        className: 'spinner',\n" +
    "        zIndex: 2e9,\n" +
    "        top: '-5px',\n" +
    "        left: 'auto'\n" +
    "      }\"></span>\n" +
    "    </div>\n" +
    "    <!-- # LOADING # -->\n" +
    "\n" +
    "    <div ng-show=\"ready\" class=\"animated fadeIn\" id=\"reportView\">\n" +
    "      <div class=\"col-md-12\" ng-controller=\"arReport\" ng-include src=\"'comp/reports/ar/report'\"\n" +
    "        ng-if=\"user.can('a/r', 'Bookkeeping Reports') && active === 'ar'\" ng-init=\"prep()\"></div>\n" +
    "\n" +
    "      <div class=\"col-md-12\" ng-controller=\"batchReport\" ng-include src=\"'comp/reports/batch/report'\"\n" +
    "        ng-if=\"user.can('batch', 'Bookkeeping Reports') && active === 'batch'\" ng-init=\"prep()\"></div>\n" +
    "\n" +
    "      <div class=\"col-md-12\" ng-controller=\"merchandiseReport\" ng-include src=\"'comp/reports/merchandise/report'\"\n" +
    "        ng-if=\"user.can('mdse', 'Bookkeeping Reports') && active === 'merchandise'\" ng-init=\"prep()\"></div>\n" +
    "\n" +
    "      <div class=\"col-md-12\" ng-controller=\"salesReport\" ng-include src=\"'comp/reports/sales/report'\"\n" +
    "        ng-if=\"user.can('sales', 'Bookkeeping Reports') && active === 'sales'\" ng-init=\"prep()\"></div>\n" +
    "\n" +
    "      <div class=\"col-md-12\" ng-controller=\"depositReport\" ng-include src=\"'comp/reports/deposits/report'\"\n" +
    "        ng-if=\"user.can('sales', 'Bookkeeping Reports') && active === 'deposits'\" ng-init=\"prep()\"></div>\n" +
    "\n" +
    "      <div class=\"col-md-12\" ng-controller=\"discrepancyReport\" ng-include src=\"'comp/reports/discrepancies/report'\"\n" +
    "        ng-if=\"user.can('sales', 'Bookkeeping Reports') && active === 'discrepancies'\" ng-init=\"prep()\"></div>\n" +
    "\n" +
    "      <div class=\"col-md-12\" ng-controller=\"tipsReport\" ng-include src=\"'comp/reports/tips/report'\"\n" +
    "        ng-if=\"user.can('tips', 'Bookkeeping Reports') && active === 'tips'\" ng-init=\"prep()\"></div>\n" +
    "\n" +
    "      <div class=\"col-md-12\" ng-controller=\"pilotCommissionsReport\" ng-include\n" +
    "        src=\"'comp/reports/pilot-commissions/report'\"\n" +
    "        ng-if=\"user.can('commissions', 'Bookkeeping Reports') && active === 'pilot-commissions'\" ng-init=\"prep()\"></div>\n" +
    "\n" +
    "      <div class=\"col-md-12\" ng-controller=\"commissionsReport\" ng-include src=\"'comp/reports/commissions/report'\"\n" +
    "        ng-if=\"user.can('commissions', 'Bookkeeping Reports') && active === 'commissions'\" ng-init=\"prep()\"></div>\n" +
    "\n" +
    "      <div class=\"col-md-12\" ng-controller=\"glanceReport\" ng-include src=\"'comp/reports/glance/report'\"\n" +
    "        ng-if=\"active === 'at-a-glance'\" ng-init=\"prep()\"></div>\n" +
    "\n" +
    "      <div class=\"col-md-12\" ng-controller=\"pilotManifestReport\" ng-include src=\"'comp/reports/manifest/report'\"\n" +
    "        ng-if=\"active === 'manifest'\" ng-init=\"prep()\"></div>\n" +
    "\n" +
    "      <div class=\"col-md-12\" ng-controller=\"waiversReport\" ng-include src=\"'comp/reports/waivers/report'\"\n" +
    "        ng-if=\"active === 'waivers'\" ng-init=\"prep()\"></div>\n" +
    "\n" +
    "      <div class=\"col-md-12\" ng-controller=\"itSheetReport\" ng-include src=\"'comp/reports/itsheet/report'\"\n" +
    "        ng-if=\"active === 'it-sheet'\" ng-init=\"prep()\"></div>\n" +
    "\n" +
    "      <div class=\"col-md-12\" ng-controller=\"pickupsReport\" ng-include src=\"'comp/reports/pickups/report'\"\n" +
    "        ng-if=\"active === 'pickups'\" ng-init=\"prep()\"></div>\n" +
    "\n" +
    "      <div class=\"col-md-12\" ng-controller=\"salesSlip\" ng-include src=\"'comp/reports/salesslip/report'\"\n" +
    "        ng-if=\"active === 'sales-slip'\" ng-init=\"prep()\"></div>\n" +
    "\n" +
    "      <div class=\"col-md-12\" ng-controller=\"photosReport\" ng-include src=\"'comp/reports/photos/report'\"\n" +
    "        ng-if=\"active === 'photos'\" ng-init=\"prep()\"></div>\n" +
    "\n" +
    "      <div class=\"col-md-12\" ng-controller=\"bookedOn\" ng-include src=\"'comp/reports/bookedon/report'\"\n" +
    "        ng-if=\"active === 'bookedon'\" ng-init=\"prep()\"></div>\n" +
    "\n" +
    "      <div class=\"col-md-12\" ng-controller=\"sourcesReport\" ng-include src=\"'comp/reports/booking-source/report'\"\n" +
    "        ng-if=\"active === 'booking-source'\" ng-init=\"prep()\"></div>\n" +
    "\n" +
    "      <div class=\"col-md-12\" ng-controller=\"packageReport\" ng-include src=\"'comp/reports/package/report'\"\n" +
    "        ng-if=\"active === 'by-package'\" ng-init=\"prep()\"></div>\n" +
    "\n" +
    "      <div class=\"col-md-12\" ng-controller=\"giftsReport\" ng-include src=\"'comp/reports/gifts/report'\"\n" +
    "        ng-if=\"active === 'gifts'\" ng-init=\"prep()\"></div>\n" +
    "\n" +
    "      <div class=\"col-md-12\" ng-controller=\"ratingsReport\" ng-include src=\"'comp/reports/ratings/report'\"\n" +
    "        ng-if=\"active === 'ratings'\" ng-init=\"prep()\"></div>\n" +
    "\n" +
    "      <div class=\"col-md-12\" ng-controller=\"birdeyeReport\" ng-include src=\"'comp/reports/birdeye/report'\"\n" +
    "        ng-if=\"active === 'birdeye'\" ng-init=\"prep()\"></div>\n" +
    "\n" +
    "    </div>\n" +
    "\n" +
    "  </div>\n" +
    "</div>\n" +
    "");
}]);

angular.module("comp/reports/sales/report", []).run(["$templateCache", function($templateCache) {
  $templateCache.put("comp/reports/sales/report",
    "<div class=\"row\" ng-if=\"allowed\" style=\"margin-top: 20px;\" print-section id=\"SalesReport\">\n" +
    "\n" +
    "  <div sales-report=\"data\" start=\"start\" end=\"end\" class=\"col-md-12\" print-landscape>\n" +
    "    <div class=\"pull-right\">\n" +
    "\n" +
    "\n" +
    "      <h3 class=\"heads-up\">\n" +
    "        {{ totalFees = (fees.charged - fees.refunded);'' }}\n" +
    "        <span class=\"amount\" ng-class=\"{'text-danger': totalFees > 0}\">{{ totalFees * -1 | currency }}</span>\n" +
    "        <span class=\"small clear\">Processing Fees</span>\n" +
    "      </h3>\n" +
    "\n" +
    "      <h3 class=\"heads-up\">\n" +
    "        {{ deposit = (data.credit_cards.total || (compiled.debits.credit_card - compiled.credits.refunds) - totalFees);'' }}\n" +
    "        <span class=\"amount\">{{ deposit | currency }}</span>\n" +
    "        <span class=\"small clear\">Credit Card Deposit</span>\n" +
    "      </h3>\n" +
    "\n" +
    "      <h3 class=\"heads-up\">\n" +
    "        <span class=\"amount\">{{ compiled.debits.cash | currency }}</span>\n" +
    "        <span class=\"small clear\">Cash Deposit</span>\n" +
    "      </h3>\n" +
    "\n" +
    "      <h3 class=\"heads-up\">\n" +
    "        <span class=\"amount\">\n" +
    "          <span>{{ compiled.balance | currency }}</span>\n" +
    "        </span>\n" +
    "        <span class=\"small clear\">Balance</span>\n" +
    "      </h3>\n" +
    "\n" +
    "      <button type=\"button\" class=\"pull-right btn bg-danger hidden-print\" ng-click=\"clearSales()\"\n" +
    "        style=\"position: absolute; top: -70px; right: 20px; \">Clear Cache</button>\n" +
    "\n" +
    "    </div>\n" +
    "\n" +
    "    <div class=\"row\">\n" +
    "\n" +
    "      <div class=\"col-md-12\">\n" +
    "        <table class=\"table\" ng-init=\"saleOrder = 'sort.last'; saleRev = false\" print-table=\"compiled\">\n" +
    "          <thead>\n" +
    "            <th>#</th>\n" +
    "            <th class=\"point\" ng-click=\"saleOrder = 'sort.last'; saleRev = !saleRev\">\n" +
    "              <span class=\"hidden-print\">Customer</span>\n" +
    "            </th>\n" +
    "            <th class=\"point\" ng-click=\"saleOrder = 'reference.day'; saleRev = !saleRev\">\n" +
    "              Date\n" +
    "            </th>\n" +
    "            <th ng-class=\"{ 'text-muted': !_.isFiltered('flights') }\">\n" +
    "              <span ng-dblclick=\"_.setFilter('flights')\">Flights</span>\n" +
    "            </th>\n" +
    "            <th ng-class=\"{ 'text-muted': !_.isFiltered('tips') }\">\n" +
    "              <span ng-dblclick=\"_.setFilter('tips')\">Tips</span>\n" +
    "            </th>\n" +
    "            <th ng-class=\"{ 'text-muted': !_.isFiltered('mdse') }\">\n" +
    "              <span ng-dblclick=\"_.setFilter('mdse')\">MDSE</span>\n" +
    "            </th>\n" +
    "            <th ng-class=\"{ 'text-muted': !_.isFiltered('pickup') }\">\n" +
    "              <span ng-dblclick=\"_.setFilter('pickup')\">Pickup</span>\n" +
    "            </th>\n" +
    "            <th ng-class=\"{ 'text-muted': !_.isFiltered('gifts') }\">\n" +
    "              <span ng-dblclick=\"_.setFilter('gifts')\">Sold G.C.</span>\n" +
    "            </th>\n" +
    "            <th ng-class=\"{ 'text-muted': !_.isFiltered('deposits_received') }\">\n" +
    "              <span ng-dblclick=\"_.setFilter('deposits_received')\">\n" +
    "                <span class='hidden-print'>Deposits Received</span>\n" +
    "                <span class='visible-print-inline'>Dep Re</span>\n" +
    "              </span>\n" +
    "            </th>\n" +
    "            <!-- <th>A/R Received</th> -->\n" +
    "            <th>Refunds</th>\n" +
    "            <th>Total Credits</th>\n" +
    "            <th>Total Debits</th>\n" +
    "            <th ng-class=\"{ 'text-muted': !_.isFiltered('balance') }\">\n" +
    "              <span ng-dblclick=\"_.setFilter('balance')\">Balance</span>\n" +
    "            </th>\n" +
    "          </thead>\n" +
    "          <tbody>\n" +
    "            <tr\n" +
    "              ng-repeat=\"guest in compiled.guests | orderBy: saleOrder:saleRev | filter: _.reportFilter track by $index\" \n" +
    "              ng-if=\"guest.report.credits.available()\">\n" +
    "              <td title=\"Confirmation Number\" class=\"confirmation\">\n" +
    "                <a ng-if=\"guest.reference.reference_model === 'Reservation'\"\n" +
    "                  ng-href=\"/booking/{{guest.reference.confirmation}}\"\n" +
    "                  ng-class=\"{'strike': guest.reference.status === 'cancelled'}\">\n" +
    "                  {{ user.activeCompany().nickname }}{{ guest.reference.confirmation }}\n" +
    "                </a>\n" +
    "                <span ng-if=\"guest.reference.reference_model === 'Prepayment'\">\n" +
    "                  {{ guest.reference.code }}\n" +
    "                </span>\n" +
    "              </td>\n" +
    "              <td title=\"Guest Name\">\n" +
    "                <span ng-if=\"guest.reference.reference_model === 'Prepayment'\">\n" +
    "                  {{ guest.sort.first + ', ' + guest.sort.last }}\n" +
    "                </span>\n" +
    "                <a ng-if=\"guest.reference.reference_model === 'Reservation'\" ng-href=\"/booking/{{guest.reference.confirmation}}\">\n" +
    "                  {{ guest.reference.group_name || guest.reference.last_name + ', ' + guest.reference.first_name }}\n" +
    "                </a>\n" +
    "              </td>\n" +
    "              <td title=\"Date\">\n" +
    "                <span class=\"hidden-print\">{{ guest.reference.day || guest.reference.date || guest.reference.created | readTime:'MM/DD/YY':(guest.reference === 'prepayment' ? true : false) }}</span>\n" +
    "                <span class=\"visible-print-inline\">{{ guest.reference.day || guest.reference.date || guest.reference.created | readTime:'MM/DD':(guest.reference === 'prepayment' ? true : false) }}</span>\n" +
    "              </td>\n" +
    "              <td title=\"Flight Credits\" ng-class=\"{ 'text-muted' : guest.report.credits.flights == 0, 'text-danger' : guest.report.credits.flights < 0 }\">\n" +
    "                {{ guest.report.credits.flights | currency }}\n" +
    "              </td>\n" +
    "              <td title=\"Tips\" ng-class=\"{ 'text-muted' : guest.report.credits.tips == 0, 'text-danger' : guest.report.credits.tips < 0 }\">\n" +
    "                {{ guest.report.credits.tips | currency }}\n" +
    "              </td>\n" +
    "              <td title=\"MDSE\" ng-class=\"{ 'text-muted' : guest.report.credits.mdse == 0, 'text-danger' : guest.report.credits.mdse < 0 }\">\n" +
    "                {{ guest.report.credits.mdse | currency }}\n" +
    "              </td>\n" +
    "              <td title=\"Pickups\" ng-class=\"{ 'text-muted' : guest.report.credits.pickup == 0, 'text-danger' : guest.report.credits.pickup < 0 }\">\n" +
    "                {{ guest.report.credits.pickup | currency }}\n" +
    "              </td>\n" +
    "              <td title=\"Gifts\" ng-class=\"{ 'text-muted' : guest.report.credits.gifts == 0, 'text-danger' : guest.report.credits.gifts < 0 }\">\n" +
    "                {{ guest.report.credits.gifts | currency }}\n" +
    "              </td>\n" +
    "              <td title=\"Deposits Received\" ng-class=\"{ 'text-muted' : guest.report.credits.deposits_received == 0, 'text-danger' : guest.report.credits.deposits_received < 0 }\">\n" +
    "                {{ guest.report.credits.deposits_received | currency }}\n" +
    "              </td>\n" +
    "              <td title=\"Refunds\" ng-class=\"{ 'text-muted' : guest.report.credits.refunds.total == 0, 'text-danger' : guest.report.credits.refunds.total < 0 }\">\n" +
    "                {{ guest.report.credits.refunds.total | currency }}\n" +
    "              </td>\n" +
    "              <!-- <td title=\"AR Received\" ng-class=\"{ 'text-muted' : guest.report.credits.ar_received == 0, 'text-danger' : guest.report.credits.ar_received < 0 }\">\n" +
    "                {{ guest.report.credits.ar_received | currency }}\n" +
    "              </td> -->\n" +
    "              <td title=\"Total Credits\" ng-class=\"{ 'text-muted' : guest.report.credits.total == 0, 'text-danger' : guest.report.credits.total < 0 }\">\n" +
    "                {{ guest.report.credits.total | currency }}\n" +
    "              </td>\n" +
    "              <td title=\"Total Debits\" ng-class=\"{ 'text-muted' : guest.report.debits.total == 0, 'text-danger' : guest.report.debits.total < 0 }\">\n" +
    "                {{ guest.report.debits.total | currency }}\n" +
    "              </td>\n" +
    "              <td title=\"Balance\" ng-class=\"{ 'text-muted' : guest.report.balance == 0, 'text-danger' : guest.report.balance != 0 }\">\n" +
    "                {{ guest.report.balance | currency }}\n" +
    "              </td>\n" +
    "            </tr>\n" +
    "          </tbody>\n" +
    "          <tfoot>\n" +
    "            <tr style=\"font-weight: bold;\">\n" +
    "              <td colspan=\"3\"></td>\n" +
    "              <td ng-class=\"{ 'text-muted' : compiled.credits.flights == 0, 'text-danger' : compiled.credits.flights < 0 }\">\n" +
    "                {{ compiled.credits.flights | currency }}\n" +
    "              </td>\n" +
    "              <td ng-class=\"{ 'text-muted' : compiled.credits.tips == 0, 'text-danger' : compiled.credits.tips < 0 }\">\n" +
    "                {{ compiled.credits.tips | currency }}\n" +
    "              </td>\n" +
    "              <td ng-class=\"{ 'text-muted' : compiled.credits.mdse == 0, 'text-danger' : compiled.credits.mdse < 0 }\">\n" +
    "                {{ compiled.credits.mdse | currency }}\n" +
    "              </td>\n" +
    "              <td ng-class=\"{ 'text-muted' : compiled.credits.pickup == 0, 'text-danger' : compiled.credits.pickup < 0 }\">\n" +
    "                {{ compiled.credits.pickup | currency }}\n" +
    "              </td>\n" +
    "              <td ng-class=\"{ 'text-muted' : compiled.credits.gifts == 0, 'text-danger' : compiled.credits.gifts < 0 }\">\n" +
    "                {{ compiled.credits.gifts | currency }}\n" +
    "              </td>\n" +
    "              <td ng-class=\"{ 'text-muted' : compiled.credits.deposits_received == 0, 'text-danger' : compiled.credits.deposits_received < 0 }\">\n" +
    "                {{ compiled.credits.deposits_received | currency }}\n" +
    "              </td>\n" +
    "              <!-- <td ng-class=\"{ 'text-muted' : compiled.credits.ar_received == 0, 'text-danger' : compiled.credits.ar_received < 0 }\">\n" +
    "                {{ compiled.credits.ar_received | currency }}\n" +
    "              </td> -->\n" +
    "              <td ng-class=\"{ 'text-muted' : compiled.credits.refunds == 0, 'text-danger' : compiled.credits.refunds !== 0 }\">\n" +
    "                {{ compiled.credits.refunds | currency }}\n" +
    "              </td>\n" +
    "              <td ng-class=\"{ 'text-muted' : compiled.credits.total == 0, 'text-danger' : compiled.credits.total < 0 }\">\n" +
    "                {{ compiled.credits.total | currency }}\n" +
    "              </td>\n" +
    "              <td ng-class=\"{ 'text-muted' : compiled.debits.total == 0, 'text-danger' : compiled.debits.total < 0 }\">\n" +
    "                {{ compiled.debits.total | currency }}\n" +
    "              </td>\n" +
    "              <td ng-class=\"{ 'text-muted' : compiled.balance == 0, 'text-danger' : compiled.balance != 0 }\">\n" +
    "                {{ compiled.balance | currency }}\n" +
    "              </td>\n" +
    "            </tr>\n" +
    "          </tfoot>\n" +
    "        </table>\n" +
    "      </div>\n" +
    "    </div>\n" +
    "\n" +
    "    <div class=\"row\">\n" +
    "\n" +
    "      <div class=\"col-md-12\">\n" +
    "\n" +
    "        <div class=\"checkbox pull-right hidden-print\">\n" +
    "          <label>\n" +
    "            <input type=\"checkbox\" ng-model=\"expanded\"> Expand\n" +
    "          </label>\n" +
    "        </div>\n" +
    "        <table class=\"table\" ng-init=\"orderCharges = 'sort.last'; reverse = false\">\n" +
    "          <thead>\n" +
    "            <th>#</th>\n" +
    "            <th class=\"point\" ng-click=\"orderCharges = 'sort.last'; reverse = !reverse\">Customer</th>\n" +
    "            <th class=\"point\" ng-click=\"orderCharges = 'reference.day'; reverse = !reverse\">\n" +
    "              <span ng-if=\"!expanded\">Date</span>\n" +
    "              <span ng-if=\"expanded\">Charge Date</span>\n" +
    "            </th>\n" +
    "            <th ng-class=\"{ 'text-muted': !_.isFiltered('credit_card') }\">\n" +
    "              <span ng-dblclick=\"_.setFilter('credit_card')\">Credit Card</span>\n" +
    "            </th>\n" +
    "            <th ng-class=\"{ 'text-muted': !_.isFiltered('square') }\">\n" +
    "              <span ng-dblclick=\"_.setFilter('square')\">Square</span>\n" +
    "            </th>\n" +
    "            <th ng-hide=\"true\" ng-class=\"{ 'text-muted': !_.isFiltered('fareharbor') }\">\n" +
    "              <span ng-dblclick=\"_.setFilter('fareharbor')\">FareHarbor</span>\n" +
    "            </th>\n" +
    "            <th ng-class=\"{ 'text-muted': !_.isFiltered('clover') }\">\n" +
    "              <span ng-dblclick=\"_.setFilter('clover')\">Clover</span>\n" +
    "            </th>\n" +
    "            <th ng-class=\"{ 'text-muted': !_.isFiltered('cash') }\">\n" +
    "              <span ng-dblclick=\"_.setFilter('cash')\">Cash</span>\n" +
    "            </th>\n" +
    "            <th ng-class=\"{ 'text-muted': !_.isFiltered('gifts_redeemed') }\">\n" +
    "              <span ng-dblclick=\"_.setFilter('gifts_redeemed')\">GC Redeemed</span>\n" +
    "            </th>\n" +
    "            <th ng-class=\"{ 'text-muted': !_.isFiltered('deposits_redeemed') }\">\n" +
    "              <span ng-dblclick=\"_.setFilter('deposits_redeemed')\">Deposits Redeemed</span>\n" +
    "            </th>\n" +
    "            <th ng-class=\"{ 'text-muted': !_.isFiltered('ar') }\">\n" +
    "              <span ng-dblclick=\"_.setFilter('ar')\">A/R</span>\n" +
    "            </th>\n" +
    "            <th ng-class=\"{ 'text-muted': !_.isFiltered('discounts') }\">\n" +
    "              <span ng-dblclick=\"_.setFilter('discounts')\">Discounts</span>\n" +
    "            </th>\n" +
    "            <th ng-class=\"{ 'text-muted': !_.isFiltered('deposits_refunded') }\">\n" +
    "              <span ng-dblclick=\"_.setFilter('deposits_refunded')\">Refunded Deposits</span>\n" +
    "            </th>\n" +
    "            <th>Total Debits</th>\n" +
    "          </thead>\n" +
    "          <tbody>\n" +
    "            <tr sales-report-guest\n" +
    "              ng-repeat=\"guest in compiled.guests | orderBy: orderCharges:reverse | filter: _.reportFilterx track by $index\" ng-hide=\"expanded\"\n" +
    "              ng-if=\"guest.report.debits.available()\">\n" +
    "              <td title=\"Confirmation Number\">\n" +
    "                <a ng-if=\"guest.reference.reference_model === 'Reservation'\"\n" +
    "                  ng-href=\"/booking/{{guest.reference.confirmation}}\"\n" +
    "                  ng-class=\"{'strike': guest.reference.status === 'cancelled'}\">\n" +
    "                  {{ user.activeCompany().nickname }}{{ guest.reference.confirmation }}\n" +
    "                </a>\n" +
    "                <span ng-if=\"guest.reference.reference_model === 'Prepayment'\">\n" +
    "                  {{ guest.reference.code }}\n" +
    "                </span>\n" +
    "              </td>\n" +
    "              <td title=\"Guest Name\">\n" +
    "                <span ng-if=\"guest.reference.reference_model === 'Prepayment'\">\n" +
    "                  {{ guest.sort.first + ', ' + guest.sort.last }}\n" +
    "                </span>\n" +
    "                <a ng-if=\"guest.reference.reference_model === 'Reservation'\" ng-href=\"/booking/{{guest.reference.confirmation}}\">\n" +
    "                  {{ guest.reference.group_name || guest.reference.last_name + ', ' + guest.reference.first_name }}\n" +
    "                </a>\n" +
    "              </td>\n" +
    "              <td title=\"Date\">{{ guest.reference.day || guest.reference.created | readTime:'MM/DD/YY':(guest.reference === 'prepayment' ? true : false) }}</td>\n" +
    "              <td title=\"Credit Card\" ng-class=\"{ 'text-muted': guest.report.debits.credit_card.total == 0 }\">{{ guest.report.debits.credit_card.total | currency }}</td>\n" +
    "              <td title=\"Square\" ng-class=\"{ 'text-muted': guest.report.debits.square.total == 0 }\">\n" +
    "                {{ guest.report.debits.square.total | currency }}</td>\n" +
    "              <td ng-hide=\"true\" title=\"FareHarbor\" ng-class=\"{ 'text-muted': guest.report.debits.fareharbor.total == 0 }\">\n" +
    "                {{ guest.report.debits.fareharbor.total | currency }}</td>\n" +
    "              </td>\n" +
    "              <td title=\"Clover\" ng-class=\"{ 'text-muted': guest.report.debits.clover.total == 0 }\">\n" +
    "                {{ guest.report.debits.clover.total | currency }}</td>\n" +
    "              </td>\n" +
    "              <td title=\"Cash\" ng-class=\"{ 'text-muted': guest.report.debits.cash.total == 0 }\">{{ guest.report.debits.cash.total | currency }}</td>\n" +
    "              <td title=\"GC Redeemed\" ng-class=\"{ 'text-muted': guest.report.debits.gifts_redeemed.total == 0 }\">{{ guest.report.debits.gifts_redeemed.total | currency }}</td>\n" +
    "              <td title=\"Deposits Redeemed\" ng-class=\"{ 'text-muted': guest.report.debits.deposits_redeemed.total == 0 }\">{{ guest.report.debits.deposits_redeemed.total | currency }}</td>\n" +
    "              <td title=\"A/R\" ng-class=\"{ 'text-muted': guest.report.debits.ar.total == 0 }\">{{ guest.report.debits.ar.total | currency }}</td>\n" +
    "              <td title=\"Discounts\" ng-class=\"{ 'text-muted': guest.report.debits.discounts.total == 0 }\">{{ guest.report.debits.discounts.total | currency }}</td>\n" +
    "              <td title=\"Deposits Refunds\" ng-class=\"{ 'text-muted': guest.report.debits.deposits_refunded == 0, 'text-danger': guest.report.debits.deposits_refunded !== 0 }\">{{ guest.report.debits.deposits_refunded | currency }}</td>\n" +
    "              <td title=\"Total Debits\" ng-class=\"{ 'text-muted': guest.report.debits.total == 0 }\">{{ guest.report.debits.total | currency }}</td>\n" +
    "            </tr>\n" +
    "          </tbody>\n" +
    "          <tfoot>\n" +
    "            <tr>\n" +
    "              <td>&nbsp;</td>\n" +
    "              <td>&nbsp;</td>\n" +
    "              <td>&nbsp;</td>\n" +
    "              <td ng-class=\"{ 'text-muted': compiled.debits.credit_card === 0 }\">\n" +
    "                <strong>{{ compiled.debits.credit_card | currency }}</strong>\n" +
    "              </td>\n" +
    "              <td ng-class=\"{ 'text-muted': compiled.debits.square === 0 }\">\n" +
    "                <strong>{{ compiled.debits.square | currency }}</strong>\n" +
    "              </td>\n" +
    "              <td ng-hide=\"true\" ng-class=\"{ 'text-muted': compiled.debits.fareharbor === 0 }\">\n" +
    "                <strong>{{ compiled.debits.fareharbor | currency }}</strong>\n" +
    "              </td>\n" +
    "              <td ng-class=\"{ 'text-muted': compiled.debits.clover === 0 }\">\n" +
    "                <strong>{{ compiled.debits.clover | currency }}</strong>\n" +
    "              </td>\n" +
    "              <td ng-class=\"{ 'text-muted': compiled.debits.cash === 0 }\">\n" +
    "                <strong>{{ compiled.debits.cash | currency }}</strong>\n" +
    "              </td>\n" +
    "              <td ng-class=\"{ 'text-muted': compiled.debits.gifts_redeemed === 0 }\">\n" +
    "                <strong>{{ compiled.debits.gifts_redeemed | currency }}</strong>\n" +
    "              </td>\n" +
    "              <td ng-class=\"{ 'text-muted': compiled.debits.deposits_redeemed === 0 }\">\n" +
    "                <strong>{{ compiled.debits.deposits_redeemed | currency }}</strong>\n" +
    "              </td>\n" +
    "              <td ng-class=\"{ 'text-muted': compiled.debits.ar === 0 }\">\n" +
    "                <strong>{{ compiled.debits.ar | currency }}</strong>\n" +
    "              </td>\n" +
    "              <td ng-class=\"{ 'text-muted': compiled.debits.discounts === 0 }\">\n" +
    "                <strong>{{ compiled.debits.discounts | currency }}</strong>\n" +
    "              </td>\n" +
    "              <td ng-class=\"{ 'text-muted': compiled.debits.deposits_refunded === 0, 'text-danger': compiled.debits.deposits_refunded !== 0 }\">\n" +
    "                <strong>{{ compiled.debits.deposits_refunded | currency }}</strong>\n" +
    "              </td>\n" +
    "              <td ng-class=\"{ 'text-muted': compiled.debits.total === 0 }\">\n" +
    "                <strong>{{ compiled.debits.total | currency }}</strong>\n" +
    "              </td>\n" +
    "            </tr>\n" +
    "          </tfoot>\n" +
    "        </table>\n" +
    "      </div>\n" +
    "    </div>\n" +
    "\n" +
    "  </div>\n" +
    "\n" +
    "</div>\n" +
    "\n" +
    "<div ng-if=\"!allowed\" class=\"row\">\n" +
    "  <div class=\"col-md-7\"><span class=\"text-muted\">Report not Authorized</span></div>\n" +
    "</div>\n" +
    "");
}]);

angular.module("comp/reports/salesslip/report", []).run(["$templateCache", function($templateCache) {
  $templateCache.put("comp/reports/salesslip/report",
    "<div class=\"row\" style=\"margin-top: 20px; margin-bottom: 20px;\"\n" +
    "  ng-init=\"prepPrice(booking)\">\n" +
    "  <div class=\"col-md-7\">\n" +
    "    <table print-table=\"data\" style=\"width: 100%\">\n" +
    "      <tbody>\n" +
    "        <tr ng-repeat=\"(key, booking) in data\" ng-if=\"booking.status == 'active'\">\n" +
    "          <td>\n" +
    "            <h5 class=\"upper\">\n" +
    "              <a ui-sref=\"editBooking({ confirmation: booking.confirmation })\">\n" +
    "                <span ng-if=\"!booking.group\">{{ booking.first_name }} {{ booking.last_name }} </span>\n" +
    "                <span ng-if=\"booking.group\">{{ booking.group_name }} </span>\n" +
    "                <small>{{ day }}</small>\n" +
    "              </a>\n" +
    "            </h5>\n" +
    "\n" +
    "            <table class=\"table table-bordered table-striped\"\n" +
    "              ng-init=\"booking.running = {};\"\n" +
    "              ng-controller=\"PricingDirectiveHelper\" style=\"width: 99%;\">\n" +
    "              <thead>\n" +
    "                <tr>\n" +
    "                  <th style=\"width: 30%;\">Description</th>\n" +
    "                  <th style=\"width: 10%\">Qty</th>\n" +
    "                  <th style=\"width: 20%\">Price</th>\n" +
    "                  <th style=\"width: 20%\">Unit Total</th>\n" +
    "                </tr>\n" +
    "              </thead>\n" +
    "              <tbody ng-init=\"rows = countRows(booking)\">\n" +
    "                <!-- <tr>\n" +
    "                  <td colspan=\"4\">\n" +
    "                    <pre><code>\n" +
    "                      {{ booking.pricing | json }}\n" +
    "                    </code></pre>\n" +
    "                  </td>\n" +
    "                </tr> -->\n" +
    "                <tr ng-init=\"booking.running['a1'] = booking.pricing.package_cost\">\n" +
    "                  <td>{{ package.name }}</td>\n" +
    "                  <td>{{ booking.adults + (booking.children || 0) }}</td>\n" +
    "                  <td>\n" +
    "                    {{ booking.sales_report.package.total / (booking.adults + (booking.children || 0)) | currency }}\n" +
    "                  </td>\n" +
    "                  <td>\n" +
    "                    {{ booking.sales_report.package.total | currency }}\n" +
    "                  </td>\n" +
    "                  <!-- <td>\n" +
    "                    {{ booking.pricing.package_cost | currency }}\n" +
    "                  </td> -->\n" +
    "                </tr>\n" +
    "                <tr ng-repeat=\"item in booking.pricing.items\"\n" +
    "                  ng-init=\"booking.running['b' + $index] = item.running\"\n" +
    "                  ng-if=\"!booking.hotel || (booking.hotel && item.product.name !== 'Hotel Pickup')\">\n" +
    "                  <td>{{ item.product.name }}</td>\n" +
    "                  <td>{{ item.count }}</td>\n" +
    "                  <td>{{ item.value - (item.discount / item.count || 0) | currency }}</td>\n" +
    "                  <td>{{ item.total | currency }}</td>\n" +
    "                  <!-- <td>{{ item.running | currency }}</td> -->\n" +
    "                </tr>\n" +
    "                <tr ng-if=\"booking.pricing.discount\" ng-init=\"booking.running['c0'] = lastObject(booking.running) + booking.pricing.discount\">\n" +
    "                  <td>{{ booking.pricing.discount_name || 'Discount' }}</td>\n" +
    "                  <td>1</td>\n" +
    "                  <td>{{ booking.pricing.discount | currency }}</td>\n" +
    "                  <td>{{ booking.pricing.discount | currency }}</td>\n" +
    "                  <!-- <td>{{ booking.running['c0'] | currency }}</td> -->\n" +
    "                </tr>\n" +
    "                <tr ng-if=\"aggregateCash(booking) > 0\"\n" +
    "                  ng-init=\"booking.running['d0'] = lastObject(booking.running) - booking.pricing.Cash()\">\n" +
    "                  <td>Cash</td>\n" +
    "                  <td>{{ booking.pricing.other_payments.cash.length }}</td>\n" +
    "                  <td>{{ -booking.pricing.Cash() | currency }}</td>\n" +
    "                  <td>{{ -booking.pricing.Cash() | currency }}</td>\n" +
    "                  <!-- <td>{{ booking.running['d0'] | currency }}</td> -->\n" +
    "                </tr>\n" +
    "                <tr ng-repeat=\"(key, gift) in booking.pricing.other_payments.gifts\" ng-init=\"booking.running['e' + $index] = lastObject(booking.running) - gift.amount\">\n" +
    "                  <td>Gift: {{ gift.code }}</td>\n" +
    "                  <td>1</td>\n" +
    "                  <td>{{ -gift.amount | currency }}</td>\n" +
    "                  <td>{{ -gift.amount | currency }}</td>\n" +
    "                  <!-- <td>{{ booking.running['e' + $index] | currency }}</td> -->\n" +
    "                </tr>\n" +
    "                <tr ng-if=\"charges.length > 0\" ng-repeat=\"(key, charge) in booking.pricing.charges\" ng-init=\"booking.running['f' + $index] = lastObject(booking.running) - charge.balance\">\n" +
    "                  <td>{{ charge.card.type }}: {{ charge.card.masked }}</td>\n" +
    "                  <td>1</td>\n" +
    "                  <td>{{ -charge.balance | currency }}</td>\n" +
    "                  <td>{{ -charge.balance | currency }}</td>\n" +
    "                  <!-- <td>{{ booking.running['f' + $index] | currency }}</td> -->\n" +
    "                </tr>\n" +
    "                <tr ng-if=\"booking.pricing.other_payments.other.length > 0\"\n" +
    "                   ng-repeat=\"(key, charge) in booking.pricing.other_payments.other\"\n" +
    "                   ng-init=\"booking.running['g' + $index] = lastObject(booking.running) - charge.amount\">\n" +
    "                  <td>\n" +
    "                    {{ 'Bill To: ' + charge.partner.name }}\n" +
    "                    <span ng-if=\"charge.type\">: {{ charge.type }}</span>\n" +
    "                  </td>\n" +
    "                  <td>1</td>\n" +
    "                  <td>{{ -charge.amount | currency }}</td>\n" +
    "                  <td>{{ -charge.amount | currency }}</td>\n" +
    "                  <!-- <td>{{ booking.running['g' + $index] | currency }}</td> -->\n" +
    "                </tr>\n" +
    "                <tr ng-repeat=\"(key, value) in blanks(rows, 4)\">\n" +
    "                  <td>&nbsp;</td>\n" +
    "                  <td></td>\n" +
    "                  <td></td>\n" +
    "                  <td></td>\n" +
    "                  <!-- <td></td> -->\n" +
    "                </tr>\n" +
    "              </tbody>\n" +
    "              <tfoot>\n" +
    "                <tr class=\"active\">\n" +
    "                  <td colspan=\"3\">\n" +
    "                    <strong class=\"\">Balance</strong>: \n" +
    "                  </td>\n" +
    "                  <td>\n" +
    "                    <strong>{{ balance(booking) | currency }}</strong>\n" +
    "                    <!-- <pre><code>\n" +
    "                      {{ booking.pricing | json }}\n" +
    "                    </code></pre> -->\n" +
    "                  </td>\n" +
    "                </tr>\n" +
    "                <tr>\n" +
    "                  <td colspan=\"3\">\n" +
    "                    <strong class=\"pull-left\" ng-if=\"booking.pricing.cards.length == 0\">No Card</strong>\n" +
    "                    <span class=\"pull-left\" ng-if=\"booking.pricing.cards.length > 0\" ng-init=\"card = booking.pricing.cards[0]\">\n" +
    "                      <strong ng-if=\"card.status != 'invalid'\">{{ card.type }} - {{ card.masked }}</strong>\n" +
    "                      <strong ng-if=\"card.status == 'invalid'\">Invalid Card</strong>\n" +
    "                    </span>\n" +
    "                    <strong class=\"pull-right\">Total:</strong>\n" +
    "                  </td>\n" +
    "                  <td></td>\n" +
    "                </tr>\n" +
    "\n" +
    "              </tfoot>\n" +
    "            </table>\n" +
    "\n" +
    "          </td>\n" +
    "        </tr>\n" +
    "      </tbody>\n" +
    "    </table>\n" +
    "\n" +
    "  </div>\n" +
    "\n" +
    "</div>\n" +
    "");
}]);

angular.module("comp/reports/tips/report", []).run(["$templateCache", function($templateCache) {
  $templateCache.put("comp/reports/tips/report",
    "<div class=\"row\" ng-if=\"allowed\" print-section>\n" +
    "  <div class=\"col-md-9\" id=\"tipsReport\" sales-report=\"data\" start=\"start\" end=\"end\">\n" +
    "    <h4>Calculated Tips</h4>\n" +
    "    <table class=\"table table-striped\">\n" +
    "\n" +
    "      <thead>\n" +
    "        <tr>\n" +
    "          <th>Recipient</th>\n" +
    "          <th ng-repeat=\"(key, day) in calucatedTips[0] | toArray | orderBy:day.date\" class=\"text-right\">\n" +
    "            {{ day.date | readTime:'MM-DD-YY':true}}\n" +
    "          </th>\n" +
    "          <th class=\"text-right\">Total</th>\n" +
    "        </tr>\n" +
    "      </thead>\n" +
    "      <tbody>\n" +
    "        <tr ng-repeat=\"(name, recipient) in calucatedTips[1]\">\n" +
    "          <td>{{ name }}</td>\n" +
    "          <td ng-repeat=\"(key, day) in calucatedTips[0] | toArray | orderBy:day.date\" class=\"text-right\" ng-init=\"dayTotal = day.recipients[name] || 0\">\n" +
    "            <span ng-class=\"{ 'text-muted': dayTotal === 0}\">\n" +
    "              {{ dayTotal | currency }}\n" +
    "            </span>\n" +
    "          </td>\n" +
    "          <td class=\"text-right text-bold\">{{ recipient || 0 | currency }}</td>\n" +
    "        </tr>\n" +
    "      </tbody>\n" +
    "      <tfoot>\n" +
    "\n" +
    "        <tr>\n" +
    "          <td><strong>Totals by Day</strong></td>\n" +
    "          <td ng-repeat=\"(key, day) in calucatedTips[0] | toArray | orderBy:day.date\" class=\"text-right\">\n" +
    "            <strong>{{ day.total || 0 | currency }}</strong>\n" +
    "          </td>\n" +
    "          <td class=\"text-right text-bold\">{{ getTotalCalculatedTips(tips.days) | currency }}</td>\n" +
    "        </tr>\n" +
    "\n" +
    "      </tfoot>\n" +
    "\n" +
    "    </table>\n" +
    "    <hr />\n" +
    "\n" +
    "    <h4>Rezflow Tips</h4>\n" +
    "    <table class=\"table table-striped\">\n" +
    "\n" +
    "      <thead>\n" +
    "        <tr>\n" +
    "          <th>Pilot</th>\n" +
    "          <th ng-repeat=\"(key, day) in compiled.tips.days | objectKeys | orderBy:day\" ng-if=\"day !== 'total'\">\n" +
    "            <a ng-href=\"/reports/sales/{{ day | readTime:'MM-DD-YY' }}\" class=\"\">{{ day }}</a>\n" +
    "          </th>\n" +
    "          <th class=\"text-right\">Pilot Total</th>\n" +
    "        </tr>\n" +
    "      </thead>\n" +
    "      <tbody>\n" +
    "        <tr ng-repeat=\"(key, pilot) in compiled.tips.pilots | objectKeys\">\n" +
    "          <td>{{ pilot }}</td>\n" +
    "          <td ng-repeat=\"(key, day) in compiled.tips.days | objectKeys | orderBy:day\" ng-if=\"day !== 'total'\">\n" +
    "            {{ compiled.tips.pilots[pilot][day] || 0 | currency }}\n" +
    "          </td>\n" +
    "          <td class=\"text-right text-bold\">{{ compiled.tips.pilots[pilot].total || 0 | currency }}</td>\n" +
    "        </tr>\n" +
    "      </tbody>\n" +
    "      <tfoot>\n" +
    "\n" +
    "        <tr>\n" +
    "          <td><strong>Totals by Day</strong></td>\n" +
    "          <td class=\"text-bold\" ng-repeat=\"(key, day) in compiled.tips.days | objectKeys | orderBy:day\"\n" +
    "            ng-if=\"day !== 'total'\">\n" +
    "            <span>{{ compiled.tips.days[day] | currency }}</span>\n" +
    "          </td>\n" +
    "          <td class=\"text-right text-bold\">{{ compiled.tips.days.total | currency }}</td>\n" +
    "        </tr>\n" +
    "\n" +
    "      </tfoot>\n" +
    "\n" +
    "    </table>\n" +
    "    <hr />\n" +
    "    <h4>Square Entries</h4>\n" +
    "    <table class=\"table table-striped\">\n" +
    "      <thead>\n" +
    "        <tr>\n" +
    "          <th>Pilot</th>\n" +
    "          <th ng-repeat=\"(index, day) in squareArray | orderBy:day.date\" class=\"text-right\">\n" +
    "            {{ day.date | readTime:'MM-DD-YY':true }}\n" +
    "          </th>\n" +
    "          <th class=\"text-right\">Total</th>\n" +
    "        </tr>\n" +
    "      </thead>\n" +
    "      <tbody>\n" +
    "        <tr ng-repeat=\"(key, pilot) in tips.pilots\">\n" +
    "          <td>{{ pilot }}</td>\n" +
    "          <td ng-repeat=\"(index, day) in squareArray | orderBy:day.date\" class=\"text-right\">\n" +
    "            {{ day.pilots[pilot].square || 0 | currency }}\n" +
    "          </td>\n" +
    "          <td class=\"text-right text-bold\">{{ totalSquareTipsForPilot(pilot) || 0 | currency }}</td>\n" +
    "        </tr>\n" +
    "      </tbody>\n" +
    "\n" +
    "      <tfoot>\n" +
    "        <tr>\n" +
    "          <td><strong>Totals by Day</strong></td>\n" +
    "          <td ng-repeat=\"(key, day) in squareArray | orderBy:day.date\" class=\"text-right\">\n" +
    "            <strong>{{ day.total | currency }}</strong>\n" +
    "          </td>\n" +
    "          <td class=\"text-right text-bold\">{{ getTotalSquareTips(squareArray) | currency }}</td>\n" +
    "        </tr>\n" +
    "      </tfoot>\n" +
    "\n" +
    "    </table>\n" +
    "  </div>\n" +
    "\n" +
    "</div>\n" +
    "\n" +
    "<div ng-if=\"!allowed\" class=\"row\">\n" +
    "  <div class=\"col-md-7\"><span class=\"text-muted\">Report not Authorized</span></div>\n" +
    "</div>\n" +
    "");
}]);

angular.module("comp/reports/waivers/report", []).run(["$templateCache", function($templateCache) {
  $templateCache.put("comp/reports/waivers/report",
    "\n" +
    "<div class=\"row\" print-section>\n" +
    "  <div class=\"col-md-8\">\n" +
    "    <table class=\"table table-striped\">\n" +
    "      <thead>\n" +
    "        <tr>\n" +
    "          <th>Confirmation</th>\n" +
    "          <th>Last Name</th>\n" +
    "          <th>First Name</th>\n" +
    "          <th>Phone</th>\n" +
    "          <th>Signatures</th>\n" +
    "          <th>Signed</th>\n" +
    "        </tr>\n" +
    "      </thead>\n" +
    "      <tbody>\n" +
    "        <tr ng-repeat=\"booking in bookings | orderBy: 'last_name'\" ng-init=\"guestCount = count(booking); signatureCount = signatures(booking)\" ng-class=\"{ 'danger': signatureCount < guestCount}\">\n" +
    "          <td>{{ user.activeCompany().nickname }}{{ booking.confirmation }}</td>\n" +
    "          <td colspan=\"2\" ng-if=\"booking.group_name\">{{ booking.group_name}}</td>\n" +
    "          <td ng-if=\"!booking.group_name\">{{ booking.last_name }}</td>\n" +
    "          <td ng-if=\"!booking.group_name\">{{ booking.first_name }}</td>\n" +
    "          <td>{{ booking.phone }}</td>\n" +
    "          <td>{{ signatureCount }}/{{ guestCount }}</td>\n" +
    "          <td>\n" +
    "            <ul class=\"list-unstyled\" ng-repeat=\"waiver in booking.smartWaiver\">\n" +
    "              <li ng-repeat=\"participant in waiver.data.participants\">{{ participant.firstName }} {{ participant.lastName }}</li>\n" +
    "            </ul>\n" +
    "          </td>\n" +
    "        </tr>\n" +
    "      </tbody>\n" +
    "    </table>\n" +
    "  </div>\n" +
    "</div>\n" +
    "");
}]);

angular.module("comp/reservations/booked_online/template", []).run(["$templateCache", function($templateCache) {
  $templateCache.put("comp/reservations/booked_online/template",
    "<header class=\"clearfix\" style=\"margin-bottom: 10px;\">\n" +
    "  <h4 class=\"\" style=\"margin-bottom: 20px;\">\n" +
    "    <span class=\"pull-left\" style=\"margin-right: 20px;\">Booked Online</span>\n" +
    "  </h4>\n" +
    "</header>\n" +
    "\n" +
    "<div class=\"row\" style=\"margin-bottom: 20px;\">\n" +
    "  <div class=\"col-md-3\">\n" +
    "    <span class=\"dateSelect\" id=\"DatePicker\" style=\"font-weight: bold;\">\n" +
    "      <input\n" +
    "        style=\"width: 225px;\"\n" +
    "        date-range-picker\n" +
    "        class=\"pointer form-control date-picker hidden-print\"\n" +
    "        type=\"text\"\n" +
    "        ng-model=\"calendar.date\"\n" +
    "        options=\"calendar.options\" />\n" +
    "    </span>  \n" +
    "  </div>\n" +
    "  <div class=\"col-md-9 text-right\">\n" +
    "    <div class=\"btn-group\">\n" +
    "      <button type=\"button\" class=\"btn bg-light lt\" ng-disabled=\"page === 1\"><</button>\n" +
    "      <button type=\"button\" class=\"btn bg-light lt\" ng-disabled=\"true\">\n" +
    "        {{ ((page-1) * limit) + 1 }} - \n" +
    "        <span ng-if=\"page === pages.length\">{{ count }}</span>\n" +
    "        <span ng-if=\"page !== pages.length\">{{ page * limit }}</span>\n" +
    "      </button>\n" +
    "      <button type=\"button\" class=\"btn bg-light lt\" ng-class=\"{ 'bg-info': page === val.page }\" ng-repeat=\"(key, val) in pages\" ng-click=\"updatePage(val.page)\">{{val.page}}</button>\n" +
    "      <button type=\"button\" class=\"btn bg-light lt\" ng-disabled=\"true\">{{ count }} Bookings</button>\n" +
    "      <button type=\"button\" class=\"btn bg-light lt\" ng-disabled=\"page === pages.length\">></button>\n" +
    "    </div>\n" +
    "  </div>\n" +
    "</div>\n" +
    "\n" +
    "<table class=\"reservations table table-hover table-condensed\">\n" +
    "  <thead>\n" +
    "    <tr>\n" +
    "      <th style=\"width: 140px;\">#</th>\n" +
    "      <th>Booked On</th>\n" +
    "      <th>Booked For</th>\n" +
    "      <th>Contact</th>\n" +
    "      <th style=\"text-align: center;\">Guests</th>\n" +
    "      <th>Package</th>\n" +
    "      <th>Extras</th>\n" +
    "      <th>Hotel</th>\n" +
    "      <th style=\"max-width: 100px; text-align: center;\">Pickup</th>\n" +
    "      <th>Phone Number</th>\n" +
    "    </tr>\n" +
    "  </thead>\n" +
    "  <tbody>\n" +
    "    <tr ng-repeat=\"booking in bookings | orderBy:'-created_on'\" class=\"\" ui-sref=\"editBooking({ confirmation: booking.confirmation})\">\n" +
    "      <td>\n" +
    "        <span class=\"label\" ng-class=\"{\n" +
    "          'label-success': booking.status === 'active',\n" +
    "          'label-warning': booking.status === 'pending',\n" +
    "          'bg-danger lter': booking.status === 'waiting'\n" +
    "        }\">{{ booking.company.nickname }}{{ booking.confirmation }}</span>\n" +
    "      </td>\n" +
    "      <td>\n" +
    "        <span class=\"\">{{ booking.created_on | date:'MM-dd-yy' : 'UTC' }}</span>\n" +
    "      </td>\n" +
    "      <td>\n" +
    "        <span class=\"\">{{ booking.day | date:'MM-dd-yy' : 'UTC' }}</span>\n" +
    "      </td>\n" +
    "      <td>\n" +
    "        <span title=\"Booking contains heavy guest\" ng-if=\"isHeavy(booking)\"><i class=\"fa fa-anchor\" style=\"margin-right: 5px; margin-left: 10px;\"></i></span>\n" +
    "        <a ng-if=\"!booking.group\" class=\"btn btn-link\">{{ booking.last_name }}, {{ booking.first_name }}</a>\n" +
    "        <a ng-if=\"booking.group\" class=\"btn btn-link\">{{ booking.group_name }}</a>\n" +
    "        <div class=\"pull-right\" style=\"margin: 6px 0\">\n" +
    "          <span class=\"label lter bg-success label-sm\" ng-show=\"booking.vip\">VIP</span>\n" +
    "          <span class=\"label lter bg-info label-sm\" ng-show=\"booking.group\">Group</span>\n" +
    "          <span class=\"label lter bg-warning label-sm\" ng-show=\"booking.hotel\">Hotel</span>\n" +
    "        </div>\n" +
    "      </td>\n" +
    "      <td style=\"text-align: center;\" ui-sref=\"day.bookings({ day: (booking.day | readTime: 'MM-DD-YY':true) })\">{{ booking.adults + booking.children }}</td>\n" +
    "      <td>{{ booking.package.name }}</td>\n" +
    "      <td class=\"extras\">\n" +
    "        <div class=\"label-group\">\n" +
    "          <span class=\"label bg-info\" ng-attr-title=\"Cancellation Policy signed on {{ booking.meta.cancellation_policy | readTime: 'MM/DD'}} at {{ booking.meta.cancellation_policy | readTime: 'h:mmA'}}\"><i class=\"fa fa-ban\"></i> {{ booking.meta.cancellation_policy | readTime: 'hh:mmA'}}</span>\n" +
    "          <span class=\"label bg-light\" ng-attr-title=\"No-show Policy signed on {{ booking.meta.sign_noshow | readTime: 'MM/DD'}} at {{ booking.meta.sign_noshow | readTime: 'h:mmA'}}\"><i class=\"fa fa-eye-slash\"></i> {{ booking.meta.sign_noshow | readTime: 'hh:mmA'}}</span>\n" +
    "          <span class=\"label label-default\" ng-if=\"booking.meta.confirmation_printed\" ng-attr-title=\"confirmation printed on {{ booking.meta.confirmation_printed | readTime: 'MM/DD'}} at {{ booking.meta.confirmation_printed | readTime: 'h:mmA'}}\"><i class=\"fa fa-print\"></i></span>\n" +
    "        </div>\n" +
    "      </td>\n" +
    "      <td>\n" +
    "        <span ng-if=\"booking.accommodations.hotel.name !== 'Other'\">{{ booking.accommodations.hotel.name }}</span>\n" +
    "        <em ng-if=\"booking.accommodations.hotel.name === 'Other'\">{{ booking.accommodations.other }}</em>\n" +
    "      </td>\n" +
    "      <td style=\"text-align: center;\" ui-sref=\"day.bookings({ day: (booking.day | readTime: 'MM-DD-YY':true) })\">\n" +
    "        <a ng-if=\"booking.needs_pickup\" href=\"\">{{ booking.pickup.time | readTime: 'h:mm a':true }}</a>\n" +
    "        <a ng-if=\"!booking.needs_pickup\" href=\"\">-</a>\n" +
    "      </td>\n" +
    "      <td><span tooltip=\"Working on format\">{{ booking.phone | tel }}</span></td>\n" +
    "    </tr>\n" +
    "  </tbody>\n" +
    "</table>\n" +
    "");
}]);

angular.module("comp/whitelist/whitelist", []).run(["$templateCache", function($templateCache) {
  $templateCache.put("comp/whitelist/whitelist",
    "<div class=\"panel panel-default\">\n" +
    "  <div class=\"panel-heading\"><h4>Website Whitelist</h4></div>\n" +
    "  <div class=\"panel-body\"><form></form> <!-- adding this empty form is the only thing that makes the one below register ¯\\_(ツ)_/¯ -->\n" +
    "    <form name=\"form\">\n" +
    "      <ul class=\"list-unstyled\">\n" +
    "        <li ng-repeat=\"url in $ctrl.company.whitelist\" class=\"clearfix\" style=\"padding: 5px 5px 5px 12px; line-height: 30px;\">\n" +
    "          {{ url }}\n" +
    "          <button type=\"button\" class=\"btn btn-link pull-right\" ng-click=\"$ctrl.remove($index)\"><i class=\"fa fa-minus\"></i></button>\n" +
    "        </li>\n" +
    "        <li class=\"input-group\">\n" +
    "          <input type=\"url\" name=\"url\" ng-model=\"$ctrl.url\" class=\"form-control\" placeholder=\"https://domain.com\" />\n" +
    "          <span class=\"input-group-btn\">\n" +
    "            <button type=\"button\" class=\"btn btn-success\" ng-disabled=\"!$ctrl.url || form.$error.url\" ng-click=\"$ctrl.add()\">\n" +
    "              <i class=\"fa fa-plus\"></i>\n" +
    "            </button>\n" +
    "          </span>\n" +
    "        </li>\n" +
    "      </ul>\n" +
    "    </form>\n" +
    "  </div>\n" +
    "</div>");
}]);
