var ARReport = angular.module('RessieApp.components.reports.ar', []);

ARReport.controller('arReport', function ($scope, $stateParams, Reports, Bookings, ipCookie) {

  $scope.ready = false;

  var ACL = 'Bookkeeping Reports';
  $scope.allowed = $scope.user.can('a/r', ACL);

  var start = $scope.start = $scope.$parent.calendar.date.startDate;
  var end = $scope.end = $scope.$parent.calendar.date.endDate;

  // NOTE: Handle the report filter.
  $scope.filter = $scope.$parent.filter;
  $scope.filter.ar = $scope.filter.ar || { 'paid': 'all' };

  $scope.$watch('$parent.filter', function(n) {
    if(n) $scope.filter = n;
  });

  $scope.setFilter = function(value, filter){
    var reportCookie = ipCookie('reportFilter') || {};
    reportCookie[$scope.active] = reportCookie[$scope.active] || {};
    reportCookie[$scope.active][filter] = value;
    $scope.$parent.filter = reportCookie;
    ipCookie('reportFilter', reportCookie, { path: '/' });
  };

  $scope.showBooking = function(charge) {
    var filter = $scope.filter;
    if(filter.ar.paid === 'all' && charge.booking_status !== 'cancelled') return true;
    if(filter.ar.paid === 'paid' && charge.paid) return true;
    if(filter.ar.paid === 'unpaid' && !charge.paid && charge.booking_status !== 'cancelled') return true;
    if(filter.ar.paid === 'cancelled' && charge.booking_status === 'cancelled') return true;
  };

  $scope.$parent.isPrintable();

  (function(parent){
    parent.setPrintTitle('AR ' + start.format('MM-DD-YY') + ' thru ' + start.format('MM-DD-YY'))
  })($scope.$parent);

  $scope.arSort = function(row){
    var sort = $scope.sort;
    var booking = $scope.data[row.reference.model][row.reference._id];
    switch (sort) {
      case 'date':
        return moment.utc(booking.day);
        break;
      case 'conf':
        return booking.confirmation;
        break;
      case 'name':
        return booking.last_name;
        break;
      case 'amount':
        return row.amount;
        break;
      default:
        return moment.utc(booking.day);

    }
  }

  $scope.countGuests = function(charges) {
    return charges.reduce(function(prev, charge){
      if($scope.showBooking(charge)) {
        var booking = $scope.data[charge.reference.model][charge.reference._id];
        return prev + ((booking.adults || 0) + (booking.children || 0))
      } else {
        return prev + 0;
      }
    }, 0);
  };

  $scope.prep = function(){

    if($scope.allowed) {
      var due = [];
      var payments;
      Reports.sales(
        $stateParams.start,
        $stateParams.end,
        function Win(res){
          var data = angular.copy(res);
          $scope.data = data.constructor.name === 'Resource' ? data.toJSON() : data;
          $scope.days = angular.copy($scope.data);
          $scope.Prepayment = $scope.data.Prepayment;
          $scope.Reservation = $scope.data.Reservation;
          delete $scope.days.Prepayment;
          delete $scope.days.Reservation;
          angular.forEach($scope.Reservation, function(val){
            if(val.payment.other.length){
              payments = val.payment.other;
              angular.forEach(payments, function(bill_to){
                var partnerExcludes = ['Square POS', 'FareHarbor', 'FAREHARBOR', 'fareharbor', 'Fareharbor']
                if (partnerExcludes.filter(function(exclude) {
                  return (bill_to.partner || {}).name === exclude // Quick hack to let the team enter square sales as billto without them showing up in this report
                }).length) return false;
                if($scope.getPeriod(val, start, end).isInRange){
                  bill_to.reference = bill_to.reference || {};
                  bill_to.booking_status = val.status;
                  bill_to.reference.model = bill_to.reference.model || 'Reservation';
                  bill_to.reference._id = bill_to.reference._id || val._id;
                  bill_to.date = bill_to.date || val.day;
                  due.push(bill_to);
                }
              })
            }
          });

          $scope.bill_to_grouped = _.groupBy(due, function(payment){
            return payment.partner ? payment.partner._id : '';
          });

          $scope.totalAR = function(group, filter){
            var total = 0;
            angular.forEach(group, function(charge){
              // dont total up cancelled bookings unless on the cancelled tab
              if(filter !== 'cancelled' && charge.booking_status !== 'cancelled'){
                if(filter === 'all') total += parseFloat(charge.amount);
                if(filter === 'paid' && charge.paid) total += parseFloat(charge.amount);
                if(filter === 'unpaid' && !charge.paid) total += parseFloat(charge.amount);
              } else if(filter === 'cancelled' && charge.booking_status === 'cancelled') {
                total += parseFloat(charge.amount);
              }
            });
            return total;
          }

          $scope.bill_to_payments = due;
          $scope.$parent.isReady();

        }
      );
    } else {
      $scope.$parent.isReady();
    }

  }

})
