var RatingsController = angular.module('RessieApp.components.reports.ratings', []);

RatingsController.controller('ratingsReport', function ($scope, $stateParams, $modal, Events, $filter) {

  $scope.$parent.isPrintable();
  var company = $scope.user.activeCompany();

  (function(parent){
    var date = parent.calendar.date.startDate.format('MM-DD-YY');
    parent.setPrintTitle('Ratings between ' + date)
  })($scope.$parent);

  var start = $scope.start = $scope.$parent.calendar.date.startDate;
  var end = $scope.end = $scope.$parent.calendar.date.endDate;

  $scope.ratings = { 
    5: [],
    4: [],
    3: [],
    2: [],
    1: [],
    0: []
  };
  
  $scope.$watch('bookings', function(n, o){
    if(n){
      var count = 0;
      angular.forEach(n, function(booking){
        var ratings = getRating(booking);
        count += ratings.length
        var obj = {};
        _.merge(obj, _.groupBy(ratings, 'rating'));
        angular.forEach(obj, function(ratings, vote){
          $scope.ratings[vote] = $scope.ratings[vote].concat(ratings);
        })
      });
    }
  })

  $scope.data = {};

  $scope.sorter = function(arr){
    return -arr.$key
  };

  $scope.getEmails = function(rating) {
    return rating.reduce(function(prev, vote){
      return prev.concat(vote.receipt);
    }, [])
  }

  $scope.getAllEmails = function(){
    return Object.keys($scope.ratings).reduce(function(prev, group){
      return prev.concat($scope.ratings[group])
    }, []).map(function(vote){
      return vote.receipt
    });
  };

  $scope.averageRatings = function(){
    var voteTotal = 0;
    var count = 0;
    angular.forEach($scope.ratings, function(ratings){
      angular.forEach(ratings, function(rating){
        if(rating.rating !== 0){
          voteTotal += rating.rating;
          count++;
        }
      })
    });
    return parseFloat(voteTotal/count).toFixed(1);
  }

  $scope.totalVotes = function(){
    var count = 0;
    angular.forEach($scope.ratings, function(ratings){
      angular.forEach(ratings, function(rating){
        if(rating.rating !== 0) count++;
      })
    });
    return count;
  }

  function getRating(booking){
    var payments = booking.payment;
    var charges = payments.cards.reduce(function(prev, card){
      var ratings = card.charges.filter(function(charge){
        charge.receipt = charge.receipt || booking.receipt_email;
        return charge.rating >= 0;
      })
      return prev.concat(ratings);
    }, []);
    var gifts = payments.gifts.filter(function(gift){
      gift.receipt = gift.receipt || booking.receipt_email;
      return gift.rating >= 0
    });
    var billto = payments.other.filter(function(bill){
      bill.receipt = bill.receipt || booking.receipt_email;
      return bill.rating >= 0;
    });
    var ratings = [].concat(charges, gifts, billto);
    return ratings;
  }

  $scope.prep = function(){
    Events.range(start.clone().format('MM-DD-YY'), end.clone().format('MM-DD-YY'), function(response){
      var bookings = response.reduce(function(prev, day){
        return prev.concat(day.reservations);
      }, []);

      $scope.bookings = bookings.filter(function(booking){
        if(!booking) return false;
        var bookingDay = moment.utc(booking.day);
        var isOnOrAfterStart = bookingDay.isSameOrAfter(start, 'day');
        var isOnOrBeforeEnd = bookingDay.isSameOrBefore(end, 'day');
        return isOnOrAfterStart && isOnOrBeforeEnd;
      });

      $scope.$parent.isReady();
    });
  }

});