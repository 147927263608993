var BatchReport = angular.module('RessieApp.components.reports.batch', []);

BatchReport.controller('batchReport', function ($scope, $stateParams, Reports) {

  var ACL = 'Bookkeeping Reports';
  $scope.allowed = $scope.user.can('batch', ACL);

  var start = $scope.start = $scope.$parent.calendar.date.startDate;
  var end = $scope.end = $scope.$parent.calendar.date.endDate;

  $scope.$parent.isPrintable();

  (function(parent){
    parent.setPrintTitle('Batch Report for ' + start.format('MM-DD-YY') + ' thru ' + end.format('MM-DD-YY'))
  })($scope.$parent);


  // NOTE: Handle the report filter.
  $scope.filter = $scope.$parent.filter;
  $scope.$watch('$parent.filter', function(n) {
    $scope.filter = n;
  });

  $scope.updateBatchTable = function(res){
    $scope.batches.push(res);
  };

  $scope.prep = function(){
    if($scope.allowed) {
      var startDate, endDate;
      var startFormatted = start.format('MM-DD-YY');
      var endFormatted = end.format('MM-DD-YY');
      var todayFormatted = moment().format('MM-DD-YY');

      if($stateParams.end === '' && startFormatted == todayFormatted && endFormatted == todayFormatted) {
        endDate = moment.utc();
        startDate = undefined;
      } else {
        startDate = start || day;
        endDate = end;
      }

      $scope.range = true;
      $scope.locked = true;

      Reports.sales(
        startDate ? startDate.clone().format('MM-DD-YY') : undefined,
        endDate.clone().format('MM-DD-YY'),
        function Win(res){

          var data = angular.copy(res);

          $scope.data = data.constructor.name === 'Resource' ? data.toJSON() : data;
          $scope.batches = $scope.data.batches;
          $scope.locked = $scope.data.locked;
          delete $scope.data.locked;

          if(!startDate) {
            var firstDay = Object.keys(res).filter(function(key){
              return /^\d{1,2}\//.test(key);
            }).sort(-1)[0];

            if(!firstDay){
              firstDay = moment.utc($scope.batches[0].end).add(1, 'day').format('MM/DD/YYYY')
            }

            dontSkipDateWatch = false;
            day = moment.utc(firstDay, 'MM/DD/YYYY')
            end = endDate.clone();
            $scope.$parent.calendar.date = {
                startDate: day,
                endDate: end
            }
          }

          if($scope.data.Reservation !== undefined){
            $scope.data.is_batch = false;
            $scope.days = angular.copy($scope.data);
            $scope.Prepayment = $scope.data.Prepayment;
            $scope.Reservation = $scope.data.Reservation;
            delete $scope.days.Prepayment;
            delete $scope.days.Prepayment;
          } else {
            $scope.data.is_batch = true;
          }
          $scope.$parent.isReady();

        },
        function Err(err){
          alert(err.message);
        },
        true
      );
    } else {
      $scope.$parent.isReady();
    }

  }

})
