var RatingsController = angular.module('RessieApp.components.reports.birdeye', []);

RatingsController.controller('birdeyeReport', function ($scope, $stateParams, $modal, Events, $filter) {

  var start = $scope.start = $scope.$parent.calendar.date.startDate;
  var end = $scope.end = $scope.$parent.calendar.date.endDate;

  $scope.$parent.isPrintable();

  (function (parent) {
    var _start = start.format('MM-DD-YY');
    var _end = end.format('MM-DD-YY');
    if (_start === _end) {
      parent.setPrintTitle('BirdEye for: ' + _start);
    } else {
      parent.setPrintTitle('BirdEye for: ' + _start + ' thru ' + _end);
    }
  })($scope.$parent);

  $scope.prep = function () {
    Events.range(start.clone().format('MM-DD-YY'), end.clone().format('MM-DD-YY'), function (response) {
      var bookings = response.reduce(function (prev, day) {
        return prev.concat(day.reservations);
      }, []);

      $scope.bookings = bookings.filter(function (booking) {
        if (!booking) return false;
        var bookingDay = moment.utc(booking.day);
        var isOnOrAfterStart = bookingDay.isSameOrAfter(start, 'day');
        var isOnOrBeforeEnd = bookingDay.isSameOrBefore(end, 'day');
        return booking.status !== 'cancelled' && (isOnOrAfterStart && isOnOrBeforeEnd);
      });

      $scope.$parent.isReady();
    });
  }

});