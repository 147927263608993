var FilterCommissionsReport = angular.module('RessieApp.components.reports.filter_commissions', []);

FilterCommissionsReport.controller('commissionsFilter', function ($scope, $timeout) {

  $scope.calendar = {
    start: $scope.$parent.startDate.format('MM/DD/YYYY'),
    end: $scope.$parent.endDate
  };

  $scope.paymentTypes = {
    'cards': { name: 'Credit Cards', type: 'cards' },
    'ar': { name: 'A/R', type: 'ar' },
    'cash': { name: 'Cash', type: 'cash' },
    'gifts': { name: 'Prepayments', type: 'gifts' }
  };

  $scope.statusTypes = {
    'due': { name: 'Commission Due', type: 'due' },
    'nc': { name: 'Non Commissionable', type: 'nc' },
    'balance': { name: 'Balance Owed', type: 'balance' },
    'cancelled': { name: 'Booking Cancelled', type: 'cancelled' }
  };

  $scope.$watch('calendar.start', function(n, o) {
    if(o && n && n.constructor === Date) {
      $scope.calendar.start = moment(n).format('MM/DD/YYYY');
      $scope.$parent.commissionFilters.ready = true;
    }
  });

  $scope.$watch('calendar.end', function(n, o) {
    if(o && n && n.constructor === Date) {
      $scope.calendar.end = moment(n).format('MM/DD/YYYY');
      $scope.$parent.commissionFilters.ready = true;
    }
  });

  $scope.filterPayment = function(item) {
    var filters = $scope.$parent.commissionFilters.selected;
    if(filters.payments.indexOf(item.type) === -1) {
      filters.payments.push(item.type);
    }
    $scope.$parent.commissionFilters.payments = undefined;
  }

  $scope.filterStatus = function(item) {
    var filters = $scope.$parent.commissionFilters.selected;
    if(filters.status.indexOf(item.type) === -1) {
      filters.status.push(item.type);
    }
    $scope.$parent.commissionFilters.status = undefined;
  }

  $scope.unfilterHotel = function(index) {
    var selected = $scope.$parent.commissionFilters.selected;
    var hotelsList = selected.hotels;
    var id = hotelsList[index];
    hotelsList.splice(index, 1);
    var conciergeList = selected.concierges;
    var hotel = $scope.$parent.data.hotels[id];
    var concierges = hotel.concierges;
    angular.forEach(concierges, function(concierge){
      delete $scope.$parent.commissionFilters.concierges[concierge._id];
      var index = conciergeList.indexOf(concierge._id);
      if(index >= 0) {
        conciergeList.splice(index, 1);
      }
    });
  }

  $scope.$watch('showAll', function(n, o){
    $scope.$parent.showAll = n;
  })

  $scope.unfilterConcierge = function(index) {
    var selected = $scope.$parent.commissionFilters.selected;
    var conciergeList = selected.concierges;
    if(index === undefined){
      var id = $scope.$parent.conciergeFilter;
      index = conciergeList.indexOf(id);
      $scope.$parent.conciergeFilter = undefined;
    }
    if(index >= 0) conciergeList.splice(index, 1);
  }

  $scope.unfilterPayment = function(index) {
    var selected = $scope.$parent.commissionFilters.selected;
    var paymentsList = selected.payments;
    paymentsList.splice(index, 1);
  }

  $scope.unfilterStatus = function(index) {
    var selected = $scope.$parent.commissionFilters.selected;
    var statusList = selected.status;
    statusList.splice(index, 1);
  }

  $scope.hasConcierges = function(item) {
    return Object.keys(item.concierges).length;
  }

  $scope.getConcierges = function() {
    return $scope.$parent.commissionFilters.concierges;
  }

  $scope.disableOption = function(id) {
    var selectedHotel = $scope.$parent.commissionFilters.selected.hotels.indexOf(id) !== -1;
    var selectedConcierge = $scope.$parent.commissionFilters.selected.concierges.indexOf(id) !== -1;
    return selectedHotel || selectedConcierge;
  };


});
