var PickupsReport = angular.module('RessieApp.components.reports.pickups', []);

PickupsReport.controller('pickupsReport', function ($scope, $stateParams, Pickups) {

  if($stateParams.start === '') {
    if(moment().hours() > 9) {
      var tomorrow = moment().add(1, 'day')
      $scope.$parent.calendar.date.startDate = tomorrow;
      $scope.$parent.calendar.date.endDate = tomorrow;
    } else {
      var today = moment();
      $scope.$parent.calendar.date.startDate = today;
      $scope.$parent.calendar.date.endDate = today;
    }
  }

  var start = $scope.start = $scope.$parent.calendar.date.startDate;
  var end = $scope.end = $scope.$parent.calendar.date.endDate;

  $scope.$parent.isSingleDay();
  $scope.$parent.isPrintable();

  (function(parent){
    var date = start.format('MM-DD-YY');
    parent.setPrintTitle('Pickups for ' + date)
  })($scope.$parent);

  $scope.assignedBookings = [];

  $scope.addToAssigned = function(id){
    if(_.indexOf($scope.assignedBookings, id) == -1){
      $scope.assignedBookings.push(id);
    }
  };

  $scope.isAssigned = function(id){
    return _.indexOf($scope.assignedBookings, id) >= 0;
  };

  $scope.prep = function(){
    Pickups.forDay(start.format('MM-DD-YYYY'), function(response){
      $scope.data = response;
      $scope.$parent.isReady();
    });
  }

})
