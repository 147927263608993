var PackageComponent = angular.module('RessieApp.components.package', [
  'RessieApp.components.package.widget'
]);

PackageComponent.component('flightPackage', {
  templateUrl: 'comp/package/package',
  bindings: {
    package: '<'
  },
  controller: function ($scope, Companies, $location, Contacts, Bundles) {
    var _this = this;
    this.$onInit = $onInit.bind(this, $location);
    this.$postLink = $postLink.bind(this, $location);
    this.link = this.url;

    (function(){
      Contacts.byType('hotel', function(hotels){
        console.log(hotels.contacts)
        _this.hotels_list = hotels.contacts;
      });

      Bundles.list(function(bundles){
        _this.bundles_list = bundles;
      })
    })()

    function updateURL() {
      var helper = updatePath.call(_this);
      var header = _this.builder.header !== '' ? encodeURIComponent(_this.builder.header) : 'none';
      var link = _this.url + '&' + helper.defaults + '&header=' + header;
      if(helper.locks && helper.locks !== '') {
        link += '&locked=' + helper.locks;
      }
      _this.link = link;
    }

    $scope.$watch('$ctrl.builder.header', function(n, o) {
      if(n) updateURL();
    })
    
    $scope.$watch('$ctrl.builder.data', function(n, o) {
      if(n) updateURL();
    }, true)

    $scope.$watch('$ctrl.builder.locks', function(n, o) {
      if(n) updateURL();
    }, true);

    $scope.$watch('$ctrl.selected_hotel', function(n, o) {
      if(n) {
        _this.builder.data.hotel_id = n._id;
      }
    })

    $scope.$watch('$ctrl.selected_bundle', function(n, o) {
      if(n) {
        _this.builder.data.bundle = n.code;
      }
    })

  }
});

function updatePath() {
  var _this = this;
  var defaults = Object.keys(this.builder.data).map(function(key) {
    return key + '=' + _this.builder.data[key];
  }).join('&');

  var locks = Object.keys(this.builder.locks).filter(function(key){
    return _this.builder.locks[key];
  }).map(function(key) {
    return key;
  }).join(',');

  return { defaults: defaults, locks: locks };
}

var $onInit = function(loc) {
  var pkg = this.package;
  this.url = loc.$$absUrl.replace(loc.$$path, '') + '/p/book/' + this.package._id + '?version=v4&external&id=' + this.package.company;
  this.builder = { header: 'Book a ' + pkg.name + ' flight' };
  this.builder.data = {
    adults: 0
  };
  this.builder.locks = {
    children: pkg.adults
  };
}

var $postLink = function() {}

var WidgetCopy = angular.module('RessieApp.components.package.widget', []);

WidgetCopy.component('widget', {
  templateUrl: 'comp/package/widget',
  bindings: {
    ngModel: '='
  },
  controller: function () {

    console.log(this)

    this.code = {
      popup: '<rezflow-popup package="' + this.data._id + '" header="Book a ' + this.data.name + ' Flight">\n' + 
             '  <button type="button"> Book Now</button>\n' +
             '</rezflow-popup>'
    }

  }
});
