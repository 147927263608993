var WhitelistComponent = angular.module('RessieApp.components.whitelist', []);

WhitelistComponent.component('whiteList', {
  templateUrl: 'comp/whitelist/whitelist',
  bindings: {
    company: '='
  },
  controller: function (Companies){
    this.add = function(){
      this.company.whitelist.push(this.url);
      this.url = "";
      this.update();
    }
    this.remove = function(i) {
      this.company.whitelist.splice(i, 1);
      this.update();
    }
    this.update = function() {
      Companies.update({ _id: this.company._id, whitelist: this.company.whitelist }, function(res) {
        console.log(res);
      })
    }
  }
});
