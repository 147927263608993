var ReportsComponents = angular.module('RessieApp.components.reports', [
  'RessieApp.components.reports.ar',
  'RessieApp.components.reports.batch',
  'RessieApp.components.reports.merchandise',
  'RessieApp.components.reports.sales',
  'RessieApp.components.reports.deposits',
  'RessieApp.components.reports.discrepancies',
  'RessieApp.components.reports.tips',
  'RessieApp.components.reports.pilot-commissions',
  'RessieApp.components.reports.commissions',
  'RessieApp.components.reports.filter_commissions',
  'RessieApp.components.reports.bookedon',
  'RessieApp.components.reports.sources',
  'RessieApp.components.reports.packages',
  'RessieApp.components.reports.glance',
  'RessieApp.components.reports.manifest',
  'RessieApp.components.reports.itsheet',
  'RessieApp.components.reports.pickups',
  'RessieApp.components.reports.salesslip',
  'RessieApp.components.reports.photos',
  'RessieApp.components.reports.gifts',
  'RessieApp.components.reports.ratings',
  'RessieApp.components.reports.birdeye',
  'RessieApp.components.reports.waivers'
]);

ReportsComponents.controller('ReportHome', function ($scope, $rootScope, $stateParams, $state, $location, allowed, Events, ipCookie, $timeout, Reports, Bookings, $transitions) {

  var vm = this;

  $transitions.onSuccess({
    to: function (state) {
      return /^reports/.test(state.name);
    }
  }, function(trans){
    var to = trans.$to();
    vm.setActive(to.report || to.self.params && to.self.params.report);
  })

  vm.setActive = function(report) {
    $scope.active = report;
    $scope.activeReport = (function (active) {
      var path = undefined;
      var activeReport = $scope.reports.filter(function (report) {
        var paths = report.paths.filter(function (route) {
          return route.route === $scope.active;
        });
        if (paths.length) path = paths[0];
      });
      if (path) return path;
    })(report)
  }

  $scope.ready = false;
  $scope.singleDay = false;
  $scope.printable = false;
  $scope.print_title = undefined;

  $scope.isSingleDay = function(){
    $scope.singleDay = true;
  };

  $scope.isReady = function(){
    $scope.ready = true;
  };

  $scope.setPrintTitle = function(title) {
    $scope.print_title = title;
  };

  $scope.isPrintable = function() {
    $scope.printable = true;
  };

  $scope.reports = [
    {
      group: 'Daily Reports',
      alias: 'daily',
      paths: [
        {
          route: 'manifest',
          rule: 'it-sheet',
          label: 'Pilot Manifest'
        },
        {
          route: 'it-sheet',
          rule: 'it-sheet',
          label: 'IT Sheet'
        },
        {
          route: 'at-a-glance',
          rule: 'at-a-glance',
          label: 'At-A-Glance'
        },
        {
          route: 'waivers',
          rule: 'waivers',
          label: 'Waivers'
        },
        {
          route: 'pickups',
          rule: 'pickups',
          label: 'Hotel Pickup'
        },
        {
          route: 'sales-slip',
          rule: 'sales-slip',
          label: 'Sales Slip'
        },
        {
          route: 'photos',
          rule: 'photos',
          label: 'Photo Emails'
        }
      ]
    },
    {
      group: 'Bookkeeping Reports',
      alias: 'bookkeeping',
      paths: [
        {
          route: 'sales',
          rule: 'sales',
          label: 'Sales'
        },
        {
          route: 'deposits',
          rule: 'sales',
          label: 'Deposits'
        },
        {
          route: 'discrepancies',
          rule: 'sales',
          label: 'Discrepancy'
        },
        {
          route: 'batch',
          rule: 'batch',
          label: 'Batch'
        },
        {
          route: 'merchandise',
          rule: 'mdse',
          label: 'Merchandise'
        },
        {
          route: 'ar',
          rule: 'a/r',
          label: 'A/R'
        },
        {
          route: 'tips',
          rule: 'tips',
          label: 'Tips'
        },
        {
          route: 'pilot-commissions',
          rule: 'commissions',
          label: 'Pilot Commissions'
        },
        {
          route: 'commissions',
          rule: 'commissions',
          label: 'Commissions'
        },
        {
          route: 'gifts',
          rule: 'gifts',
          label: 'Gifts'
        }
      ]
    },
    {
      group: 'Marketing/Sales Reports',
      alias: 'sales',
      paths: [
        {
          route: 'bookedon',
          rule: 'bookedon',
          label: 'New Bookings'
        },
        {
          route: 'booking-source',
          rule: 'booking-source',
          label: 'Booking Source'
        },
        {
          route: 'by-package',
          rule: 'package',
          label: 'By Package'
        },
        {
          route: 'area-code',
          rule: 'area-code',
          label: 'Demographics: Area Code'
        }
      ]
    },
    {
      group: 'Quality Assurance',
      alias: 'qa',
      paths: [
        {
          route: 'ratings',
          rule: 'ratings',
          label: 'Guest Ratings'
        },
        {
          route: 'birdeye',
          rule: 'birdeye',
          label: 'BirdEye'
        }
      ]
    }
  ];

  vm.setActive($stateParams.report || 'home');

  $scope.groupName = function(name) {
    return name.replace(/\d-(.*)/, "$1")
  }

  var is_allowed = $scope.reports.reduce(function(array, group){
    return array.concat(group.paths.map(function(path){
      return path.route;
    }));
  }, ['home']);

  $scope.all_reports = $scope.reports.reduce(function(obj, group){
    return angular.extend(obj, $scope.reports[group])
  }, {});

  var allowedEndpoint = is_allowed.indexOf($stateParams.report) >= 0;

  $scope.startDate = $stateParams.start !== '' ? moment($stateParams.start, 'MM-DD-YY').hours(0).minutes(0).seconds(0) : moment().hours(0).minutes(0).seconds(0);

  $scope.endDate = $stateParams.end !== '' ? moment($stateParams.end, 'MM-DD-YY').hours(0).minutes(0).seconds(0) : moment().hours(0).minutes(0).seconds(0);

  if(!allowedEndpoint) {
    $state.go('reports', { report: '' })
  }

  $scope.$watch('calendar.date', function(n, o) {
    if(n) {
      if(n.startDate !== o.startDate || n.endDate !== o.endDate) {
        var from = moment(n.startDate).format('MM-DD-YY');
        var to = moment(n.endDate).format('MM-DD-YY');
        var test = $stateParams;
        $location.path('/reports/'+ ($stateParams.report || 'home') + '/' + from + '/' + to, false);
      }
    }
  }, true);

  $scope.getPeriod = function(item, start, end){
    var zeroed = moment.utc(item.date || item.day).hours(0).minutes(0).seconds(0)
    if(zeroed.isUTC()) {
      if(!start.isUTC()) start = moment.utc(start);
      if(!end.isUTC()) end = moment.utc(end);
    }
    return {
      isBefore: zeroed.isBefore(start, 'day'),
      isAfter: zeroed.isAfter(end, 'day'),
      isInRange: !zeroed.isBefore(start, 'day') && !zeroed.isAfter(end, 'day')
    }
  };

  $scope.filter = ipCookie('reportFilter') || {
    'billTo': {
      'paid': 'all'
    }
  };

  $scope.clearSales = function(){
    Reports.clearSales(
      $stateParams.start,
      $stateParams.end,
      function win(res){
        if(res.success){
          alert(res.removed + ' cached records were cleared. Reloading report.');
          location.reload();
        }
      },
      function err(err){
        alert(err.message)
      }
    )
  },

  $scope.updateBillTo = function(item){
    Bookings.updateBillTo(item, function Win(res){

    })
  };

  $scope.calendar = {
    date: {
      startDate: $scope.startDate,
      endDate: $scope.endDate
    },
    options: {
      showDropdowns: true,
      locale: {
        format: 'MM/DD/YYYY',
        separator: ' thru '
      },
      singleDatePicker: false,
      ranges: {
        'Today': [
          moment(),
          moment()
        ],
        'Yesterday': [
          moment().subtract(1, 'days'),
          moment().subtract(1, 'days')
        ],
        'Last 7 Days': [
          moment().subtract(6, 'days'),
          moment()
        ],
        'Last 30 Days': [
          moment().subtract(29, 'days'),
          moment()
        ],
        'This Month': [
          moment().startOf('month'),
          moment().endOf('month')
        ],
        'Last Month': [
          moment().subtract(1, 'month').startOf('month'),
          moment().subtract(1, 'month').endOf('month')
        ]
      }
    }
  };

})
