var PickupsReport = angular.module('RessieApp.components.reports.waivers', []);

PickupsReport.controller('waiversReport', function ($scope, $rootScope, $stateParams, Events) {

  if($stateParams.start === '') {
    if(moment().hours() > 9) {
      var tomorrow = moment().add(1, 'day')
      $scope.$parent.calendar.date.startDate = tomorrow;
      $scope.$parent.calendar.date.endDate = tomorrow;
    } else {
      var today = moment();
      $scope.$parent.calendar.date.startDate = today;
      $scope.$parent.calendar.date.endDate = today;
    }
  }

  var start = $scope.start = $scope.$parent.calendar.date.startDate;
  var end = $scope.end = $scope.$parent.calendar.date.endDate;

  $scope.$parent.isSingleDay();
  $scope.$parent.isPrintable();

  (function(parent){
    var date = start.format('MM-DD-YY');
    parent.setPrintTitle('Waivers for ' + date)
  })($scope.$parent);

  $scope.signatures = function (booking) {
    var count = 0;
    var waivers = booking.smartWaiver;
    angular.forEach(waivers, function(waiver, i) {
      var data = waiver.data;
      count += data.participants.reduce(function(prev, cur) {
        return prev + 1;
      }, 0)
    })
    return count;
  }

  $scope.count = function (booking) {
    return booking.adults + (booking.children || 0);
  };

  $scope.prep = function () {
    Events.range(start.clone().format('MM-DD-YY'), end.clone().format('MM-DD-YY'), function (response) {
      var bookings = response.reduce(function (prev, day) {
        return prev.concat(day.reservations);
      }, []);

      $scope.bookings = bookings.filter(function (booking) {
        if (!booking) return false;
        var bookingDay = moment.utc(booking.day);
        var isOnOrAfterStart = bookingDay.isSameOrAfter(start, 'day');
        var isOnOrBeforeEnd = bookingDay.isSameOrBefore(end, 'day');
        return booking.status === 'active' && (isOnOrAfterStart && isOnOrBeforeEnd);
      });

      $scope.$parent.isReady();
    });
  }

})
