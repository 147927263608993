var CommissionsReport = angular.module('RessieApp.components.reports.commissions', []);

CommissionsReport.controller('commissionsReport', function ($scope, $stateParams, Reports, ipCookie, $modal, $timeout) {

  $scope.commissionFilters = {
    show: false,
    selected: {
      hotels: [],
      concierges: [],
      payments: [],
      status: []
    },
    concierges: {}
  };

  $scope.$watch('commissionFilters.selected.hotels', function(n, o){
    if(n && !$scope.commissionFilters.show) {
      $scope.hotelFilter = n[0];
    } else {
      $scope.hotelFilter = undefined;
    }
  }, true);

  $scope.$watch('commissionFilters.show', function(n, o){
    if(n === true) {
      $scope.hotelFilter = undefined;
    }
  })

  var ACL = 'Bookkeeping Reports';
  $scope.allowed = $scope.user.can('commissions', ACL);

  var start = $scope.start = $scope.$parent.calendar.date.startDate;
  var end = $scope.end = $scope.$parent.calendar.date.endDate;

  $scope.$parent.isPrintable();

  (function(parent){
    parent.setPrintTitle('Commissions for ' + start.format('MM-DD-YY') + ' thru ' + end.format('MM-DD-YY'));
  })($scope.$parent);

  // NOTE: Handle the report filter.
  $scope.filter = $scope.$parent.filter;

  $scope.$watch('$parent.filter', function(n) {
    $scope.filter = n;
  });

  $scope.filter.type = $scope.filter.type || ipCookie('reportFilter') ? ipCookie('reportFilter').type : false || 'hotels';
  $scope.filter.filter = $scope.filter.filter || 'all';

  $scope.$watch('filter.type', function(n, o){
    if(n) {
      var reportCookie = $scope.filter;
      reportCookie.type = n;
      reportCookie[$scope.active] = reportCookie[$scope.active] || {};
      ipCookie('reportFilter', reportCookie, { path: '/' });
      $scope.children = (n === 'hotels');
    }
  });

  $scope.pushSelect = function(item) {
    var selected = $scope.commissionFilters.selected;
    if(item.concierges !== undefined) {
      angular.forEach(item.concierges, function(concierge) {
        $scope.commissionFilters.concierges[concierge._id] = concierge;
      });
      selected.hotels.push(item._id);
      $scope.commissionFilters.hotel = undefined;
    } else {
      selected.concierges.push(item._id);
      item.show_details = true;
      $scope.commissionFilters.concierge = undefined;
    }
  };

  $scope.selectedPayments = {};

  $scope.filterTo = function(recipient){
    if(recipient.type && recipient.type === 'concierges') {
      $scope.commissionFilters.selected.hotels = [];
      $scope.commissionFilters.selected.concierges = [];
      $scope.pushSelect($scope.data.hotels[recipient.parent]);
      $scope.pushSelect(recipient);
      $scope.conciergeFilter = recipient._id;
    }
  }

  $scope.changePaymentCheck = function(booking){
    var checked = booking.commission_paid;
    var recipient = $scope.getRecipient(booking);
    $scope.selectedPayments[recipient._id] = $scope.selectedPayments[recipient._id] || {};
    if(checked) {
      $scope.selectedPayments[recipient._id][booking._id] = {
        booking: booking,
        commission: { amount: $scope.commission(recipient, booking) }
      };
    } else {
      delete $scope.selectedPayments[recipient._id][booking._id];
    }
  };

  $scope.openPayment = function(recipient, booking){

    var payment = booking ? $scope.getCommissionPayment(booking) : null;

    if(payment) {
      if(payment.hold_reason) {
        payment.on_hold = true;
      } 
      payment.not_commissionable = !payment.commissionable;
    }

    function Open(){
      var modalInstance = $modal.open({
        templateUrl: 'comp/reports/commissions/pay_modal',
        controller: 'commissionsReportModal',
        resolve: {
          type: function(){
            return $scope.filter.type
          },
          commissions: function(){
            return $scope.selectedPayments[recipient._id];
          },
          recipient: function(){
            return recipient;
          },
          payment: function(){
            return payment;
          }
        }
      });

      modalInstance.result.then(function (obj) {
        if(obj) {
          $scope.selectedPayments = [];
          var recipientBookings = $scope.data.grouped[obj.recipient._id];
          angular.forEach(obj.commissions, function(commission, id){
            commission.booking.meta.commission = commission.booking.meta.commission || [];
            // added commissoin, update view

            if(commission && commission.commission._id) {
              if(commission.commission.contact) commission.commission.contact = recipient;
              if(commission.commission.employee) commission.commission.employee = recipient;
              commission.booking.meta.commission.push(commission.commission);
            } else {
            // removed commission, clear payment
              var index = -1;
              commission.booking.meta.commission.filter(function(comm, i){
                var recipient_id = (comm.contact ? (comm.contact._id || comm.contact) : (comm.employee._id || comm.employee));
                var matched = recipient_id === recipient._id;
                if(matched) index = i;
                return true;
              });
              if(index !== -1) commission.booking.meta.commission.splice(index, 1);
            }
          });
        }
      });
    }

    if(booking !== undefined && payment !== undefined) {
      booking.commission_paid = true;
      payment.edit = true;
      payment.date = moment(payment.date).format('MM/DD/YYYY');
      $scope.changePaymentCheck(booking);
      $timeout(Open, 500);
    } else {
      payment = false;
      Open();
    }

  }

  $scope.filterByPayment = function(item) {
    var paymentFilter = $scope.commissionFilters.selected.payments;
    if(paymentFilter.length && item.cart !== undefined) {
      var payments = $scope.getPaymentSource(item);
      delete payments.count;
      var filteredCount = 0;
      angular.forEach(payments, function(arr, key){
        if(paymentFilter.indexOf(key) !== -1) filteredCount += payments[key].length;
      });
      return filteredCount;
    }
    return true;
  };

  $scope.getRecipient = function(item) {
    var recipient = item.accommodations ? item.accommodations.concierge : null;
    switch ($scope.filter.type) {
      case 'employees':
        recipient = item.trace !== undefined ? item.trace[0].user : null;
        break;
      case 'partners':
        recipient = item.agent;
        break;
      default:
        recipient = item.accommodations ? item.accommodations.concierge : undefined;
    }
    return recipient;
  }

  $scope.getCommissionPayment = function(item) {
    var recipient = $scope.getRecipient(item);

    var recipientCommissionEntry = item.meta.commission && item.meta.commission.filter(function(commission){
      if(commission.contact || commission.employee) {
        var recip_id = commission.employee ? (commission.employee._id || commission.employee) : (commission.contact._id || commission.contact);
        return recipient._id === recip_id;
      } else {
        return false;
      }
    });


    if(recipientCommissionEntry && recipientCommissionEntry.length) {
      return recipientCommissionEntry[0];
    }

    return false;

  }

  $scope.filterByStatus = function(item) {
    var statusFilter = $scope.commissionFilters.selected.status;
    if(statusFilter.length && item.cart !== undefined) {
      var passCheck = [];
      var balanceDue = $scope.balanceDue(item);
      var recipient = $scope.getRecipient(item);
      var holdCommission = (recipient.meta && recipient.meta.hold_commissions);
      var cancelled = item.status !== undefined && item.status === 'cancelled';

      var recipCommission = $scope.getCommissionPayment(item);

      var commissionDue = $scope.commission(recipient, item, false);

      if(statusFilter.indexOf('balance') !== -1 && balanceDue > 0) {
        passCheck.push('balance due');
      }

      if(statusFilter.indexOf('due') !== -1 && !cancelled && commissionDue > 0) {
        passCheck.push('commission due');
      }

      if(statusFilter.indexOf('cancelled') !== -1 && cancelled) {
        passCheck.push('cancelled');
      }

      if(statusFilter.indexOf('nc') !== -1){
        var not_commissionable = recipCommission && !recipCommission.commissionable;
        if(cancelled || holdCommission || not_commissionable) {
          passCheck.push('not commissionable');
        }
      }

      return passCheck.length;
    }
    return true;
  };

  // Filters a concierge list by the status specified.
  $scope.conciergeStatusTypes = function(item) {
    var conciergeBookings = $scope.data.grouped[item._id];
    var statusFilterCount = conciergeBookings.reduce(function(prev, booking){
      var counted = $scope.filterByStatus(booking);
      return prev + (counted === true ? 1 : counted);
    }, 0);
    return statusFilterCount;
  };

  // Filters a concierge list by the payment type specified.
  $scope.conciergePaymentTypes = function(item) {
    var conciergeBookings = $scope.data.grouped[item._id];
    var paymentFilterCount = conciergeBookings.reduce(function(prev, booking){
      var counted = $scope.filterByPayment(booking);
      return prev + counted === true ? 1 : counted;
    }, 0);
    return paymentFilterCount;
  };

  $scope.filterBookings = function(item) {
    if(item.confirmation !== undefined) {
      var payment = $scope.getCommissionPayment(item);
      var passesPaymentFilter = $scope.filterByPayment(item);
      var passesStatusFilter = $scope.filterByStatus(item);

      var passesAll = passesPaymentFilter && passesStatusFilter;

      var paidOnly = $scope.commissionFilters.selected.paid;
      var unpaidOnly = $scope.commissionFilters.selected.unpaid;

      var passesPaidFilter = (payment !== undefined && payment.amount !== 0);
      var passesUnpaidFilter = (payment === undefined);

      if(paidOnly && unpaidOnly) return passesAll;

      if(paidOnly) return passesPaidFilter && passesAll;
      if(unpaidOnly) return passesUnpaidFilter && passesAll;

      return passesAll;
    } else {
      return true;
    }
  };

  // TODO: Clean up
  // Does the heavy lifting of the frontend report filtering
  $scope.filterReport = function(item){
    var recipient = item.constructor.name === 'Recipient' ? item : $scope.getRecipient(item);
    if(recipient && (!recipient.meta || (recipient.meta && !recipient.meta.commissions))) return false;
    var conciergeFilter = $scope.commissionFilters.selected.concierges;
    var paymentFilter = $scope.commissionFilters.selected.payments;
    var statusFilter = $scope.commissionFilters.selected.status;
    var hotelFilter = $scope.commissionFilters.selected.hotels;
    // Filters hotels by payment type specified
    // If any concierge under a hotel has bookings that meet the payment filter criteria we show
    // the hotel.
    function hotelPaymentTypes(item) {
      var count = 0;
      angular.forEach(item.concierges, function(concierge){
        var conciergePassesPaymentCheck = $scope.conciergePaymentTypes(concierge);
        count += conciergePassesPaymentCheck;
      });
      return count;
    }

    // Filters hotels by status type specified
    // If any concierge under a hotel has bookings that meet the status filter criteria
    // we show the hotel.
    function hotelStatusTypes(item) {
      var count = 0;
      angular.forEach(item.concierges, function(concierge){
        var conciergePassesStatusCheck = $scope.conciergeStatusTypes(concierge);
        count += conciergePassesStatusCheck;
      });
      return count;
    }

    // filtering by hotel
    if(item.type === 'hotels') {
      return (function(){
        var passesStatusCheck = statusFilter.length === 0 || hotelStatusTypes(item) > 0;
        var passesPaymentCheck = paymentFilter.length === 0 || hotelPaymentTypes(item) > 0;
        var hotelFilterPasses = hotelFilter.length === 0 || hotelFilter.indexOf(item._id) !== -1;
        if(hotelFilter.length) return hotelFilterPasses && (passesStatusCheck && passesPaymentCheck);
        return passesStatusCheck && passesPaymentCheck;
      })();
    }

    if(item.type === 'concierges') {
      // here we need to exclude any concierge in the hotel that ISN'T in the selected list
      if(conciergeFilter.length || paymentFilter.length || statusFilter.length) {
        var passesPaymentFilter = $scope.conciergePaymentTypes(item) > 0;
        var passesConciergeFilter = !conciergeFilter.length || conciergeFilter.indexOf(item._id) !== -1;
        var passesStatusFilter = $scope.conciergeStatusTypes(item) > 0
        return passesConciergeFilter && passesPaymentFilter && passesStatusFilter;
      }
      return true;
    }

    return $scope.filterBookings(item);

  }

  $scope.commissionable = function(booking) {

    var recipient = $scope.getRecipient(booking);
    var recieves_commissions = recipient.meta && !recipient.meta.hold_commissions;

    var cart = booking.cart;
    var package = cart.package;
    var isVIPPackage = package.vip;
    var isNotVIPPackage = !isVIPPackage ? parseFloat(package.adults.total) + parseFloat(package.children.total) : 0;

    var vipPrice = $scope.vipPrice(booking)

    if (booking.confirmation === 34390) console.log(vipPrice)

    return booking.status !== 'cancelled' && recieves_commissions && parseFloat(cart.balance) <= 0 ? (isVIPPackage ? parseFloat(vipPrice.price) : isNotVIPPackage) : 0;
  };

  $scope.vipPrice = function(booking) {
    return ((booking.package || {}).vipPricing || []).find(function (vip) {
      var count = (booking.adults + booking.children || 0);
      return vip.min <= count && vip.max >= count;
    });
  }

  $scope.commission = function(recipient, booking, all) {
    if(all === undefined) all = true;
    var payment = $scope.getCommissionPayment(booking);
    var commissionable = $scope.commissionable(booking);
    if(!recipient || recipient.meta === undefined || recipient.meta.commission_amount === undefined) return 0;
    var commission_amount = recipient.meta.commission_amount;
    var commission_match = recipient.meta.commission_amount && commission_amount.toString().match(/($)?((\d+)(.\d+)?)(.*?)(%)?/);
    if(commission_match) {
      var commission = commission_match[2];
      var percent = commission_match[6] ? true : false;
      if(all || commissionable) {
        var guestcount = (booking.adults + (booking.children || 0));
        // dont want to add up paid commissions into "due"
        if(!all && payment) return 0;
        if(all && payment) return payment.amount;
        return percent ? commissionable * (parseFloat(commission)/100) : parseFloat(commission) * guestcount;
      } else {
        return 0;
      }
    } else {
      return 0;
    }
  }

  $scope.totalCommission = function(recipient, bookings, all) {
    if(all === undefined) all = true;
    if(recipient) {
      return bookings.reduce(function(prev, booking) {
        return $scope.filterBookings(booking) ? prev + $scope.commission(recipient, booking, all) : prev;
      }, 0);
    }
  }

  $scope.totalDue = function(recipients) {
    var total = 0;
    angular.forEach(recipients, function(recipient) {
      var bookings = $scope.data.grouped[recipient._id];
      total += $scope.totalCommission(recipient, bookings, false);
    });
    return total;
  }

  $scope.balanceDue = function(booking) {
    return parseFloat(booking.cart.balance);
  }

  $scope.getPaymentSource = function(booking) {
    var payments = booking.cart.payments;
    var cards = payments.cards.filter(function(card){
      return card.type !== 'tip' && parseFloat(card.total) > 0;
    });
    var ar = payments.bill_to;
    var cash = payments.cash.filter(function(cash){
      return parseFloat(cash.total) > 0;
    });
    var gifts = payments.prepayments;
    return {
      count: cards.length + ar.length + cash.length + gifts.length,
      cards: cards,
      ar: ar,
      cash: cash,
      gifts: gifts
    }
  };

  $scope.bookingRange = function(bookings) {

    var dated = angular.copy(bookings).filter(function(booking){
      return $scope.filterBookings(booking) > 0;
    });

    if(dated && dated.length) {

      var sorted = dated.sort(function(a, b){
        return moment.utc(a.day) - moment.utc(b.day);
      });

      var first = sorted[0]
      var last = sorted[sorted.length - 1];

      return {
        first: first.day,
        last: last.day
      }
    }

  };

  $scope.totalGuests = function(bookings) {
    var total = 0;
    angular.forEach(bookings, function(booking){
      total += $scope.filterBookings(booking) > 0 ? (booking.adults + (booking.children || 0)) : 0;
    });
    return total;
  }

  function CommissionBooking(obj){
    angular.extend(this, obj);
    return this;
  }

  function Recipient(obj){
    angular.extend(this, obj);
    return this;
  }

  Recipient.prototype.show = function(override) {
    if(!override && this.show_details) return true;
    if(override) return true;
    return false;
  }

  function Hotel(obj){
    angular.extend(this, obj);
    return this;
  }

  $scope.prep = function(start, end){

    $scope.filter = angular.extend($scope.filter, {
      type: $scope.filter.type || 'hotels'
    });

    var type = $scope.filter.type;

    function PrepHotels(hotels){
      angular.forEach(hotels, function(hotel, i){
        $scope.data.hotels[i] = new Hotel(hotel);
        angular.forEach(hotel.concierges, function(recipient, id){
          $scope.data[type][i].concierges[id] = new Recipient(recipient);
        });
      })
    }

    function PrepRecipients(recipients){
      angular.forEach(recipients, function(recipient, i){
        $scope.data[type][i] = new Recipient(recipient);
      })
    }

    function PrepBookings(bookings){
      angular.forEach(bookings, function(bookings, recipient_id){
        if(type === 'hotels') PrepHotels($scope.data[type]);
        if(type !== 'hotels') PrepRecipients($scope.data[type]);
        angular.forEach(bookings, function(booking, i){
          $scope.data.grouped[recipient_id][i] = new CommissionBooking(booking);
        })
      })
    }

    if($scope.allowed) {
      $scope.working = true;
      Reports.commissions(
        start || $stateParams.start,
        end || $stateParams.end,
        $scope.filter,
        function Win(res){
          $scope.working = false;
          $scope.active = 'commissions';
          var data = angular.copy(res);
          $scope.data = data;
          PrepBookings($scope.data.grouped);
          $scope.$parent.isReady();
        }
      );
    } else {
      $scope.$parent.isReady();
    }
  }

})

CommissionsReport.controller('commissionsReportModal', function($scope, $modalInstance, commissions, recipient, payment, type, Bookings, $q){

  var deferred = $q.defer();

  $scope.type = type;
  $scope.commissions = commissions;
  $scope.recipient = recipient

  $scope.payment = payment || {
    date: moment().format('MM/DD/YYYY')
  };

  $scope.close = function(){
    $modalInstance.close();
  };

  $scope.remove = function() {
    var booking = Object.keys($scope.commissions)[0];
    Bookings.deleteCommission({
      payment: $scope.payment._id,
      id: booking
    }, function(res){
      $modalInstance.close({
        recipient: $scope.recipient,
        payment: $scope.payment,
        commissions: $scope.commissions
      });
    });
  }

  $scope.unsetOther = function(source){
    var choice = $scope.payment[source];
    var target = source === 'on_hold' ? 'not_commissionable' : 'on_hold';
    if(target === 'not_commissionable') {
      $scope.payment.commissionable = true;
    }
    $scope.payment[target] = false;
  }

  $scope.submit = function(){
    var count = Object.keys($scope.commissions).length;
    angular.forEach($scope.commissions, function(payment){

      if($scope.payment.not_commissionable) {
        $scope.payment.hold_reason = undefined;
        $scope.payment.commissionable = false;
      }

      var data = {
        id: payment.booking._id,
        amount: ($scope.payment.on_hold || $scope.payment.not_commissionable) ? parseFloat("0.00") : payment.commission.amount,
        employee: type === 'employees' ? recipient._id : null,
        contact: type !== 'employees' ? recipient._id : null,
        commissionable: !$scope.payment.not_commissionable,
        date: $scope.payment.date,
        method: $scope.payment.method,
        hold_reason: !$scope.payment.not_commissionable ? $scope.payment.hold_reason : undefined
      };

      if($scope.payment._id) data.payment = $scope.payment._id;

      Bookings.payCommission(data, function(res){
        count--;
        $scope.commissions[payment.booking._id].commission = res;
        if(count === 0) {
          $modalInstance.close({
            recipient: $scope.recipient,
            payment: $scope.payment,
            commissions: $scope.commissions
          });
        }
      });

    });
  };

});
