var GiftsReportController = angular.module('RessieApp.components.reports.gifts', []);

GiftsReportController.controller('giftsReport', function ($scope, $stateParams, $modal, Reports, Gifts) {

  $scope.$parent.isPrintable();
  var company = $scope.user.activeCompany();

  (function(parent){
    var date = parent.calendar.date.startDate.format('MM-DD-YY');
    parent.setPrintTitle('Prepayments between ' + date)
  })($scope.$parent);

  // set default days
  if($stateParams.start === '') {
    if(moment().hours() > 9) {
      var tomorrow = moment().add(1, 'day')
      $scope.$parent.calendar.date.startDate = tomorrow;
      $scope.$parent.calendar.date.endDate = tomorrow.clone().add(7, 'day');
    } else {
      var today = moment();
      $scope.$parent.calendar.date.startDate = today;
      $scope.$parent.calendar.date.endDate = today.clone().add(7, 'days');
    }
  }

  var start = $scope.start = $scope.$parent.calendar.date.startDate;
  var end = $scope.end = $scope.$parent.calendar.date.endDate;
  
  $scope.data = {};

  function prepGift(g) {
    angular.extend(this, g);
    this.created = moment.utc(this.created);
    this.redeemed = this.redeemed ? moment.utc(this.redeemed) : null;
    return this;
  }

  prepGift.prototype.inRange = function(date){
    var isOnOrAfterStart = date.isSameOrAfter(start, 'day');
    var isOnOrBeforeEnd = date.isSameOrBefore(end, 'day');
    return isOnOrAfterStart && isOnOrBeforeEnd;
  }

  prepGift.prototype.guestName = function(){
    var booking = this.booking;
    if(!booking) return undefined;
    if(booking.group) return booking.group_name;
    return booking.last_name + ', ' + booking.first_name;
  }

  prepGift.prototype.redeem = function() {
    var self = this;
    var modalInstance = $modal.open({
      templateUrl: 'comp/reports/gifts/redeem_modal',
      controller: 'giftsReportModal',
      resolve: {
        gift: function(){
          return self;
        },
        start: function(){
          return start;
        },
        end: function(){
          return end;
        }
      }
    });
    modalInstance.result.then(function (data) {
      if(data) {
        angular.extend(self, data);
      }
    });
  }

  prepGift.prototype.unlink = function() {
    var self = this;

    bootbox.dialog({
      message: "<p><strong>This can not be undone.</strong> If you still want to unlink this booking then please type <code>UNLINK</code> below:</p><div class='form-group bg-light' style='padding: 26px;margin: 0 -15px -1px -15px;margin-bottom: -15px;'><input type='text' id='result' class='form-control' /></div>",
      title: "Removing <code>" + this.code +"</code> from <code>" + company.nickname + this.booking.confirmation + "</code>. Are you sure?",
      buttons: {
        success: {
          label: "Remove",
          className: "btn-primary",
          callback: function() {
            var result = $('#result').val();
            if(result == 'UNLINK') {

              Gifts.unlink(self._id, self.booking._id, function(res){
                if(res) {
                  self.booking = null;
                  self.redeemed = null;
                  self._redeemed = undefined;
                  self.manual = null;
                }
              });

            }
          }
        },
        cancel: {
          label: "Cancel",
          className: "btn-default",
          callback: function() {}
        }

      }
    });
    
  }

  $scope.prepGift = function(g) {
    return new prepGift(g);
  };

  $scope.total = function(arr) {
    if(arr) {
      return arr.reduce(function(prev, gift){
        return prev + parseFloat(gift.amount);
      }, 0);
    }
  }

  $scope.showFilter = 'all';

  $scope.getFilter = function(){
    return $scope.showFilter;
  }

  $scope.setFilter = function(filter){
    $scope.showFilter = filter;
  }

  $scope.giftFilter = function(item){
    var filter = $scope.getFilter();
    if(item){
      if(item.redeemed && $scope.showFilter === 'redeemed') return true;
      if(!item.redeemed && $scope.showFilter === 'unredeemed') return true;
      return filter === 'all';
    }
  };

  $scope.prep = function(){
    Reports.gifts(start.format('MM-DD-YY'), end.format('MM-DD-YY'), function(res){
      $scope.data = res;
      $scope.data.joined = res.created.concat(res.redeemed);
      $scope.$parent.isReady();
    })
  }

});

GiftsReportController.controller('giftsReportModal', function($scope, $modalInstance, Bookings, Gifts, gift, start, end, $timeout){
  $scope.gift = angular.copy(gift);
  $scope.gift._redeemed = gift.redeemed ? gift.redeemed.format('MM/DD/YY') : moment().format('MM/DD/YY');
  $scope.bookings = [];
  $scope.working = false;

  var today = moment();
  if(gift.booking) {
    var bookingDay = moment.utc(gift.booking.day);
    if(bookingDay.isSameOrAfter(start) && bookingDay.isSameOrBefore(end)) {
      $scope.gift._redeemed = moment(bookingDay).format('MM/DD/YY')
    }
  }

  $scope.bookingFilter = function(booking, index, array){
    var userEntry = $scope.gift.booking && $scope.gift.booking.confirmation.toString();
    if(userEntry) {
      var filter = userEntry.replace($scope.user.activeCompany().nickname, '');
      return new RegExp(filter, 'i').test(booking.confirmation.toString());
    } else {
      return true;
    }
  }

  $scope.$watch('gift._redeemed', function(n, o){
    if(n) {
      var date = moment(n, 'MM/DD/YY');
      if(date.isValid()) $scope.gift.redeemed = date;
      $scope.working = true;
      Bookings.get({ day: date.format('MM-DD-YY') }, function(response){
        if(response && response.results) {
          $scope.working = false;
          $scope.bookings = response.results;
        }
      });

    }
  });

  $scope.linkBooking = function(booking, gift) {
    gift.booking = angular.copy(booking);
    gift._redeemed = moment.utc(booking.day).format('MM/DD/YY');
  };

  $scope.setDate = function(date) {
    $scope.gift._redeemed = moment(date).format('MM/DD/YY');
    $scope.showCal = false;
  }

  $scope.cancel = function(){
    $modalInstance.close();
  };

  $scope.redeem = function(gift){
    Gifts.redeem(gift._id, gift.booking._id, gift.redeemed.format('MM-DD-YY'), function(res){
      $modalInstance.close($scope.gift);
    });
  };

});
