var TipsReport = angular.module('RessieApp.components.reports.tips', []);

TipsReport.controller('tipsReport', function ($scope, $stateParams, Reports, $filter) {

function getRange(startDate, endDate, type) {
  let fromDate = moment(startDate)
  let toDate = moment(endDate)
  let diff = toDate.diff(fromDate, type)
  let range = []
  for (let i = 0; i < diff; i++) {
    range.push(moment(startDate).add(i, type).format('MM-DD-YYYY'))
  }
  return range
}

  var ACL = 'Bookkeeping Reports';
  $scope.allowed = $scope.user.can('tips', ACL);

  $scope.$parent.isPrintable();

  var start = $scope.start = $scope.$parent.calendar.date.startDate;
  var end = $scope.end = $scope.$parent.calendar.date.endDate;

  // NOTE: Handle the report filter.
  $scope.filter = $scope.$parent.filter;
  $scope.$watch('$parent.filter', function(n) {
    $scope.filter = n;
  });

  // NOTE: tip results come from sales report directive
  function GrabData() {
    Reports.tips($stateParams.start, $stateParams.end, function Win(res){

      var pilots = res.map(function(tip){
        return tip.pilot.name;
      });
      var recipients = [];
      res.map(function(tip){
        return tip.recipients.map(function(recipient){
          return { id: recipient.user._id, name: recipient.user.name };
        });
      }).flat().forEach(function(recipient) {
        if(!recipients.find(function(rec) {
          return recipient.id === rec.id
        })) {
          recipients.push(recipient);
        }
      });

      $scope.tips = {
        days: res.map(function(tip){
          return { 
            event: tip.event._id,
            date: tip.event.date,
            pilot: tip.pilot,
            rezflow: tip.rezflow,
            square: tip.square,
            recipients: tip.recipients,
          };
        }),
        recipients: recipients,
        pilots: pilots.filter(function(pilot, index, self){
          return pilots.indexOf(pilot) === index;
        }),
      };
    });

    $scope.getTipsByRecipient = function(recipient, recipients) {
      var recip =  recipients.find(function(rec) {
        return rec.user._id === recipient.id;
      });
      return recip ? recip.amount : 0;
    };

    $scope.getTipsEnteredForDay = function(day, pilot) {
      if (day.pilot.name === pilot) {
        return day.square;
      }
      return 0;
    }

    $scope.getTotalRecipientTips = function(recipient) {
      var total = 0;
      ($scope.tips.days).forEach(function(day){
        var tip = day.recipients.find(function(rec) {
          return rec.user._id === recipient.id;
        });
        total += tip ? tip.amount : 0;
      });
      return total;
    };

    $scope.getTotalCalculatedTips = function(days) {
      const matrix = $scope.calculatedTips(days) ? $scope.calculatedTips(days) : [];
      return matrix[0] ? Object.values(matrix[0]).reduce(function(sum, day) { return sum + day.total }, 0) : 0;
    };

    $scope.getTotalSquareTips = function(tips) {
      return (tips ? tips : []).reduce(function(sum, tip) { return sum + tip.total }, 0);
    }

    $scope.joinDays = function(tipDays) {
      if (tipDays) {
        const days = {};
        tipDays.forEach(function(day) {
          days[day.date] = days[day.date] || {};
          days[day.date].date = day.date;
          days[day.date].pilots = days[day.date].pilots || {};
          days[day.date].pilots[day.pilot.name] = days[day.date].pilots[day.pilot.name] || {};
          days[day.date].pilots[day.pilot.name].square = day.square;
        });
        return days;
      }
    }

    $scope.calculatedTips = function(tipDays) {
      if (tipDays) {
        const days = {};
        const recipients = {};
        tipDays.forEach(function(day) {
          days[day.date] = days[day.date] || {};
          days[day.date].date = day.date;
          days[day.date].total = days[day.date].total || 0;
          days[day.date].recipients = days[day.date].recipients || {};
          day.recipients.forEach(function(recipient) {
            days[day.date].total = days[day.date].total + recipient.amount;
            recipients[recipient.user.name] = recipients[recipient.user.name] || 0;
            days[day.date].recipients[recipient.user.name] = days[day.date].recipients[recipient.user.name] || 0;
            recipients[recipient.user.name] = recipients[recipient.user.name] + recipient.amount;
            days[day.date].recipients[recipient.user.name] = days[day.date].recipients[recipient.user.name] + recipient.amount;
          });
        });
        return [days, recipients];
      }
    }


    return Reports.sales(
      $stateParams.start,
      $stateParams.end,
      function Win(res){
        var data = angular.copy(res);
        $scope.data = data.constructor.name === 'Resource' ? data.toJSON() : data;
        $scope.days = angular.copy($scope.data);
        $scope.Prepayment = $scope.data.Prepayment;
        $scope.Reservation = $scope.data.Reservation;
        $scope.calucatedTips = $scope.calculatedTips($scope.tips.days);
        $scope.square = $scope.joinDays($scope.tips.days);
        $scope.squareArray = Object.values($scope.square).sort(
          // sort by date
          function(a, b) {
            return a.date > b.date ? 1 : -1;
          }
        ).map(function(day) {
          day.total = (Object.values(day.pilots).reduce(function(sum, pilot) { return pilot.square + sum }, 0));
          return day;
        });
        $scope.totalSquareTipsForPilot = function(pilot) {
          // sum up all the square tips for a pilot
          return Object.values($scope.square).reduce(function(sum, day) {
            return sum + (day.pilots[pilot] ? day.pilots[pilot].square : 0);
          }
          , 0);
        }
        delete $scope.days.Prepayment;
        delete $scope.days.Reservation;
        delete $scope.days.requestUrl;
        $scope.$parent.isReady();
      }
    );
  }

  $scope.prep = function(){
    if($scope.allowed) return GrabData();
    $scope.$parent.isReady();
  };

})
