var salesSlip = angular.module('RessieApp.components.reports.salesslip', []);

salesSlip.controller('salesSlip', function ($scope, $stateParams, Pricing, Reports) {

  $scope.$parent.isSingleDay();
  $scope.$parent.isPrintable();

  (function(parent){
    var date = parent.calendar.date.startDate.format('MM-DD-YY');
    parent.setPrintTitle('Sales Slip for ' + date)
  })($scope.$parent);

  if($stateParams.start === '') {
    if(moment().hours() > 9) {
      var tomorrow = moment().add(1, 'day')
      $scope.$parent.calendar.date.startDate = tomorrow;
      $scope.$parent.calendar.date.endDate = tomorrow.clone();
    } else {
      var today = moment();
      $scope.$parent.calendar.date.startDate = today;
      $scope.$parent.calendar.date.endDate = today.clone();
    }
  }

  var start = $scope.start = $scope.$parent.calendar.date.startDate;
  var end = $scope.end = $scope.$parent.calendar.date.endDate;

  $scope.aggregateCash = function(booking) {
    return booking.pricing.other_payments.cash.reduce(function(prev, next){
      return parseFloat(prev) + parseFloat(next);
    }, 0)
  }

  function charges(booking) {

    var bill_to = booking.sales_report.bill_to.reduce(function(prev, curr){
      return prev + parseFloat(curr.total)
    }, 0)

    var cash = booking.sales_report.cash.reduce(function(prev, curr){
      return prev + curr;
    }, 0)

    // console.log(booking);
    var gifts = booking.payment.gifts.reduce(function(prev, curr){
      return prev + curr.amount;
    }, 0)

    var creditCard = booking.payment.cards.reduce(function(prev, curr){
      return prev + curr.total;
    }, 0)

    return bill_to + cash + gifts + creditCard;
  }

  function billable(booking) {
      var pack = parseFloat(booking.sales_report.package.total);
      // if (booking.confirmation === 32604) console.log(pack)
      var items = booking.sales_report.items.reduce(function(prev, curr){
        return prev + parseFloat(curr.total)
      }, 0)
      return pack + items;
  }


  $scope.balance = function(booking) {
    var total = billable(booking) - charges(booking);
    return total;
  }

  function GrabData() {
    Reports.sales(
      $stateParams.start,
      $stateParams.end,
      function Win(res) {
        var data = angular.copy(res);
        var reservations = (data.constructor.name === 'Resource' ? data.toJSON() : data).Reservation;
        // console.log(reservations);
        $scope.data = reservations;

        for (var id in reservations) {
          if (reservations.hasOwnProperty(id)) {
            var booking = reservations[id];
            if(!$scope.getPeriod(booking, start, end).isInRange || booking.status !== 'active') {
              delete $scope.data[id];
            }
            
          }
        }
        // .filter(function (booking) {
        //   return $scope.getPeriod(booking, start, end).isInRange;
        // });
        $scope.$parent.isReady();
      }
    );
  }

  $scope.prep = function(){
    GrabData();
    // Events.get(start.clone().format('MM-DD-YYYY'), function(res){
    //   $scope.data = res.reservations;
    //   $scope.$parent.isReady();
    // });
  }

})
