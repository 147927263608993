var itSheetReport = angular.module('RessieApp.components.reports.itsheet', []);

itSheetReport.controller('itSheetReport', function ($scope, $stateParams, Events) {

  $scope.$parent.isSingleDay();
  $scope.$parent.isPrintable();

  (function(parent){
    var date = parent.calendar.date.startDate.format('MM-DD-YY');
    parent.setPrintTitle('IT-Sheet for ' + date)
  })($scope.$parent);

  if($stateParams.start === '') {
    if(moment().hours() > 9) {
      var tomorrow = moment().add(1, 'day')
      $scope.$parent.calendar.date.startDate = tomorrow;
      $scope.$parent.calendar.date.endDate = tomorrow;
    } else {
      var today = moment();
      $scope.$parent.calendar.date.startDate = today;
      $scope.$parent.calendar.date.endDate = today;
    }
  }

  var start = $scope.start = $scope.$parent.calendar.date.startDate;
  var end = $scope.end = $scope.$parent.calendar.date.endDate;

  $scope.getFlight = function(flight){
    $scope.range = false;
    return $scope.data.flights.filter(function(fl){
      return fl._id == flight.flight;
    })[0];
  };

  $scope.getBookingWeight = function(booking){
    return booking.meta.weights.reduce(function(prev, weight){
      return prev + weight;
    }, 0);
  }

  $scope.getTotalWeight = function(){
    var total = 0;
    if($scope.data){
      angular.forEach($scope.data.reservations, function(booking){
        if(booking.status === 'active') total += $scope.getBookingWeight(booking);
      });
    }
    return total;
  }

  $scope.prep = function(){
    Events.get(start.clone().format('MM-DD-YYYY'), function(response){
      $scope.data = response;
      $scope.$parent.isReady();
    });
  }

})
