var SalesReport = angular.module('RessieApp.components.reports.sales', []);

SalesReport.controller('salesReport', function ($scope, $stateParams, Reports) {
  
  var ACL = 'Bookkeeping Reports';
  $scope.allowed = $scope.user.can('sales', ACL);

  var start = $scope.start = $scope.$parent.calendar.date.startDate;
  var end = $scope.end = $scope.$parent.calendar.date.endDate;

  $scope.$parent.isPrintable();

  (function(parent){
    parent.setPrintTitle('Sales Report for ' + start.format('MM-DD-YY') + ' thru ' + end.format('MM-DD-YY'))
  })($scope.$parent);

  // NOTE: Handle the report filter.
  $scope.filter = $scope.$parent.filter;
  $scope.$watch('$parent.filter', function(n) {
    $scope.filter = n;
  });

  $scope.report_filter = 'all';

  $scope.setFilter = function(type) {
    if(type.constructor.name === 'Object') {
      $scope.report_filter = type;
    } else {
      $scope.report_filter = $scope.report_filter === type ? 'all' : type;
    }
  }

  $scope.reportFilter = function(data){
    var filter = $scope.report_filter;
    var credit = data.report.credits[filter];
    var debit = data.report.debits[filter];
    if(!filter || filter === 'all') return true;
    if(filter === 'balance') return Math.abs(data.report.balance) > 0;
    if(debit !== undefined) {
      var debit_amount = typeof debit === 'object' ? Math.abs(debit.total) : debit;
      return Math.abs(debit_amount) > 0;
    }
    if(credit !== undefined) {
      var credit_amount = typeof credit === 'object' ? Math.abs(credit.total) : credit;
      return Math.abs(credit_amount) > 0;
    }
  }

  $scope.isFiltered = function(type) {
    return $scope.report_filter === type || $scope.report_filter === 'all';
  }

  function GrabData() {
    Reports.sales(
      $stateParams.start,
      $stateParams.end,
      function Win(res){
        var data = angular.copy(res);
        $scope.data = data.constructor.name === 'Resource' ? data.toJSON() : data;
        $scope.days = angular.copy($scope.data);
        $scope.Prepayment = $scope.data.Prepayment;
        $scope.Reservation = $scope.data.Reservation;
        delete $scope.days.Prepayment;
        delete $scope.days.Reservation;
        $scope.$parent.isReady();
      }
    );
  }

  $scope.prep = function(){
    if($scope.allowed) return GrabData();
    $scope.$parent.isReady();
  }

})
