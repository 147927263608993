var SalesReport = angular.module('RessieApp.components.reports.discrepancies', []);

SalesReport.controller('discrepancyReport', function ($scope, $stateParams, Reports) {
  
  var ACL = 'Bookkeeping Reports';
  $scope.allowed = $scope.user.can('sales', ACL);

  var start = $scope.start = $scope.$parent.calendar.date.startDate;
  var end = $scope.end = $scope.$parent.calendar.date.endDate;

  $scope.$parent.isPrintable();

  (function(parent){
    parent.setPrintTitle('Discrepancy Report for ' + start.format('MM-DD-YY') + ' thru ' + end.format('MM-DD-YY'))
  })($scope.$parent);

  // NOTE: Handle the report filter.
  $scope.filter = $scope.$parent.filter;
  $scope.$watch('$parent.filter', function(n) {
    $scope.filter = n;
  });

  $scope.report_filter = 'all';

  $scope.setFilter = function(type) {
    if(type.constructor.name === 'Object') {
      $scope.report_filter = type;
    } else {
      $scope.report_filter = $scope.report_filter === type ? 'all' : type;
    }
  }

  $scope.toFloat = function(numString) {
    return Number.parseFloat(numString);
  }

  $scope.onFile = function(booking) {
    return booking.cart.payments.bill_to.find(function(payment) { return payment.name === 'CARD ON FILE' });
  }

  function GrabData() {
    Reports.discrepancies(
      $stateParams.start,
      $stateParams.end,
      function Win(res){
        // var data = angular.copy(res);
        $scope.data = res.filter(function(item) {
          return $scope.toFloat(item.cart.balance) !== 0 || $scope.onFile(item) !== undefined;
        }).map(function(item) {
          item.cart.balance = $scope.toFloat(item.cart.balance);
          return item;
        });
        $scope.$parent.isReady();
      }
    );
  }

  $scope.prep = function(){
    if($scope.allowed) return GrabData();
    $scope.$parent.isReady();
  }

})
