var DepartureTimes = angular.module('RessieApp.components.company.departure', []);

DepartureTimes.controller('DepartureTimes', function ($scope, $stateParams, Companies) {

  $scope.$watch('editIndex', function(n, o){
    if($scope.dates) {
      if(o >= 0 && $scope.dates[o]) $scope.dates[o].edit = false;
      if(n >= 0) {
        var active = $scope.dates[n];
        if (!active.start.start_dst && !active.start.end_dst) {
          active.start._date = moment(active.start.date).format('MM/DD');
        }
        if (!active.end.start_dst && !active.end.end_dst) {
          active.end._date = moment(active.end.date).format('MM/DD');
        }
        active._time = moment.utc(active.time).format('hh:mm A');
        active.edit = true;
      }
    }
  });

  $scope.$watch('dates', function(n, o){
    if(n && $scope.editIndex !== -1 && n[$scope.editIndex]) {
      var range = n[$scope.editIndex];
      var start = range.start;
      var end = range.end;
      var old = o[$scope.editIndex];
      var next = $scope.dates[$scope.editIndex + 1];
      var prev = $scope.dates[$scope.editIndex - 1];

      if(start.start_dst && !old.start.start_dst) {
        start.date = undefined;
        start._date = undefined;
        $scope.unsetDST('start_dst');
        $scope.rangeDST(start, 'start');
        range.end.start_dst = false;
      }

      if(start.end_dst && !old.start.end_dst) {
        start.date = undefined;
        start._date = undefined;
        $scope.unsetDST('end_dst');
        $scope.rangeDST(start, 'end');
        range.end.start_dst = false;
        range.end.end_dst = false;
      }

      if(end.start_dst && !old.end.start_dst) {
        end.date = undefined;
        end._date = undefined;
        $scope.unsetDST('start_dst');
        $scope.rangeDST(end, 'start');
        range.start.start_dst = false;
        range.start.end_dst = false;
      }
      if(end.end_dst && !old.end.end_dst) {
        end.date = undefined;
        end._date = undefined;
        $scope.unsetDST('end_dst');
        $scope.rangeDST(end, 'end');
        range.start.end_dst = false;
      }

      if(range._time) {
        range.time = moment.utc(range._time, 'hh:mm A')
      }

      if(start._date && start._date !== old.start._date) {
        start.date = moment(start._date, 'MM/DD');
      }

      if(end._date && end._date !== old.end._date && next) {
        end.date = moment(end._date, 'MM/DD');
        next.start.date = moment(end.date).add(1, 'day');
      }
    }
  }, true)

  $scope.editIndex = -1;

  $scope.setEditIndex = function(index){
    if($scope.editIndex === index) {
      $scope.editIndex = -1;
    } else {
      $scope.editIndex = index !== undefined ? index : -1;
    }
  };

  $scope.isSelected = function(index) {
    return $scope.editIndex >= 0 && index === $scope.editIndex;
  };

  $scope.unsetDST = function(target){
    var index = -1;
    var place = undefined;
    angular.forEach($scope.dates, function(range, i){
      if(range.start[target]) {
        place = 'start';
        index = i;
        range.start[target] = false;
      }
      if(range.end[target]) {
        place = 'end';
        index = i;
        range.end[target] = false;
      }
    });
  }

  $scope.rangeDST = function(range, target) {
    if(target !== 'start' && target !== 'end') throw new Error('rangeDST target needs to be "start" or "end"');
    var other = target === 'start' ? 'end_dst' : 'start_dst';
    target = target === 'start' ? 'start_dst' : 'end_dst';
    range.date = undefined;
    range[target] = true;
    range[other] = false;
  };

  $scope.removeDate = function(index) {
    $scope.dates.splice(index, 1);
    Companies.updateTimes({ _id: $scope.user.activeCompany()._id, times: $scope.dates }, function(res){
      $scope.nextTime();
      $scope.setEditIndex();
    });
  };

  $scope.update = function() {
    Companies.updateTimes({ _id: $scope.user.activeCompany()._id, times: $scope.dates }, function(res){
      $scope.nextTime();
      $scope.setEditIndex();
    });
  };

  $scope.$watch('newTime', function(n, o){
    var startWasDST = o !== undefined && o && (o.start.start_dst || o.start.end_dst);
    var startIsDST = n !== undefined && n && (n.start.start_dst || n.start.end_dst);
    var endWasDST = o !== undefined && o && (o.end.start_dst || o.end.end_dst);
    var endIsDST = n !== undefined && n && (n.end.start_dst || n.end.end_dst);
    if(n) {
      var next = $scope.nextTime(true);

      if(startIsDST) {
        n.start.date = undefined; 
        n.start._date = undefined; 
        if(startWasDST) {
          if(n.start.start_dst && !o.start.start_dst) n.start.end_dst = false;
          if(n.start.end_dst && !o.start.end_dst) n.start.start_dst = false;
        }
      } else {
        if((n.start && o && o.start) && n.start._date !== o.start._date) n.start.date = moment(n.start._date, 'MM/DD');
        if(startWasDST) {
          n.start.date = next.start.date;
          n.start._date = next.start._date;
        }
      }

      if(endIsDST) {
        n.end.date = undefined; 
        n.end._date = undefined;
        if(endWasDST) {
          if(n.end.start_dst && !o.end.start_dst) n.end.end_dst = false;
          if(n.end.end_dst && !o.end.end_dst) n.end.start_dst = false;
        }
      } else {
        if((n.end && o && o.end) && n.end._date !== o.end._date) n.end.date = moment(n.end._date, 'MM/DD');
        if(endWasDST) {
          n.end.date = next.end.date;
          n.end._date = next.end._date;
        }
      }

    }
  }, true);

  $scope.sortDates = function(item) {
    var start = moment.utc(item.start.date);
    if (item.start.end_dst) start = angular.copy($scope.endOfDST).add(1, 'day');
    return start.toDate();
  }

  $scope.nextTime = function(dataOnly){
    dataOnly = dataOnly !== undefined ? dataOnly : false;
    var dates = $scope.dates;
    var prev = dates ? dates[dates.length - 1] : null;
    var start = (function(){
      var date = prev && prev.end && prev.end.date ? moment.utc(prev.end.date).add(1, 'day') : null;
      if(!date && !prev) date = moment.utc('01/01', 'MM/DD');
      return {
        date: date,
        _date: date ? moment.utc(date).format('MM/DD') : undefined,
        start_dst:  prev && prev.end && prev.end.start_dst,
        end_dst:  prev && prev.end && prev.end.end_dst,
      }
    })();
    var end = (function(){

      var date = start.date ? moment.utc(start.date).add(1, 'day') : null;
      if(!date && start.end_dst) date = DSTLookup(false).endOf('month');
      if(!date && start.start_dst) date = DSTLookup(true).endOf('month');
      return {
        date: date ? moment(date).endOf('month') : null,
        _date: date ? moment(date).endOf('month').format('MM/DD') : null,
        start_dst:  false,
        end_dst:  false,
      }
    })();

    var timeReg = (/^([a-z]{3}) ([a-z]{3}) (\d{2}) (\d{4}) (\d{2}:\d{2}:\d{2}) ([a-z]{3}\+\d{4})$/i);
    var format = prev && timeReg.test(prev.time) ? 'ddd MMM DD YYYY HH:mm:ss zZZ' : undefined;

    var data = {
      start: start,
      end: end,
      time: prev ? moment.utc(prev.time, format).format('hh:mm A') : '06:00 AM'
    };

    if(dataOnly) return data;

    $scope.newTime = data;

  };

  $scope.addTime = function(time) {
    time.time = moment.utc(time.time, 'hh:mm A');
    time.start.date = time.start.date ? moment.utc(time.start.date) : time.start.date;
    time.end.date = time.end.date ? moment.utc(time.end.date) : time.end.date;
    $scope.dates.push(time);
    Companies.updateTimes({ _id: $scope.user.activeCompany()._id, times: $scope.dates }, function(res){
      $scope.adding = false;
      $scope.nextTime();
    })
  }

  $scope.dates = $scope.user.activeCompany().departure_times;

  $scope.startOfDST = DSTLookup(true);
  $scope.endOfDST = DSTLookup(false);

  // startDate is when we start looping to find DST
  // start is a boolean, if true then we look for start of DST, otherwise we look for end.
  function DSTLookup(start) {
  
    var startDate = start ? moment('01-01', 'MM-DD') : DSTLookup(true).add(1, 'day');
    
    var prevDate = moment(startDate).hour(2).second(1);
    var prevDST = prevDate.isDST();

    if(start) {
      while (!prevDST) {
        prevDate.add(1, 'day');
        prevDST = prevDate.isDST();
      }
    } else {
      while (prevDST) {
        prevDate.add(1, 'day');
        prevDST = prevDate.isDST();
      }
    }
    
    return start ? moment(prevDate) : moment(prevDate).subtract(1, 'day');

  }

});
