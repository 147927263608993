var ReferralsComponent = angular.module('RessieApp.components.referrals', []);

ReferralsComponent.component('referrals', {
  templateUrl: 'comp/referrals/referrals',
  bindings: {
    company: '='
  },
  controller: function (Companies){
    this.add = function(){
      var referrals = this.company.meta.sources;
      referrals.push(this.referral);
      this.referral = "";
      this.update();
    }
    this.remove = function(i) {
      var referrals = this.company.meta.sources;
      referrals.splice(i, 1);
      this.update();
    }
    this.update = function() {
      var referrals = this.company.meta.sources;
      Companies.update({ _id: this.company._id, meta: { sources : referrals } }, function(res) {
        console.log(res);
      })
    }
  }
});
