var SalesReport = angular.module('RessieApp.components.reports.deposits', []);

SalesReport.controller('depositReport', function ($scope, $stateParams, Reports) {
  
  var ACL = 'Bookkeeping Reports';
  $scope.allowed = $scope.user.can('sales', ACL);

  var start = $scope.start = $scope.$parent.calendar.date.startDate;
  var end = $scope.end = $scope.$parent.calendar.date.endDate;

  $scope.$parent.isPrintable();

  (function(parent){
    parent.setPrintTitle('Deposits Report for ' + start.format('MM-DD-YY') + ' thru ' + end.format('MM-DD-YY'))
  })($scope.$parent);

  // NOTE: Handle the report filter.
  $scope.filter = $scope.$parent.filter;
  $scope.$watch('$parent.filter', function(n) {
    $scope.filter = n;
  });

  $scope.report_filter = 'all';

  $scope.setFilter = function(type) {
    if(type.constructor.name === 'Object') {
      $scope.report_filter = type;
    } else {
      $scope.report_filter = $scope.report_filter === type ? 'all' : type;
    }
  }

  $scope.depositsReceivedNotRedeemed = function(booking) {
    var charges = booking.payment.cards.reduce((prev, card) => {
      return prev.concat(card.charges)
    }, []);
    return charges.filter(function(charge) {
      // charged before flown
      var refunds = charge.refunds.filter((refund) => {
        return refund.date < booking.day;
      }).reduce(function(prev, refund) {
        return prev + refund.amount;
      }, 0);
      return (moment(charge.date).isBefore(booking.day) && moment(booking.day).isAfter(end)) && charge.amount > refunds;
    }).reduce(function(prev, charge) {
      return prev + charge.amount;
    }, 0);
  }

  $scope.totalReceived = function(bookings) {
    var total = 0;
    (bookings || []).forEach(function(booking) {
      total += $scope.depositsReceivedNotRedeemed(booking);
    });
    return total;
  }

  $scope.depositsRedeemed = function(booking) {
    var charges = booking.payment.cards.reduce((prev, card) => {
      return prev.concat(card.charges)
    }, []);
    var deposits = charges.filter(function(charge) {
      // charged before flown
      return charge.date < booking.day;
    });
    return deposits.filter(function(deposit) {
      var refunds = deposit.refunds.filter((refund) => {
        return refund.date < booking.day;
      }).reduce(function(prev, refund) {
        return prev + refund.amount;
      }, 0);
      return deposit.date < booking.day && moment(booking.day).isBefore(end) && deposit.amount > refunds;
    }).reduce(function(prev, charge) {
      return prev + charge.amount;
    }, 0)
  }

  $scope.totalRedeemed = function(bookings) {
    var total = 0;
    (bookings || []).forEach(function(booking) {
      total += $scope.depositsRedeemed(booking);
    });
    return total;
  }

  $scope.depositsRefunded = function(booking) {
    var charges = booking.payment.cards.reduce((prev, card) => {
      return prev.concat(card.charges)
    }, []).filter(function(charge) {
      return charge.date < booking.day;
    });
    return charges.reduce(function(prev, charge) {
      // charged before flown
      var refunds = charge.refunds.filter((refund) => {
        return refund.date < booking.day;
      });
      return prev.concat(refunds); //refundTotal > 0 && charge.date < booking.day;
    }, []).reduce(function(prev, refund) {
      return prev + refund.amount;
    }, 0)
  }

  $scope.totalRefunded = function(bookings) {
    var total = 0;
    (bookings || []).forEach(function(booking) {
      total += $scope.depositsRefunded(booking);
    });
    return total;
  }

  function GrabData() {
    Reports.deposits(
      $stateParams.start,
      $stateParams.end,
      function Win(res){
        var data = angular.copy(res);
        var data = angular.copy(res);
        $scope.all = data.all;
        $scope.received = data.received;
        $scope.$parent.isReady();
      }
    );
  }

  $scope.prep = function(){
    if($scope.allowed) return GrabData();
    $scope.$parent.isReady();
  }

})
